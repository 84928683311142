export const PRODUCT_COLOR_REQUEST = 'PRODUCT_COLOR_REQUEST'
export const PRODUCT_COLOR_REQUEST_CUD = 'PRODUCT_COLOR_REQUEST_CUD'
// get
export const GET_PRODUCT_COLOR_SUCCESS = 'GET_PRODUCT_COLOR_SUCCESS'
export const GET_PRODUCT_COLOR_ERROR = 'GET_PRODUCT_COLOR_ERROR'
// get detail
export const GET_PRODUCT_COLOR_DETAIL_SUCCESS = 'GET_PRODUCT_COLOR_DETAIL_SUCCESS'
export const GET_PRODUCT_COLOR_DETAIL_ERROR = 'GET_PRODUCT_COLOR_DETAIL_ERROR'
// create
export const CREATE_PRODUCT_COLOR_SUCCESS = 'CREATE_PRODUCT_COLOR_SUCCESS'
export const CREATE_PRODUCT_COLOR_ERROR = 'CREATE_PRODUCT_COLOR_ERROR'
// update
export const UPDATE_PRODUCT_COLOR_SUCCESS = 'UPDATE_PRODUCT_COLOR_SUCCESS'
export const UPDATE_PRODUCT_COLOR_ERROR = 'UPDATE_PRODUCT_COLOR_ERROR'
// delete
export const DELETE_PRODUCT_COLOR_SUCCESS = 'DELETE_PRODUCT_COLOR_SUCCESS'
export const DELETE_PRODUCT_COLOR_ERROR = 'DELETE_PRODUCT_COLOR_ERROR'