import React, {useEffect, useState} from "react"
import { Link } from "react-router-dom"
import {NavLink, Row, Col, Card, CardBody, Table, Button, Modal, Form, InputGroup, FormControl} from "reactstrap"
import PropTypes from "prop-types"
import swal from "sweetalert2"
import {connect} from "react-redux"
import ReactTable from "react-table"
import "react-table/react-table.css"
import AuthenticatedNavbar from "components/Navbars/AuthenticatedNavbar.js";
import InoutModal from "./InoutModal"
import { 
  getInout,
  updateInout,
  deleteInout
} from "store/actions"
import moment from 'moment'
import { img } from 'assets'
import { formatNumber } from 'utils'

export const Mode = {
  HIDE: "0",
  ADD: "1",
  EDIT: "2",
  DELETE: "3"
}

const InoutPage = (props) => {
  const [modeModalInout, setModeModalInout] = useState(Mode.HIDE)
  const [formModalInout, setFormModalInout] = useState({})

  const fetchData = async (params) => {
    await props.getInout(params)
  }
  const onFetchData = async (rtState = {}) => {
    const { page, pageSize, filtered, sorted } = rtState

    let filter = filtered ? [...filtered] : []
    filter.push({"id": "inoutCategory", "value": "manual"})

    const filteredStr = JSON.stringify(filter)
    const sortedStr = JSON.stringify(sorted)

    let params = `page=${Number(page)}&pageSize=${pageSize}&filtered=${filteredStr}`

    fetchData(params)
  }
  function doShowModal(mode, data = null)
  {
    let form
    switch (mode) {
      case Mode.EDIT:
      case Mode.DELETE:
        form = data
        break
      case Mode.ADD:
        form = {}
        break
      default:
        break
    }

    setModeModalInout(mode)
    setFormModalInout(form)
  }

  const handleDelete =(data) => {
    let id = data._id
    swal.fire({
      title: `Apakah anda yakin ingin menghapus ini?`,
      text: "Data yang sudah terhapus tidak dapat dikembalikan",
      type: "warning", 
      showCancelButton: true,
      confirmButtonColor: "#d33", 
      confirmButtonText: "Ya", 
      cancelButtonColor: "#3085d6",
    }).then(async(result) => {
      if(result.value){
        await props.deleteInout(id)
        onFetchData()
      }
    })
  }

  const { inout } = props
  const { isLoading, isError, message, data } = inout
  const { data: dataList, page, pageSize, totalPage } = data

  const columns = [
    {
      Header: "#",
      width: 60,
      filterable: false,
      Cell: (props) => <p className="text-center">{(page * pageSize) + props.index + 1}</p>,
    },
    {
      Header: "Tanggal",
      accessor: "date",
      Cell: (props) => <small>{moment(props.value).format('lll')}</small>,
    },
    {
      Header: "Keterangan",
      accessor: "description",
    },
    {
      Header: "Jenis",
      accessor: "inoutType",
    },
    {
      Header: "Jumlah",
      accessor: "amount",
      Cell: (props) => <span>{formatNumber(props.value)}</span>,
    },
    {
      Header: "Aksi",
      width: 100,
      Cell: (props) => (
        <div className="text-center"> 
          <button 
            type="button" 
            className="btn btn-sm btn-success" 
            onClick={() => doShowModal(Mode.EDIT, props.original)}
            >
            <i className='fa fa-pen'></i>
          </button> 
          &nbsp;
          <button 
            type="button" 
            className="btn btn-sm btn-danger" 
            onClick={() => handleDelete(props.original)}
            >
            <i className='fa fa-trash'></i>
          </button> 
        </div>
      )
    },
  ]

  return (
    <>
      <AuthenticatedNavbar />

      <div id="content" className="container-fluid pt-4 pb-4">
        <div className="title-lb">
          <label>Pemasukan / Pengeluaran</label> <br />
          <span 
            onClick={() => props.history.goBack()}>
            <i className="fa fa-arrow-left" /> Kembali
          </span>
        </div>
        <Row>
          <Col md="6">
            <Card className="shadow-sm">
              <CardBody>
                <Button 
                  type="button" 
                  className="btn btn-sm btn-success float-right" 
                  onClick={() => doShowModal(Mode.ADD)}
                  >
                  <i className="fa fa-plus" /> Tambah
                </Button>
                <br />
                <br />
                <br />
                <br />
                <ReactTable
                  manual
                  data={dataList}
                  columns={columns}
                  pages={totalPage}
                  page={page}
                  defaultPageSize={8}
                  loading={isLoading}
                  minRows={8}
                  onFetchData={onFetchData}
                />
              </CardBody>
            </Card>

            <InoutModal
              toggleModal={setModeModalInout}
              data={formModalInout}
              mode={modeModalInout}
              refresh={() => onFetchData()}
            />
          </Col>
          <Col md="6">
            <img
              alt="..."
              className=""
              src={img.wallet}
            ></img>
          </Col>
        </Row>
      </div>
    </>
  )
}

InoutPage.propTypes = {
  inout: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
    isError: PropTypes.bool.isRequired,
    message: PropTypes.array,
    data: PropTypes.object.isRequired,
  }),
  getInout: PropTypes.func.isRequired,
  updateInout: PropTypes.func.isRequired,
  deleteInout: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  inout: state.inout,
})

export default connect( mapStateToProps, {
	getInout,
	updateInout,
	deleteInout,
})(InoutPage)
