export const CUSTOMER_REQUEST = 'CUSTOMER_REQUEST'
export const CUSTOMER_REQUEST_CUD = 'CUSTOMER_REQUEST_CUD'
// get
export const GET_CUSTOMER_SUCCESS = 'GET_CUSTOMER_SUCCESS'
export const GET_CUSTOMER_ERROR = 'GET_CUSTOMER_ERROR'
// get detail
export const GET_CUSTOMER_DETAIL_SUCCESS = 'GET_CUSTOMER_DETAIL_SUCCESS'
export const GET_CUSTOMER_DETAIL_ERROR = 'GET_CUSTOMER_DETAIL_ERROR'
// create
export const CREATE_CUSTOMER_SUCCESS = 'CREATE_CUSTOMER_SUCCESS'
export const CREATE_CUSTOMER_ERROR = 'CREATE_CUSTOMER_ERROR'
// update
export const UPDATE_CUSTOMER_SUCCESS = 'UPDATE_CUSTOMER_SUCCESS'
export const UPDATE_CUSTOMER_ERROR = 'UPDATE_CUSTOMER_ERROR'
// delete
export const DELETE_CUSTOMER_SUCCESS = 'DELETE_CUSTOMER_SUCCESS'
export const DELETE_CUSTOMER_ERROR = 'DELETE_CUSTOMER_ERROR'