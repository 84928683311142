import * as Type from 'store/actionTypes/setting'

const initialStateWithoutData = {
  isLoading: false,
  isError: false,
  message: [],
}

const initialState = {
  ...initialStateWithoutData,
  data: {
    data: [], 
    page: 0, 
    pageSize: 10, 
    totalPage: 0
  },
  dataDetail: {},
}

const request = state => ({
  ...state,
  ...initialStateWithoutData,
  isLoading: true,
})

const requestError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  message: payload,
})

const generateCredShopeeSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  message: payload,
})


const settingReducer = (state = initialState, action) => {
  switch (action.type) {
    case Type.GENERATE_CRED_SHOPEE_REQUEST: return request(state)

    case Type.CREATE_GENERATE_CRED_SHOPEE_SUCCESS: return generateCredShopeeSuccess(state, action.payload)
    case Type.CREATE_GENERATE_CRED_SHOPEE_ERROR: return requestError(state, action.payload)
    default:
      return state
  }
}

export default settingReducer
