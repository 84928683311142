import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Input,
  FormGroup,
  Container,
  Col,
} from "reactstrap";
import { connect } from "react-redux";
import { delay } from "lodash";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import TransparentFooter from "components/Footers/TransparentFooter.js";
import { img } from "assets";

import { login } from "store/actions";

function LoginPage(props) {
  const [usermaneFocus, setUsernameFocus] = useState(false);
  const [passwordFocus, setPasswordFocus] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  useEffect(() => {
    document.body.classList.add("login-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("login-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");

    if (accessToken) {
      props.history.push(`/a/dasboard`);
    } else {
      delay(() => {
        props.history.push(`/login`);
      }, 3000);
    }
  });
  onsubmit = (e) => {
    e.preventDefault();
    props.login({ username, password });
  };

  return (
    <>
      <IndexNavbar />

      <div className="page-header">
        {
          // filter-color="blue"
        }
        <div
          className="page-header-image"
          style={{
            backgroundImage:
              "url(" +
              require("assets/img/background-texture-2110724_640.jpg") +
              ")",
          }}
        ></div>
        <div className="content">
          <br />
          <br />
          <Container className="">
            <Col className="ml-auto mr-auto bg-white" md="4">
              <Card className="card-plain">
                <Form action={(e) => onsubmit(e)} className="form" method="">
                  <CardHeader className="text-center">
                    <div className="logo-container my-3" width="75%">
                      <img alt="..." src={img.logo}></img>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <FormGroup
                      className={
                        "input-lg" + (usermaneFocus ? " input-group-focus" : "")
                      }
                    >
                      <Input
                        placeholder="Username..."
                        type="text"
                        value={username}
                        onFocus={() => setUsernameFocus(true)}
                        onBlur={() => setUsernameFocus(false)}
                        onChange={(e) => setUsername(e.target.value)}
                      ></Input>
                    </FormGroup>
                    <FormGroup
                      className={
                        "input-lg" + (passwordFocus ? " input-group-focus" : "")
                      }
                    >
                      <Input
                        placeholder="Password..."
                        type="password"
                        value={password}
                        onFocus={() => setPasswordFocus(true)}
                        onBlur={() => setPasswordFocus(false)}
                        onChange={(e) => setPassword(e.target.value)}
                      ></Input>
                    </FormGroup>
                    <br />
                  </CardBody>
                  <CardFooter className="text-center">
                    <Button
                      block
                      color="info"
                      size="lg"
                      type="submit"
                      disabled={!username || !password}
                    >
                      Masuk
                    </Button>
                    {/* <div className="pull-left">
                      <h6>
                        <a
                          className="link"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          Create Account
                        </a>
                      </h6>
                    </div> */}
                    {/* <div className="pull-right">
                      <h6>
                        <a
                          className="link"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          Need Help?
                        </a>
                      </h6>
                    </div> */}
                  </CardFooter>
                </Form>
              </Card>
            </Col>
          </Container>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { login })(LoginPage);
