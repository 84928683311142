import Swal from "sweetalert2";
import * as Type from "store/actionTypes/inout";
import { xhr } from "utils";

export const getInout =
  (params = "") =>
  async (dispatch) => {
    try {
      dispatch({
        type: Type.INOUT_REQUEST,
      });
      const res = await xhr.get(`/inout?${params}`);
      dispatch({
        type: Type.GET_INOUT_SUCCESS,
        payload: res.data,
      });
    } catch (err) {
      Swal.fire("error", err.message, "error");
      dispatch({
        type: Type.GET_INOUT_ERROR,
        payload: err.message,
      });
    }
  };

export const createInout = (data) => async (dispatch) => {
  try {
    dispatch({
      type: Type.INOUT_REQUEST,
    });
    const res = await xhr.post("/inout", data);
    Swal.fire("success", res.message, "success");
    dispatch({
      type: Type.CREATE_INOUT_SUCCESS,
      payload: res.message,
    });
  } catch (err) {
    Swal.fire("error", err.message, "error");
    dispatch({
      type: Type.CREATE_INOUT_ERROR,
      payload: err.message,
    });
  }
};

export const updateInout = (data, id) => async (dispatch) => {
  try {
    dispatch({
      type: Type.INOUT_REQUEST,
    });
    console.log(data);
    const res = await xhr.put(`/inout/${id}`, data);
    Swal.fire("success", res.message, "success");
    dispatch({
      type: Type.UPDATE_INOUT_SUCCESS,
      payload: res.message,
    });
  } catch (err) {
    Swal.fire("error", err.message, "error");
    dispatch({
      type: Type.UPDATE_INOUT_ERROR,
      payload: err.message,
    });
  }
};

export const deleteInout = (id) => async (dispatch) => {
  try {
    dispatch({
      type: Type.INOUT_REQUEST,
    });
    const res = await xhr.delete(`/inout/${id}`);
    Swal.fire("success", res.message, "success");
    dispatch({
      type: Type.DELETE_INOUT_SUCCESS,
      payload: res.message,
    });
  } catch (err) {
    Swal.fire("error", err.message, "error");
    dispatch({
      type: Type.DELETE_INOUT_ERROR,
      payload: err.message,
    });
  }
};

export const revertProductOut = (id) => async (dispatch) => {
  try {
    dispatch({
      type: Type.INOUT_REQUEST,
    });
    const res = await xhr.put(`/inout/${id}/revert-product-out`);
    Swal.fire("success", res.message, "success");
    dispatch({
      type: Type.REVERT_PRODUCT_OUT_SUCCESS,
      payload: res.message,
    });
  } catch (err) {
    Swal.fire("error", err.message, "error");
    dispatch({
      type: Type.REVERT_PRODUCT_OUT_ERROR,
      payload: err.message,
    });
  }
};

export const revertProductIn = (id) => async (dispatch) => {
  try {
    dispatch({
      type: Type.INOUT_REQUEST,
    });
    const res = await xhr.put(`/inout/${id}/revert-product-in`);
    Swal.fire("success", res.message, "success");
    dispatch({
      type: Type.REVERT_PRODUCT_IN_SUCCESS,
      payload: res.message,
    });
  } catch (err) {
    Swal.fire("error", err.message, "error");
    dispatch({
      type: Type.REVERT_PRODUCT_IN_ERROR,
      payload: err.message,
    });
  }
};

export const productInout = (data) => async (dispatch) => {
  try {
    dispatch({
      type: Type.INOUT_REQUEST,
    });
    const res = await xhr.post("/product-inout", data);

    Swal.fire({
      icon: "success",
      title: res.message,
      showConfirmButton: false,
      timer: 1500,
    });
    dispatch({
      type: Type.PRODUCT_INOUT_SUCCESS,
      payload: res.message,
    });
  } catch (err) {
    Swal.fire("error", err.message, "error");
    dispatch({
      type: Type.PRODUCT_INOUT_ERROR,
      payload: err.message,
    });
  }
};

export const checklistProductOut = (data, id) => async (dispatch) => {
  try {
    dispatch({
      type: Type.INOUT_REQUEST,
    });
    const res = await xhr.put(`/inout/${id}/checklist-product-out`, data);

    Swal.fire({
      title: "success",
      message: res.message,
      icon: "success",
      showConfirmButton: false,
    });
    dispatch({
      type: Type.CHECKLIST_PRODUCT_OUT_SUCCESS,
      payload: res,
    });
  } catch (err) {
    Swal.fire("error", err.message, "error");
    dispatch({
      type: Type.CHECKLIST_PRODUCT_OUT_ERROR,
      payload: err.message,
    });
  }
};
