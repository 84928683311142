import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, CardBody, FormGroup, Input } from "reactstrap";
import PropTypes from "prop-types";
import swal from "sweetalert2";
import { connect } from "react-redux";
import ReactTable from "react-table";
import "react-table/react-table.css";
import AuthenticatedNavbar from "components/Navbars/AuthenticatedNavbar.js";
import ExportCSV from "components/Common/ExportCSV.js";
// import ProductOutModal from "./ProductOutModal"
import {
  getInout,
  updateInout,
  revertProductOut,
  checklistProductOut,
} from "store/actions";
import { formatNumber } from "utils";
import moment from "moment";
import { xhr } from "utils";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export const Mode = {
  HIDE: "0",
  ADD: "1",
  EDIT: "2",
  DELETE: "3",
};

const ProductOutPage = (props) => {
  const [modeModalProductOut, setModeModalProductOut] = useState(Mode.HIDE);
  const [formModalProductOut, setFormModalProductOut] = useState({});
  const [dataCSV, setDataSCV] = useState([]);
  const [onlyThisDay, set_onlyThisDay] = useState(false);

  const [form, setForm] = useState({
    dateFrom: new Date(),
    dateTo: new Date(),
  });
  const [isLoadingCSV, setIsloadingCSV] = useState(false);

  useEffect(() => {
    fetchDataForCSV();
  }, [form]);

  const fetchData = async (params) => {
    await props.getInout(params);
  };
  const onFetchData = async (rtState = {}) => {
    const { page = 0, pageSize = 10, filtered, sorted } = rtState;

    let filter = filtered ? [...filtered] : [];
    let sort = sorted ? [...sorted] : [];

    const date1 = moment(form.dateFrom).startOf("day").format("x");
    const date2 = moment(form.dateTo).endOf("day").format("x");

    filter.push({ id: "inoutType", value: "Pemasukan" });
    filter.push({ id: "inoutCategory", value: "barcode" });

    if (onlyThisDay) {
      filter.push({
        id: "createdAt",
        value: `${date1}.${date2}`,
        type: "between-date",
      });
    }

    const filteredStr = JSON.stringify(filter);
    const sortedStr = JSON.stringify(sort);

    let params = `page=${Number(
      page
    )}&pageSize=${pageSize}&filtered=${filteredStr}&sorterd=${sortedStr}`;

    fetchData(params);
  };
  function doShowModal(mode, data = null) {
    let form;
    switch (mode) {
      case Mode.EDIT:
      case Mode.DELETE:
        form = data;
        break;
      case Mode.ADD:
        form = {};
        break;
      default:
        break;
    }

    setModeModalProductOut(mode);
    setFormModalProductOut(form);
  }

  const handleRevert = (data) => {
    let id = data._id;
    swal
      .fire({
        title: ``,
        text: "Salah Input?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        confirmButtonText: "Pulihkan Stok",
        cancelButtonColor: "#3085d6",
      })
      .then(async (result) => {
        if (result.value) {
          await props.revertProductOut(id);
          onFetchData();
        }
      });
  };

  const handleChecklist = async (data) => {
    let id = data._id;

    await props.checklistProductOut({ isChecked: !data.isChecked }, id);
  };

  const handleChangeByName = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  const fetchDataForCSV = async () => {
    setIsloadingCSV(true);

    const date1 = moment(form.dateFrom).startOf("day").format("x");
    const date2 = moment(form.dateTo).endOf("day").format("x");
    const filtered = [
      { id: "createdAt", value: `${date1}.${date2}`, type: "between-date" },
      { id: "inoutType", value: `Pemasukan`, type: "equal" },
    ];
    const params = `filtered=${JSON.stringify(filtered)}&pageSize=100000`;
    const res = await xhr.get(`/inout?${params}`);
    const data = res.data.data;

    let inoutdata = [];
    for (let i = 0; i < data.length; i++) {
      if (!data[i].wrongInput) {
        inoutdata.push({
          TANGGAL: moment(data[i].createdAt).format("DD-MM-YYYY HH:mm"),
          NAMA: data[i].customer?.name,
          "KETERANGAN BARANG MASUK": data[i].description,
          TIPE: data[i].priceType,
          SUMBER: data[i].sourceOfSales,
          QTY: data[i].qty,
          DISKON: data[i].discountPercent ? `${data[i].discountPercent}%` : "-",
          "ADMIN FEE": data[i].platformFeePercent
            ? `${data[i].platformFeePercent}%`
            : "-",
        });
      }
    }
    setDataSCV(inoutdata);
    setIsloadingCSV(false);
  };

  const { inout } = props;
  const { isLoading, isError, message, data } = inout;
  const { data: dataList, page, pageSize, totalPage, totalUnchecked } = data;

  const columns = [
    {
      Header: "#",
      width: 50,
      filterable: false,
      Cell: (props) => (
        <p className="text-center">{page * pageSize + props.index + 1}</p>
      ),
    },
    {
      Header: "Tanggal",
      width: 70,
      accessor: "createdAt",
      style: { whiteSpace: "unset" },

      Cell: (props) => (
        <small>{moment(props.value).format("DD MMM YYYY, HH:mm:ss")}</small>
      ),
    },
    {
      Header: "No Order",
      width: 200,
      accessor: "orderNumber",
      filterable: true,
      style: { whiteSpace: "unset" },
    },
    {
      Header: "Konsumen",
      width: 200,
      accessor: "customer.name",
      filterable: true,
      style: { whiteSpace: "unset" },
    },
    {
      Header: "Keterangan",
      accessor: "description",
      filterable: true,
      style: { whiteSpace: "unset" },
      Cell: (props) => (
        <div>
          <div
            style={{
              textDecoration: props.original.wrongInput ? "line-through" : "",
            }}
          >
            {props.value} {props.original.qty} pcs
            {props.original.discountPercent
              ? `, disc: ${props.original.discountPercent}%`
              : ""}
            {props.original.platformFeePercent
              ? `, adm: ${props.original.platformFeePercent}%`
              : ""}
          </div>
          {props.original.wrongInput && (
            <small>
              <i>salah input, stok dikembalikan</i>
            </small>
          )}
        </div>
      ),
    },
    // {
    //   Header: "Jenis",
    //   accessor: "inoutType",
    //   width: 120,
    //   Cell: (props) => <p style={{
    //     textDecoration: props.original.wrongInput
    //       ? 'line-through'
    //       : ''
    //   }}>{props.value}</p>
    // },
    {
      Header: "Jumlah",
      accessor: "amount",
      width: 120,
      Cell: (props) => (
        <p
          className="text-right"
          style={{
            textDecoration: props.original.wrongInput ? "line-through" : "",
          }}
        >
          {formatNumber(props.value)}
        </p>
      ),
    },
    {
      Header: "Checklist",
      accessor: "isChecked",
      width: 80,
      Cell: (props) => (
        <div className="text-center">
          {!props.original.wrongInput ? (
            <Input
              type="checkbox"
              name="autoSubmit"
              id="autoSubmit"
              checked={props.value}
              onClick={() => handleChecklist(props.original)}
              autoComplete="off"
              style={{ width: 25, height: 25, marginTop: 10 }}
            />
          ) : (
            "-"
          )}
        </div>
      ),
    },
    {
      Header: "Hapus",
      width: 80,
      Cell: (props) => (
        <div className="text-center">
          &nbsp;
          {moment(props.original.createdAt) > moment().subtract(300, "day") &&
            !props.original.wrongInput && (
              <button
                type="button"
                className="btn btn-sm btn-danger"
                onClick={() => handleRevert(props.original)}
              >
                x
              </button>
            )}
        </div>
      ),
    },
  ];

  return (
    <>
      <AuthenticatedNavbar />

      <div id="content" className="container-fluid pt-4 pb-4">
        <div className="title-lb">
          <label>Barang Keluar</label> <br />
          <span onClick={() => props.history.goBack()}>
            <i className="fa fa-arrow-left" /> Kembali
          </span>
        </div>

        <Row>
          <Col md={{ size: 10, offset: 1 }}>
            <div className="px-2 bg-light" style={{ borderRadius: "8px" }}>
              <span> Input barang keluar yuk</span>
              &nbsp;
              <Link
                to="/a/product/out/create"
                className="btn btn-success btn-sm"
              >
                Oke, Siap
              </Link>
              {Boolean(totalUnchecked) && (
                <b
                  className="float-right text-danger"
                  style={{ marginTop: 15 }}
                >
                  {totalUnchecked} Item belum diceklis
                </b>
              )}
            </div>
            <br />
            <ReactTable
              manual
              data={dataList}
              columns={columns}
              pages={totalPage}
              page={page}
              defaultPageSize={20}
              loading={isLoading}
              minRows={20}
              onFetchData={onFetchData}
            />

            <Row>
              <FormGroup className="col-md-12">
                <br />
                <Card className="bg-light text-center">
                  <CardBody>
                    <Row>
                      <Col className="col-md-6">
                        <DatePicker
                          name="date"
                          id="date"
                          style={{ width: "100%" }}
                          autoComplete="off"
                          selected={form.dateFrom}
                          onChange={(v) => handleChangeByName("dateFrom", v)}
                        />
                      </Col>
                      <Col className="col-md-6">
                        <DatePicker
                          name="dateTo"
                          id="dateTo"
                          style={{ width: "100%" }}
                          autoComplete="off"
                          selected={form.dateTo}
                          onChange={(v) => handleChangeByName("dateTo", v)}
                        />
                      </Col>
                    </Row>
                    <ExportCSV
                      csvData={dataCSV}
                      fileName={"csv"}
                      loading={isLoadingCSV}
                    />
                  </CardBody>
                </Card>
              </FormGroup>
            </Row>
          </Col>
          {/* <Col md="4">
            <img alt="..." className="" src={img.note}></img>
          </Col> */}
        </Row>
      </div>
    </>
  );
};

ProductOutPage.propTypes = {
  inout: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
    isError: PropTypes.bool.isRequired,
    message: PropTypes.array,
    data: PropTypes.object.isRequired,
  }),
  getInout: PropTypes.func.isRequired,
  updateInout: PropTypes.func.isRequired,
  revertProductOut: PropTypes.func.isRequired,
  checklistProductOut: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  inout: state.inout,
});

export default connect(mapStateToProps, {
  getInout,
  updateInout,
  revertProductOut,
  checklistProductOut,
})(ProductOutPage);
