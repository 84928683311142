export const PRODUCT_TYPE_REQUEST = 'PRODUCT_TYPE_REQUEST'
export const PRODUCT_TYPE_REQUEST_CUD = 'PRODUCT_TYPE_REQUEST_CUD'
// get
export const GET_PRODUCT_TYPE_SUCCESS = 'GET_PRODUCT_TYPE_SUCCESS'
export const GET_PRODUCT_TYPE_ERROR = 'GET_PRODUCT_TYPE_ERROR'
// get detail
export const GET_PRODUCT_TYPE_DETAIL_SUCCESS = 'GET_PRODUCT_TYPE_DETAIL_SUCCESS'
export const GET_PRODUCT_TYPE_DETAIL_ERROR = 'GET_PRODUCT_TYPE_DETAIL_ERROR'
// create
export const CREATE_PRODUCT_TYPE_SUCCESS = 'CREATE_PRODUCT_TYPE_SUCCESS'
export const CREATE_PRODUCT_TYPE_ERROR = 'CREATE_PRODUCT_TYPE_ERROR'
// update
export const UPDATE_PRODUCT_TYPE_SUCCESS = 'UPDATE_PRODUCT_TYPE_SUCCESS'
export const UPDATE_PRODUCT_TYPE_ERROR = 'UPDATE_PRODUCT_TYPE_ERROR'
// delete
export const DELETE_PRODUCT_TYPE_SUCCESS = 'DELETE_PRODUCT_TYPE_SUCCESS'
export const DELETE_PRODUCT_TYPE_ERROR = 'DELETE_PRODUCT_TYPE_ERROR'