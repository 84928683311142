import * as Type from 'store/actionTypes/product'

const initialStateWithoutData = {
  isLoading: false,
  isError: false,
  message: [],
}

const initialState = {
  ...initialStateWithoutData,
  data: {
    data: [], 
    page: 0, 
    pageSize: 10, 
    totalPage: 0
  },
  dataDetail: {},
}

const request = state => ({
  ...state,
  ...initialStateWithoutData,
  isLoading: true,
})

const requestError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  message: payload,
})


const getProductSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  data: payload,
})

const getProductDetailSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  dataDetail: payload,
})

const createProductSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  message: payload,
})

const updateProductSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  message: payload,
})

const deleteProductSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  message: payload,
})


const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case Type.PRODUCT_REQUEST: return request(state)
    case Type.PRODUCT_REQUEST_CUD: return request(state)

    case Type.GET_PRODUCT_SUCCESS: return getProductSuccess(state, action.payload)
    case Type.GET_PRODUCT_ERROR: return requestError(state, action.payload)

    case Type.GET_PRODUCT_DETAIL_SUCCESS: return getProductDetailSuccess(state, action.payload)
    case Type.GET_PRODUCT_DETAIL_ERROR: return requestError(state, action.payload)

    case Type.CREATE_PRODUCT_SUCCESS: return createProductSuccess(state, action.payload)
    case Type.CREATE_PRODUCT_ERROR: return requestError(state, action.payload)

    case Type.UPDATE_PRODUCT_SUCCESS: return updateProductSuccess(state, action.payload)
    case Type.UPDATE_PRODUCT_ERROR: return requestError(state, action.payload)

    case Type.DELETE_PRODUCT_SUCCESS: return deleteProductSuccess(state, action.payload)
    case Type.DELETE_PRODUCT_ERROR: return requestError(state, action.payload)
    default:
      return state
  }
}

export default productReducer
