import Swal from 'sweetalert2'
import * as Type from 'store/actionTypes/customer'
import { xhr } from 'utils'

export const getCustomer = (params = '') => async (dispatch) => {
  try {
    dispatch({
      type: Type.CUSTOMER_REQUEST,
    })
    const res = await xhr.get(`/customer?${params}`)
    dispatch({
      type: Type.GET_CUSTOMER_SUCCESS,
      payload: res.data,
    })
  } catch (err) {
    Swal.fire('error', err.message, 'error')
    dispatch({
      type: Type.GET_CUSTOMER_ERROR,
      payload: err.message,
    })
  }
}

export const createCustomer = (data) => async (dispatch) => {
  try {
    dispatch({
      type: Type.CUSTOMER_REQUEST,
    })
    const res = await xhr.post('/customer', data)
    // Swal.fire('success', res.message, 'success')
    dispatch({
      type: Type.CREATE_CUSTOMER_SUCCESS,
      payload: res.message,
    })
  } catch (err) {
    Swal.fire('error', err.message, 'error')
    dispatch({
      type: Type.CREATE_CUSTOMER_ERROR,
      payload: err.message,
    })
  }
}

export const updateCustomer = (data, id) => async (dispatch) => {
  try {
    dispatch({
      type: Type.CUSTOMER_REQUEST,
    })
    console.log(data)
    const res = await xhr.put(`/customer/${id}`, data)
    // Swal.fire('success', res.message, 'success')
    dispatch({
      type: Type.UPDATE_CUSTOMER_SUCCESS,
      payload: res.message,
    })
  } catch (err) {
    Swal.fire('error', err.message, 'error')
    dispatch({
      type: Type.UPDATE_CUSTOMER_ERROR,
      payload: err.message,
    })
    
  }
}

export const deleteCustomer = (id) => async (dispatch) => {
  try {
    dispatch({
      type: Type.CUSTOMER_REQUEST,
    })
    const res = await xhr.delete(`/customer/${id}`)
    Swal.fire('success', res.message, 'success')
    dispatch({
      type: Type.DELETE_CUSTOMER_SUCCESS,
      payload: res.message,
    })
  } catch (err) {
    Swal.fire('error', err.message, 'error')
    dispatch({
      type: Type.DELETE_CUSTOMER_ERROR,
      payload: err.message,
    })
  }
}
