import Swal from 'sweetalert2'
import * as Type from 'store/actionTypes/recap'
import { xhr } from 'utils'

export const getRecap = (params = '') => async (dispatch) => {
  try {
    dispatch({
      type: Type.RECAP_REQUEST,
    })
    const res = await xhr.get(`/recap?${params}`)
    dispatch({
      type: Type.GET_RECAP_SUCCESS,
      payload: res.data,
    })
  } catch (err) {
    Swal.fire('error', err.message, 'error')
    dispatch({
      type: Type.GET_RECAP_ERROR,
      payload: err.message,
    })
  }
}