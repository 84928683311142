import React, { useEffect, useState } from "react";
import "react-table/react-table.css";
import AuthenticatedNavbar from "components/Navbars/AuthenticatedNavbar.js";
import { xhr, formatNumber } from "utils";
import _ from "lodash";
import dataFake from "./data.json"
import moment from "moment"

const ShopeeOrderPage = (props) => {
  const [data, setData] = useState([]);
  const fetchData = async (params) => {
    // setData(dataFake.item);
    const data = await xhr.get(`/shopee/order`);
    setData(data.data.order_list);
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <AuthenticatedNavbar />
      <table style={{ border: "1px solid", width: "100%" }}>
        <tr style={{ border: "1px solid" }}>
          <th style={{ width: "10%" }}>create_time</th>
          <th style={{ width: "15%" }}>order_sn</th>
          <th style={{ width: "10%" }}>order_status</th>
          <th style={{ width: "10%" }}>buyer_username</th>
        </tr>
        {data.map((order) => (
          <tr style={{ border: "1px solid" }}>
            <td>{moment(Number(order.order_detail?.create_time) * 1000).format('DD-MM-YYYY HH:mm')}</td>
            <td>{order.order_sn}</td>
            <td>{order.order_status}</td>
            <td>{order.order_detail?.buyer_username}</td>
            <table style={{ border: "1px solid", width: "100%" }}>
              <tr style={{ border: "1px solid" }}>
                <th style={{ width: "10%" }}>model_id</th>
                <th style={{ width: "20%" }}>model_sku</th>
                <th style={{ width: "20%" }}>QTY</th>
                <th style={{ width: "15%" }}>original price</th>
                <th style={{ width: "15%" }}>discount price</th>
              </tr>
              {order.order_detail?.item_list.map((model, idxM) => (
                <tr style={{ border: "1px solid" }}>
                  <td>{model.model_id}</td>
                  <td>{model.model_sku}</td>
                  <td>{model.model_quantity_purchased}</td>
                  <td>{formatNumber(model.model_original_price)}</td>
                  <td>{formatNumber(model.model_discounted_price)}</td>
                </tr>
              ))}
            </table>
          </tr>
        ))}
      </table>
    </>
  );
};

export default ShopeeOrderPage;
