import React, {useEffect, useState} from "react"
import {NavLink, Row, Col, Card, CardBody, Table, Button, Modal, Form, InputGroup, FormControl} from "reactstrap"
import PropTypes from "prop-types"
import swal from "sweetalert2"
import {connect} from "react-redux"
import ReactTable from "react-table"
import "react-table/react-table.css"
import AuthenticatedNavbar from "components/Navbars/AuthenticatedNavbar.js";
import ProductTypeModal from "./ProductTypeModal"
import { 
  getProductType,
  updateProductType,
  deleteProductType
} from "store/actions"


export const Mode = {
  HIDE: "0",
  ADD: "1",
  EDIT: "2",
  DELETE: "3"
}

const ProductTypePage = (props) => {
  const [modeModalProductType, setModeModalProductType] = useState(Mode.HIDE)
  const [formModalProductType, setFormModalProductType] = useState({})

  const fetchData = async (params) => {
    await props.getProductType(params)
  }
  const onFetchData = async (rtState = {}) => {
    const { page, pageSize, filtered, sorted } = rtState

    const filteredStr = JSON.stringify(filtered)
    const sortedStr = JSON.stringify(sorted)

    let params = `page=${Number(page)}&pageSize=${pageSize}&filtered=${filteredStr}`

    fetchData(params)
  }
  function doShowModal(mode, data = null)
  {
    let form
    switch (mode) {
      case Mode.EDIT:
      case Mode.DELETE:
        form = data
        break
      case Mode.ADD:
        form = {}
        break
      default:
        break
    }

    setModeModalProductType(mode)
    setFormModalProductType(form)
  }

  const handleDelete =(data) => {
    let id = data._id
    swal.fire({
      title: `Apakah anda yakin ingin menghapus productType ini?`,
      text: "Data yang sudah terhapus tidak dapat dikembalikan",
      type: "warning", 
      showCancelButton: true,
      confirmButtonColor: "#d33", 
      confirmButtonText: "Ya", 
      cancelButtonColor: "#3085d6",
    }).then(async(result) => {
      if(result.value){
        await props.deleteProductType(id)
        fetchData()
      }
    })
  }

  const { productType } = props
  const { isLoading, isError, message, data } = productType
  const { data: dataList, page, pageSize, totalPage } = data

  const columns = [
    {
      Header: "#",
      width: 60,
      filterable: false,
      Cell: (props) => <p className="text-center">{(page * pageSize) + props.index + 1}</p>,
    },
    {
      Header: "Nama",
      accessor: "name",
    },
    {
      Header: "Aksi",
      width: 200,
      Cell: (props) => (
        <div className="text-center"> 
          <button 
            type="button" 
            className="btn btn-sm btn-success" 
            onClick={() => doShowModal(Mode.EDIT, props.original)}
            >
            Edit
          </button> 
          &nbsp;
          <button 
            type="button" 
            className="btn btn-sm btn-danger" 
            onClick={() => handleDelete(props.original)}
            >
            Hapus
          </button> 
        </div>
      )
    },
  ]

  return (
    <>
      <AuthenticatedNavbar />

      <div id="content" className="container-fluid pt-4 pb-4">
        <div className="title-lb">
          <label>Jenis Produk</label> <br />
          <span 
            onClick={() => props.history.goBack()}>
            <i className="fa fa-arrow-left" /> Kembali
          </span>
        </div>

        <Card className="shadow-sm">
          <CardBody>

            <Button 
              type="button" 
              className="btn btn-sm btn-success float-right" 
              onClick={() => doShowModal(Mode.ADD)}
              >
              <i className="fa fa-plus" /> Tambah
            </Button>
            <br />
            <br />
            <br />
            <ReactTable
              manual
              data={dataList}
              columns={columns}
              pages={totalPage}
              page={page}
              defaultPageSize={8}
              loading={isLoading}
              minRows={8}
              onFetchData={onFetchData}
            />
          </CardBody>
        </Card>

        <ProductTypeModal
          toggleModal={setModeModalProductType}
          data={formModalProductType}
          mode={modeModalProductType}
          refresh={() => fetchData()}
        />
      </div>
    </>
  )
}

ProductTypePage.propTypes = {
  productType: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
    isError: PropTypes.bool.isRequired,
    message: PropTypes.array,
    data: PropTypes.object.isRequired,
  }),
  getProductType: PropTypes.func.isRequired,
  updateProductType: PropTypes.func.isRequired,
  deleteProductType: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  productType: state.productType,
})

export default connect( mapStateToProps, {
	getProductType,
	updateProductType,
	deleteProductType,
})(ProductTypePage)
