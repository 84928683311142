export const objToFormData = (objFormData) => {
  const formData = new FormData()
  const ObjKeys = Object.keys(objFormData)

  for (let row of ObjKeys) {
    if (objFormData[row]) {
      formData.append(row, objFormData[row])
    }
  }
  return formData
}