import React, { useState, useEffect } from 'react'
import {
	Col,
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Form,
	FormGroup,
	Input,
	Label,
} from 'reactstrap'

import PropTypes from 'prop-types'
import Select,{components} from 'react-select'
import {connect} from 'react-redux'
import _ from "lodash"

import { Mode, modeModalProduct } from './ProductTypePage'
import { 
  createProductType,
  updateProductType
} from 'store/actions'
import { xhr } from 'utils'

// import  conso from 'components'
// console.log('conso',conso)
// import { Loading } from 'components'
const Loading = () =>  'Loading ...'

const ProductTypeModal = (props) => {
  const initForm = {
    name: '',
    description: '',
  }
  const [isSubmitting, setSubmittiing] = useState(false)
  const [error, setError] = useState(false)
  const [form, setForm] = useState(initForm)
  const [optionRequirements, setOptionRequirements] = useState([])
  const [optionCargoType, setOptionCargoType] = useState([])

	const { 
		isLoading, 
		isError, 
		message 
	} = props.productType
	// console.log(props.data)

	//componentDidMount
  useEffect(() => {
    fetchData()
  }, [])
	//componentDidUpdate
  useEffect(() => {
  	let newProps = { ...props.data }
  	let cargoTypeId = newProps.cargoType && newProps.cargoType._id
		newProps.cargoType = _.find( optionCargoType, {value: cargoTypeId})
  	// console.log('newtype', newProps.cargoType)

		setForm(newProps)
  },[props.data])
  const fetchData = async() => {
    try {
    }
    catch(e){
      console.log(e)
    }
  }

  const handleChange = ({target}) => {
    setForm({
    	...form,
      [target.name]: target.value,
    })
  }

  const handleChangeByName = (name, value) => {
	  if(name === 'requirement'){
		let requirement = value.map(val => {
			return val.value
		})
		return setForm({
			...form,
		[name]: requirement
		})
	  }
    setForm({
    	...form,
      [name]: value
    })
  }

	const createProduct = async () => {
		props.createProductType(form)
		
		if(!isError){
			_.delay(()=> {
				props.toggleModal(Mode.HIDE)
				props.refresh()
			},2000)
		}
	}

	const updateProduct = async (id) => {	
		props.updateProductType(form, id)

		if(!isError){
			_.delay(()=> {
				props.toggleModal(Mode.HIDE)
				props.refresh()
			},2000)
		}
	}

	const Option = props => (
		<div>
		  <components.Option {...props}>
			<input type="checkbox" checked={props.isSelected} onChange={() => null} />{" "}
			<label>{props.label}</label>
		  </components.Option>
		</div>
	  );
	  
	  const MultiValue = props => (
		<components.MultiValue {...props}>
		  <span>{props.data.label}</span>
		</components.MultiValue>
	  );

	const renderModalTitleAndFooter = () => {
		let title
		let footer

		switch (props.mode) {
			case Mode.ADD:
			title = <div><i className="fa fa-plutext, allowOutsideClick: false, allowEscapeKey: false, allowEnterKey: falses-circle" /> Tambah Jenis Produk</div>
			footer = (
				<ModalFooter>
					<Button
						color="light"
						onClick={() => props.toggleModal(Mode.HIDE)}
						disabled={isLoading}
					>
						Cancel
					</Button>

					<Button
						color="primary"
						onClick={() => { createProduct() }}
						disabled={isLoading}
					>
						{ !isLoading ? 'Tambah' : <Loading type='basicSpinnerBorder' /> }
					</Button>
				</ModalFooter>
			)
			break
			case Mode.EDIT:
			title = <div><i className="fa fa-pencil" /> Ubah Jenis Produk</div>
			footer = (
				<ModalFooter>
					<Button
						color="light"
						onClick={() => props.toggleModal(Mode.HIDE)}
						disabled={isLoading}
					>
						Cancel
					</Button>
					&nbsp;
					<Button
						color="primary"
						onClick={() => { updateProduct(props.data._id) }}
						disabled={isLoading}
					>
						{ !isLoading ? 'Ubah' : <Loading type='basicSpinnerBorder' /> }
					</Button>
				</ModalFooter>
			)
			break
			default:
		}
		return {title, footer}
	}



	let { title, footer } = renderModalTitleAndFooter()

	return (
			<Modal
				isOpen={props.mode !== Mode.HIDE}
				toggle={() => props.toggleModal(Mode.HIDE)}
				className={props.className}
			>
			<ModalHeader toggle={() => props.toggleModal(Mode.HIDE)}>
				{title}
			</ModalHeader>
			<ModalBody>
        <Form onSubmit={e => e.preventDefault()}>
          <FormGroup >
            <Label htmlFor="name">Nama Jenis</Label>
            <Input
              name="name"
              id="name"
              className=""
              placeholder="Masukan jenis"
              value={form.name}
              onChange={handleChange}
              autoComplete="off"
              required 
            />
          </FormGroup>
				</Form>
			</ModalBody>
			{footer}
		</Modal>
	)
}

ProductTypeModal.propTypes = {
  form: PropTypes.object,
  productType: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
    isError: PropTypes.bool.isRequired,
    message: PropTypes.array,
  }),
  createProductType: PropTypes.func.isRequired,
  updateProductType: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  productType: state.productType,
})


export default connect( mapStateToProps, {
  createProductType,
	updateProductType,
})(ProductTypeModal)

