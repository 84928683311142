/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";

function DarkFooter() {
  return (
    <footer 
      className="footer"
      data-background-color="black"
      style={{marginTop: '100px'}}
      >
      <Container>
        <nav>
          <ul>
            <li>
              <a
                href=""
              >
                Dzakira Official
              </a>
            </li>
          </ul>
        </nav>
        <div className="copyright" id="copyright">
          © {new Date().getFullYear()}
        </div>
      </Container>
    </footer>
  );
}

export default DarkFooter;
