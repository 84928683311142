import * as Type from 'store/actionTypes/productColor'

const initialStateWithoutData = {
  isLoading: false,
  isError: false,
  message: [],
}

const initialState = {
  ...initialStateWithoutData,
  data: {
    data: [], 
    page: 0, 
    pageSize: 10, 
    totalPage: 0
  },
  dataDetail: {},
}

const request = state => ({
  ...state,
  ...initialStateWithoutData,
  isLoading: true,
})

const requestError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  message: payload,
})


const getProductColorSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  data: payload,
})

const getProductColorDetailSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  dataDetail: payload,
})

const createProductColorSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  message: payload,
})

const updateProductColorSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  message: payload,
})

const deleteProductColorSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  message: payload,
})


const productColorReducer = (state = initialState, action) => {
  switch (action.type) {
    case Type.PRODUCT_COLOR_REQUEST: return request(state)
    case Type.PRODUCT_COLOR_REQUEST_CUD: return request(state)

    case Type.GET_PRODUCT_COLOR_SUCCESS: return getProductColorSuccess(state, action.payload)
    case Type.GET_PRODUCT_COLOR_ERROR: return requestError(state, action.payload)

    case Type.GET_PRODUCT_COLOR_DETAIL_SUCCESS: return getProductColorDetailSuccess(state, action.payload)
    case Type.GET_PRODUCT_COLOR_DETAIL_ERROR: return requestError(state, action.payload)

    case Type.CREATE_PRODUCT_COLOR_SUCCESS: return createProductColorSuccess(state, action.payload)
    case Type.CREATE_PRODUCT_COLOR_ERROR: return requestError(state, action.payload)

    case Type.UPDATE_PRODUCT_COLOR_SUCCESS: return updateProductColorSuccess(state, action.payload)
    case Type.UPDATE_PRODUCT_COLOR_ERROR: return requestError(state, action.payload)

    case Type.DELETE_PRODUCT_COLOR_SUCCESS: return deleteProductColorSuccess(state, action.payload)
    case Type.DELETE_PRODUCT_COLOR_ERROR: return requestError(state, action.payload)
    default:
      return state
  }
}

export default productColorReducer
