import Swal from 'sweetalert2'
import * as Type from 'store/actionTypes/setting'
import { xhr, objToFormData } from 'utils'

export const generateCredShopee = (data) => async (dispatch) => {
  try {
    dispatch({
      type: Type.GENERATE_CRED_SHOPEE_REQUEST,
    })

    const res = await xhr.post('/cred/shopee/generate/access-token', data)
    Swal.fire('success', res.message, 'success')
      .then(() => {
        window.location.reload()
      })

    dispatch({
      type: Type.CREATE_GENERATE_CRED_SHOPEE_SUCCESS,
      payload: res.message,
    })
  } catch (err) {
    Swal.fire('error', err.message, 'error')
    dispatch({
      type: Type.CREATE_GENERATE_CRED_SHOPEE_ERROR,
      payload: err.message,
    })
  }
}
