import jwt from 'jsonwebtoken'

export const getUser = () => {
  const accessToken = localStorage.getItem('accessToken')

	if(accessToken){
	  const { user } = jwt.decode(accessToken)
	  return user || ''
	}
	return ''
}
