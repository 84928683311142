const api_uri = {
  production: "https://api.dzakiraofficial.com/v1",
  development: "https://api.dzakiraofficial.com/v1",
  // production: "http://localhost:5001/v1",
  // development: "http://localhost:5001/v1",
};

const frontend_page_uri = {
  production: "https://dzakiraofficial.com",
  development: "http://localhost:3000",
};

const env = process.env.NODE_ENV || "development";

export const apiURL = api_uri[env];
export const frontendPageURL = frontend_page_uri[env];

export const priceType = [
  {
    value: "priceRetail",
    label: "Harga Ritel",
  },
  {
    value: "priceReseller",
    label: "Harga Reseller",
  },
];

export const barcodeType = [
  {
    value: "Dzakira",
    label: "Dzakira",
  },
  {
    value: "Shopee",
    label: "Shopee",
  },
  {
    value: "Tiktok",
    label: "Tiktok",
  },
];

export const sourceOfSales = [
  {
    value: "Shopee",
    label: "Shopee",
  },
  {
    value: "Whatsapp",
    label: "Whatsapp",
  },
  {
    value: "Lazada",
    label: "Lazada",
  },
  {
    value: "Tiktok",
    label: "Tiktok",
  },
];

export const inoutType = [
  {
    value: "Pengeluaran",
    label: "Pengeluaran",
  },
  {
    value: "Pemasukan",
    label: "Pemasukan",
  },
];

export const sizeType = [
  {
    value: "New Born",
    label: "New Born",
  },
  {
    value: "XS",
    label: "XS",
  },
  {
    value: "S",
    label: "S",
  },
  {
    value: "M",
    label: "M",
  },
  {
    value: "L",
    label: "L",
  },
  {
    value: "XL",
    label: "XL",
  },
  {
    value: "XXL",
    label: "XXL",
  },
  {
    value: "Tanpa Ukuran",
    label: "Tanpa Ukuran",
  },
];

export const colorType = [
  {
    value: "Putih",
    label: "Putih",
  },
  {
    value: "Hitam",
    label: "Hitam",
  },
  {
    value: "Kuning",
    label: "Kuning",
  },
  {
    value: "Merah",
    label: "Merah",
  },
  {
    value: "Ungu",
    label: "Ungu",
  },
  {
    value: "Hijau",
    label: "Hijau",
  },
  {
    value: "Biru",
    label: "Biru",
  },
  {
    value: "Orange",
    label: "Orange",
  },
  {
    value: "Cokelat",
    label: "Cokelat",
  },
  {
    value: "Abu",
    label: "Abu",
  },
  {
    value: "Merah Muda",
    label: "Merah Muda",
  },
  {
    value: "Merah Tua",
    label: "Merah Tua",
  },
  {
    value: "Biru Tua",
    label: "Biru Tua",
  },
  {
    value: "Abu Tua",
    label: "Abu Tua",
  },
  {
    value: "Hijau Tua",
    label: "Hijau Tua",
  },
  {
    value: "Cokelat Tua",
    label: "Cokelat Tua",
  },
  {
    value: "Ungu Tua",
    label: "Ungu Tua",
  },
  {
    value: "Biru Muda",
    label: "Biru Muda",
  },
  {
    value: "Abu Muda",
    label: "Abu Muda",
  },
  {
    value: "Kuning Muda",
    label: "Kuning Muda",
  },
  {
    value: "Hijau Muda",
    label: "Hijau Muda",
  },
  {
    value: "Cokelat Muda",
    label: "Cokelat Muda",
  },
  {
    value: "Ungu Muda",
    label: "Ungu Muda",
  },
  {
    value: "Magenta",
    label: "Magenta",
  },
  {
    value: "Nila",
    label: "Nila",
  },
  {
    value: "Biru Langit",
    label: "Biru Langit",
  },
  {
    value: "Biru Terang",
    label: "Biru Terang",
  },
  {
    value: "Merah Tua Terang",
    label: "Merah Tua Terang",
  },
  {
    value: "Kuning Sawo",
    label: "Kuning Sawo",
  },
  {
    value: "Kuning Apricot",
    label: "Kuning Apricot",
  },
  {
    value: "Emas",
    label: "Emas",
  },
  {
    value: "Kuning Pucat",
    label: "Kuning Pucat",
  },
  {
    value: "Biru Navy",
    label: "Biru Navy",
  },
  {
    value: "Marun",
    label: "Marun",
  },
  {
    value: "Merah Jambu",
    label: "Merah Jambu",
  },
  {
    value: "Putih Gading",
    label: "Putih Gading",
  },
  {
    value: "Hijau Lumut",
    label: "Hijau Lumut",
  },
];
