import Swal from 'sweetalert2'
import * as Type from 'store/actionTypes/product'
import { xhr, objToFormData } from 'utils'

export const getProduct = (params = '') => async (dispatch) => {
  try {
    dispatch({
      type: Type.PRODUCT_REQUEST,
    })
    const res = await xhr.get(`/product?${params}`)
    dispatch({
      type: Type.GET_PRODUCT_SUCCESS,
      payload: res.data,
    })
  } catch (err) {
    Swal.fire('error', err.message, 'error')
    dispatch({
      type: Type.GET_PRODUCT_ERROR,
      payload: err.message,
    })
  }
}

export const createProduct = (data) => async (dispatch) => {
  try {
    dispatch({
      type: Type.PRODUCT_REQUEST,
    })

    const formData = objToFormData({
      ...data,
      size: JSON.stringify(data.size),
      color: JSON.stringify(data.color),
      variation: JSON.stringify(data.variation),
    })

    const res = await xhr.post('/product', formData)
    Swal.fire('success', res.message, 'success')
      .then(() => {
        window.location.reload()
      })

    dispatch({
      type: Type.CREATE_PRODUCT_SUCCESS,
      payload: res.message,
    })
  } catch (err) {
    Swal.fire('error', err.message, 'error')
    dispatch({
      type: Type.CREATE_PRODUCT_ERROR,
      payload: err.message,
    })
  }
}

export const updateProduct = (data, id) => async (dispatch) => {
  try {
    dispatch({
      type: Type.PRODUCT_REQUEST,
    })

    const formData = objToFormData({
      ...data,
      size: JSON.stringify(data.size),
      color: JSON.stringify(data.color),
      variation: JSON.stringify(data.variation),
    })

    const res = await xhr.put(`/product/${id}`, formData)
    Swal.fire('success', res.message, 'success')
      .then(() => {
        window.location.reload()
      })
    dispatch({
      type: Type.UPDATE_PRODUCT_SUCCESS,
      payload: res.message,
    })
  } catch (err) {
    Swal.fire('error', err.message, 'error')
    dispatch({
      type: Type.UPDATE_PRODUCT_ERROR,
      payload: err.message,
    })
    
  }
}

export const deleteProduct = (id) => async (dispatch) => {
  try {
    dispatch({
      type: Type.PRODUCT_REQUEST,
    })
    const res = await xhr.delete(`/product/${id}`)
    Swal.fire('success', res.message, 'success')
    dispatch({
      type: Type.DELETE_PRODUCT_SUCCESS,
      payload: res.message,
    })
  } catch (err) {
    Swal.fire('error', err.message, 'error')
    dispatch({
      type: Type.DELETE_PRODUCT_ERROR,
      payload: err.message,
    })
  }
}
