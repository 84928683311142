import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  Row,
	Col,
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Form,
	FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
	Input,
	Label,
	Container,
} from 'reactstrap'

import PropTypes from 'prop-types'
import Select,{components} from 'react-select'
import {connect} from 'react-redux'
import _ from "lodash"
import Swal from 'sweetalert2'
import Barcode from 'react-barcode'

import { Mode, modeModalProduct } from './ProductPage'
import { 
} from 'store/actions'
import { sizeType, colorType, apiURL } from 'constant'
import { queryStringToJSON } from 'utils'
const Loading = () =>  'Loading ...'

const BarcodePage = (props) => {
	const print = () => {
		// const printableElements = document.getElementById('printme');
		// const orderHTML = '<html><head><title></title></head><body>' + printableElements + '</body></html>'
		// const oldPage = document.body.innerHTML;
		// document.body.innerHTML = orderHTML;
		window.print();
		// document.body.innerHTML = oldPage
	}

	const data = queryStringToJSON(props.location.search)

	return (
		<Container>

			<Row>
				<Col md={12} className="text-center bg-gray">
					<div onClick={() => print()} id="btn-print" className="btn btn-primary btn-lg">
						<b>Print</b>
					</div>
				</Col>
			</Row>
			<div id="printme">
				<Row>
					<Col md={6} style={{border: '1px solid'}}>
						| {data.productType}-{data.productModel}-{data.color}-{data.size} <br />
						<Barcode 
							value={props.match.params.id}
							/>
					</Col>
					<Col md={6} style={{border: '1px solid'}}>
						| {data.productType}-{data.productModel}-{data.color}-{data.size} <br />
						<Barcode 
							value={props.match.params.id}
							/>
					</Col>
				</Row>
				<Row>
					<Col md={6} style={{border: '1px solid'}}>
						| {data.productType}-{data.productModel}-{data.color}-{data.size} <br />
						<Barcode 
							value={props.match.params.id}
							/>
					</Col>
					<Col md={6} style={{border: '1px solid'}}>
						| {data.productType}-{data.productModel}-{data.color}-{data.size} <br />
						<Barcode 
							value={props.match.params.id}
							/>
					</Col>
				</Row>
				<Row>
					<Col md={6} style={{border: '1px solid'}}>
						| {data.productType}-{data.productModel}-{data.color}-{data.size} <br />
						<Barcode 
							value={props.match.params.id}
							/>
					</Col>
					<Col md={6} style={{border: '1px solid'}}>
						| {data.productType}-{data.productModel}-{data.color}-{data.size} <br />
						<Barcode 
							value={props.match.params.id}
							/>
					</Col>
				</Row>
				<Row>
					<Col md={6} style={{border: '1px solid'}}>
						| {data.productType}-{data.productModel}-{data.color}-{data.size} <br />
						<Barcode 
							value={props.match.params.id}
							/>
					</Col>
					<Col md={6} style={{border: '1px solid'}}>
						| {data.productType}-{data.productModel}-{data.color}-{data.size} <br />
						<Barcode 
							value={props.match.params.id}
							/>
					</Col>
				</Row>
				<Row>
					<Col md={6} style={{border: '1px solid'}}>
						| {data.productType}-{data.productModel}-{data.color}-{data.size} <br />
						<Barcode 
							value={props.match.params.id}
							/>
					</Col>
					<Col md={6} style={{border: '1px solid'}}>
						| {data.productType}-{data.productModel}-{data.color}-{data.size} <br />
						<Barcode 
							value={props.match.params.id}
							/>
					</Col>
				</Row>
				<Row>
					<Col md={6} style={{border: '1px solid'}}>
						| {data.productType}-{data.productModel}-{data.color}-{data.size} <br />
						<Barcode 
							value={props.match.params.id}
							/>
					</Col>
					<Col md={6} style={{border: '1px solid'}}>
						| {data.productType}-{data.productModel}-{data.color}-{data.size} <br />
						<Barcode 
							value={props.match.params.id}
							/>
					</Col>
				</Row>
				<Row>
					<Col md={6} style={{border: '1px solid'}}>
						| {data.productType}-{data.productModel}-{data.color}-{data.size} <br />
						<Barcode 
							value={props.match.params.id}
							/>
					</Col>
					<Col md={6} style={{border: '1px solid'}}>
						| {data.productType}-{data.productModel}-{data.color}-{data.size} <br />
						<Barcode 
							value={props.match.params.id}
							/>
					</Col>
				</Row>
				<Row>
					<Col md={6} style={{border: '1px solid'}}>
						| {data.productType}-{data.productModel}-{data.color}-{data.size} <br />
						<Barcode 
							value={props.match.params.id}
							/>
					</Col>
					<Col md={6} style={{border: '1px solid'}}>
						| {data.productType}-{data.productModel}-{data.color}-{data.size} <br />
						<Barcode 
							value={props.match.params.id}
							/>
					</Col>
				</Row>
				<Row>
					<Col md={6} style={{border: '1px solid'}}>
						| {data.productType}-{data.productModel}-{data.color}-{data.size} <br />
						<Barcode 
							value={props.match.params.id}
							/>
					</Col>
					<Col md={6} style={{border: '1px solid'}}>
						| {data.productType}-{data.productModel}-{data.color}-{data.size} <br />
						<Barcode 
							value={props.match.params.id}
							/>
					</Col>
				</Row>
			</div>
		</Container>
	)
}

BarcodePage.propTypes = {
}

const mapStateToProps = (state) => ({
})


export default connect( mapStateToProps, {
})(BarcodePage)

