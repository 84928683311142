import axios from 'axios'
import { apiURL } from 'constant'

function baseAxios(options) {
  const defaultHeaders = {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    'max-age': 0,
  }
  
  const accessToken = localStorage.getItem('accessToken')
  axios.defaults.headers.common.Authorization = accessToken

  const headers = {
    ...defaultHeaders,
    ...options.headers,
  }
  
  return axios.create({
    baseURL: `${apiURL}`,
    timeout: options.timeout || 10000000,
    headers,
  })
}

function executeRequest(method, pathname, data, options = {}) {
  const body = method === 'get' || !data ? {} : { data }
  const reqObj = {
    url: pathname,
    method,
    params: options.query,
    ...body,
  }
  const baseAxiosRequest = baseAxios(options)
  const xhr = new Promise((resolve, reject) => {
    baseAxiosRequest
      .request(reqObj)
      .then(res => {
        if(process.env.NODE_ENV === 'development'){
          console.log(`response logger, ${res.config.url} status ${res.status}`)
          console.log('DATA: ', res.data)
        }
        resolve(res.data)
      })
      .catch(error => {
        const res = error.response

        if (res &&
            res.status &&
            res.status === 401){
          localStorage.setItem('accessToken', '')
          window.location.reload()
        }

        if (res && 
            res.data &&
            res.data.message)
        {
          error = res.data
        }
        console.log('HTTP ERROR: ', error.message)
        if(error.message === 'error_auth') {
          axios.post(`${apiURL}/shopee/refresh-token`)
          .then(res => console.log(res))
          .catch(err => console.log(err))
        }

	      reject(error)
      })
  })

  return xhr
}

export const xhr = {
  async get(pathname, options) {
    return executeRequest('get', pathname, null, options)
  },

  async post(pathname, data, options) {
    return executeRequest('post', pathname, data, options)
  },

  async put(pathname, data, options) {
    return executeRequest('put', pathname, data, options)
  },

  async delete(pathname, data, options) {
    return executeRequest('delete', pathname, data, options)
  },

  all(promises) {
    return Promise.all(promises)
  },
}
