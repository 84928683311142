import React from 'react'
import Quagga from 'quagga'

import { 
  audio,
} from "assets"
import {Howl, Howler} from 'howler'

var sound = new Howl({
  src: [audio.beepCashier]
})
export default class Scanner extends React.Component {
    componentDidMount() {
        Quagga.init({
            "inputStream": {
                "type": "LiveStream",
                "constraints": {
                "width": { "min": 450 },
                "height": { "min": 300 },
                "facingMode": "environment",
                "aspectRatio": { "min": 1, "max": 2 }
                }
            },
            "locator": {
                "patchSize": "medium",
                "halfSample": true
            },
            "numOfWorkers": 2,
            "frequency": 2,
            "decoder": {
                "readers": ["code_128_reader"]
            },
            "locate": true
        }, function(err) {
            if (err) {
                return console.log(err)
            }
            Quagga.start()
        })
        Quagga.onDetected(this._onDetected)
        Quagga.onProcessed(this._onProcessed)
    }

    componentWillUnmount() {
        Quagga.offDetected(this._onDetected)
        Quagga.stop()
    }

    _onDetected = (result) => {
    //   sound.play()
      this.props.onDetected(result)
    }

    _onProcessed (result) {
        var drawingCtx = Quagga.canvas.ctx.overlay, drawingCanvas = Quagga.canvas.dom.overlay

        if (result) {
            if (result.boxes) {
                drawingCtx.clearRect(0, 0, parseInt(drawingCanvas.getAttribute("width")), parseInt(drawingCanvas.getAttribute("height")))
                result.boxes.filter(function (box) {
                return box !== result.box
                }).forEach(function (box) {
                Quagga.ImageDebug.drawPath(box, {x: 0, y: 1}, drawingCtx, {color: "green", lineWidth: 2})
                })
            }

            if (result.box) {
                Quagga.ImageDebug.drawPath(result.box, {x: 0, y: 1}, drawingCtx, {color: "#00F", lineWidth: 2})
            }

            if (result.codeResult && result.codeResult.code) {
                Quagga.ImageDebug.drawPath(result.line, {x: 'x', y: 'y'}, drawingCtx, {color: 'red', lineWidth: 3})
            }
        }
    }

    render() {
        return (
            <div id="interactive" className="viewport"/>
          
        )
    }

}