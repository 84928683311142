import React, { useState, useEffect } from 'react'
import {
	Col,
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Form,
	FormGroup,
	Input,
	Label,
} from 'reactstrap'

import PropTypes from 'prop-types'
import Select,{components} from 'react-select'
import {connect} from 'react-redux'
import _ from "lodash"

import { Mode, modeModalCustomer } from './CustomerPage'
import { 
  createCustomer,
  updateCustomer
} from 'store/actions'
import { xhr } from 'utils'
import {
  priceType as priceTypeList,
  sourceOfSales as sourceOfSalesList,
} from 'constant'

const Loading = () =>  'Loading ...'

const CustomerModal = (props) => {
  const initForm = {
    name: '',
    phone: '',
		sourceOfSales: '',
		priceType: ''
  }
  const [isSubmitting, setSubmittiing] = useState(false)
  const [error, setError] = useState(false)
  const [form, setForm] = useState(initForm)
  const [optionRequirements, setOptionRequirements] = useState([])
  const [optionCargoType, setOptionCargoType] = useState([])

	const { 
		isLoading, 
		isError, 
		message 
	} = props.customer
	// console.log(props.data)

	//componentDidMount
  useEffect(() => {
    fetchData()
  }, [])
	//componentDidUpdate
  useEffect(() => {
  	if (props.mode === Mode.EDIT) {
      let newProps = { ...props.data }

      if(newProps.priceType) {
				newProps.priceType = {
					label: newProps.priceType,
					value: newProps.priceType,
				}
			}

      if(newProps.sourceOfSales) {
				newProps.sourceOfSales = {
					label: newProps.sourceOfSales,
					value: newProps.sourceOfSales,
				}
			}

      setForm(newProps)
    }
    else {
      setForm(initForm)
    }
  },[props.data])
  const fetchData = async() => {
    try {
    }
    catch(e){
      console.log(e)
    }
  }

  const handleChange = ({target}) => {
    setForm({
    	...form,
      [target.name]: target.value,
    })
  }

  const handleChangeByName = (name, value) => {
    setForm({
    	...form,
      [name]: value
    })
  }

	const createCustomer = async () => {
		const sendForm = {
      ...form,
      priceType: form.priceType ? form.priceType.value : null,
      sourceOfSales: form.sourceOfSales ? form.sourceOfSales.value : null,
    }
		props.createCustomer(sendForm)
		
		if(!isError){
			_.delay(()=> {
				props.toggleModal(Mode.HIDE)
				props.refresh()
			},2000)
		}
	}

	const updateCustomer = async (id) => {	
		const sendForm = {
      ...form,
      priceType: form.priceType ? form.priceType.value : null,
      sourceOfSales: form.sourceOfSales ? form.sourceOfSales.value : null,
    }
		props.updateCustomer(sendForm, id)

		if(!isError){
			_.delay(()=> {
				props.toggleModal(Mode.HIDE)
				props.refresh()
			},2000)
		}
	}

	const Option = props => (
		<div>
		  <components.Option {...props}>
			<input type="checkbox" checked={props.isSelected} onChange={() => null} />{" "}
			<label>{props.label}</label>
		  </components.Option>
		</div>
	  );
	  
	  const MultiValue = props => (
		<components.MultiValue {...props}>
		  <span>{props.data.label}</span>
		</components.MultiValue>
	  );

	const renderModalTitleAndFooter = () => {
		let title
		let footer

		switch (props.mode) {
			case Mode.ADD:
			title = <div><i className="fa fa-plutext, allowOutsideClick: false, allowEscapeKey: false, allowEnterKey: falses-circle" /> Tambah  Customer</div>
			footer = (
				<ModalFooter>
					<Button
						color="light"
						onClick={() => props.toggleModal(Mode.HIDE)}
						disabled={isLoading}
					>
						Cancel
					</Button>

					<Button
						color="primary"
						onClick={() => { createCustomer() }}
						disabled={isLoading}
					>
						{ !isLoading ? 'Tambah' : <Loading type='basicSpinnerBorder' /> }
					</Button>
				</ModalFooter>
			)
			break
			case Mode.EDIT:
			title = <div><i className="fa fa-pencil" /> Ubah  Customer</div>
			footer = (
				<ModalFooter>
					<Button
						color="light"
						onClick={() => props.toggleModal(Mode.HIDE)}
						disabled={isLoading}
					>
						Cancel
					</Button>
					&nbsp;
					<Button
						color="primary"
						onClick={() => { updateCustomer(props.data._id) }}
						disabled={isLoading}
					>
						{ !isLoading ? 'Ubah' : <Loading type='basicSpinnerBorder' /> }
					</Button>
				</ModalFooter>
			)
			break
			default:
		}
		return {title, footer}
	}



	let { title, footer } = renderModalTitleAndFooter()

	return (
			<Modal
				isOpen={props.mode !== Mode.HIDE}
				toggle={() => props.toggleModal(Mode.HIDE)}
				className={props.className}
			>
			<ModalHeader toggle={() => props.toggleModal(Mode.HIDE)}>
				{title}
			</ModalHeader>
			<ModalBody>
        <Form onSubmit={e => e.preventDefault()}>
          <FormGroup >
            <Label htmlFor="name">Nama </Label>
            <Input
              name="name"
              id="name"
              className=""
              placeholder="Masukan customer"
              value={form.name}
              onChange={handleChange}
              autoComplete="off"
              required 
            />
          </FormGroup>
          <FormGroup >
            <Label htmlFor="phone">No. Telepon </Label>
            <Input
              name="phone"
              id="phone"
              className=""
              placeholder="Masukan nomor telepon"
              value={form.phone}
              onChange={handleChange}
              autoComplete="off"
              required 
            />
          </FormGroup>
					<FormGroup>
						<Label htmlFor="priceType"><div><b>jenis</b></div></Label>
						<Select
							id="priceType"
							name="priceType"
							className="basic-single"
							options={priceTypeList}
							value={form.priceType}
							classNamePrefix="select"
							onChange={(v) => handleChangeByName('priceType', v)}
						/>
					</FormGroup>
					<FormGroup>
						<Label htmlFor="sourceOfSales"><div><b>Sumber penjualan</b></div></Label>
						<Select
							id="sourceOfSales"
							name="sourceOfSales"
							className="basic-single"
							options={sourceOfSalesList}
							value={form.sourceOfSales}
							classNamePrefix="select"
							onChange={(v) => handleChangeByName('sourceOfSales', v)}
						/>
					</FormGroup>
				</Form>
			</ModalBody>
			{footer}
		</Modal>
	)
}

CustomerModal.propTypes = {
  form: PropTypes.object,
  customer: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
    isError: PropTypes.bool.isRequired,
    message: PropTypes.array,
  }),
  createCustomer: PropTypes.func.isRequired,
  updateCustomer: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  customer: state.customer,
})


export default connect( mapStateToProps, {
  createCustomer,
	updateCustomer,
})(CustomerModal)

