import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  Row,
	Col,
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Form,
	FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
	Input,
	Label,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardHeader,
  Alert,
  Container
} from 'reactstrap'

import PropTypes from 'prop-types'
import Select,{components} from 'react-select'
import {connect} from 'react-redux'
import _ from "lodash"
import Swal from 'sweetalert2'
import Barcode from 'react-barcode'
import { apiURL } from 'constant'
import { formatNumber } from 'utils'

const Mode = {
  HIDE: "0",
  SHOW: "1",
}

const Loading = () =>  'Loading ...'

const ProductHomeModal = (props) => {
  const [form, setForm] = useState({})
  const [pills, setPills] = React.useState(0)

	//componentDidUpdate
  useEffect(() => {
  	if (props.mode === Mode.SHOW) {
      let newProps = { ...props.data }
      setForm(newProps)

    }
  },[props.mode])

	return (
			<Modal
				isOpen={props.mode !== Mode.HIDE}
				toggle={() => props.toggleModal(Mode.HIDE)}
				className={props.className}
			>
			<ModalHeader toggle={() => props.toggleModal(Mode.HIDE)}>
			</ModalHeader>
			<ModalBody>
        <Row>
          <Col md={4}>
              <img
                alt="..."
                className="rounded"
                src={form.image 
                  ? (form.image.url ? form.image.url : apiURL+form.image)
                  : 'https://via.placeholder.com/150'}
              ></img>    
          </Col>
          <Col md={8}>
              <Label><b>{form.name}</b></Label> <br />

                <span className="">{form.productType?.name}</span>, &nbsp;
                <span className="">{form.productModel?.name}</span>

              <hr style={{margin: '10px 0px 10px 0px'}}/>
              <Nav className="" role="tablist" tabs>
                { form.size 
                  && form.size.map((row, index) => {
                  return <NavItem>
                    <NavLink
                      className={pills === index ? "active" : ""}
                      href="#variation"
                      onClick={(e) => {
                        e.preventDefault();
                        setPills(index)
                      }}
                      style={{
                        fontSize: '12px',
                        padding: '5px 10px'
                        // line-height: 1.5,
                      }}
                    >
                      {row}
                    </NavLink>
                  </NavItem>
                })}
              </Nav>
              
                { form.variation 
                  && form.variation.map((row, index) => {
                  return <TabContent
                    activeTab={"pills" + pills}
                    >
                    <TabPane tabId={"pills" + index}>
                      <p>
                      { row.colors
                        && row.colors.map((rowColor, index) => {
                        return <Row>
                          <Col md="4">
                            {rowColor.color}
                          </Col>
                          <Col md="8">
                            <small>Tersisa</small> <b>{formatNumber(rowColor.stock)} pcs</b>
                          </Col>
                        </Row>
                      })}

                      </p>
                      <Alert color="primary" className="text-right">
                        <Container>
                          <b style={{fontSize: '20px'}}>Rp. {formatNumber(row.priceRetail)}</b>
                        </Container>
                      </Alert>
                    </TabPane>
                  </TabContent>
                })}
          </Col>
        </Row>
			</ModalBody>
		</Modal>
	)
}

const mapStateToProps = (state) => ({
})


export default connect( mapStateToProps, {
})(ProductHomeModal)

