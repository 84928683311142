import React, { useState, useEffect } from 'react'
import {
  Row,
	Col,
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Form,
	FormGroup,
	Input,
	Label,
} from 'reactstrap'

import PropTypes from 'prop-types'
import Select,{components} from 'react-select'
import {connect} from 'react-redux'
import _ from "lodash"
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import { Mode, modeModalInout } from './InoutPage'
import { 
  createInout,
  updateInout,
	getInoutType,
	getInoutModel,
} from 'store/actions'
import { 
  inoutType as optionInoutType,
} from 'constant'
console.log(optionInoutType)

const Loading = () =>  'Loading ...'

const InoutModal = (props) => {
  const initForm = {
    description: '',
		inoutType: optionInoutType[0].value,
		amount: '',
		date: new Date()
  }
  const [isSubmitting, setSubmittiing] = useState(false)
  const [error, setError] = useState(false)
  const [form, setForm] = useState(initForm)
console.log(form)
	const { 
		isLoading, 
		isError, 
		message 
	} = props.inout

	//componentDidMount
  useEffect(() => {
    fetchData()
  }, [])
	//componentDidUpdate
  useEffect(() => {
  	let newProps = Object.keys(props.data).length === 0
			? { ...initForm }
			: { ...props.data }

  	let inoutTypeId = newProps.inoutType
		newProps.inoutType = _.find( optionInoutType, {value: inoutTypeId})
		newProps.date = new Date(newProps.date)

		setForm(newProps)
  },[props.data])

  const fetchData = async() => {
    try {
			// nothing fetch
    }
    catch(e){
      console.log(e.message)
    }
  }

  const handleChange = ({target}) => {
    setForm({
    	...form,
      [target.name]: target.value,
    })
  }

  const handleChangeByName = (name, value) => {
    setForm({
    	...form,
      [name]: value
    })
  }

	const createInout = async () => {
    const sendForm = {
      ...form,
      inoutType: form.inoutType ? form.inoutType.value : null,
			inoutCategory: 'manual',
    }
		props.createInout(sendForm)
		
		if(!isError){
			_.delay(()=> {
				props.toggleModal(Mode.HIDE)
				props.refresh()
			},2000)
		}
	}

	const updateInout = async (id) => {	
    const sendForm = {
      ...form,
      inoutType: form.inoutType ? form.inoutType.value : null,
			inoutCategory: 'manual',
    }
		props.updateInout(sendForm, id)

		if(!isError){
			_.delay(()=> {
				props.toggleModal(Mode.HIDE)
				props.refresh()
			},2000)
		}
	}

	const renderModalTitleAndFooter = () => {
		let title
		let footer

		switch (props.mode) {
			case Mode.ADD:
			title = <div><i className="fa fa-plutext, allowOutsideClick: false, allowEscapeKey: false, allowEnterKey: falses-circle" /> Tambah</div>
			footer = (
				<ModalFooter>
					<Button
						color="light"
						onClick={() => props.toggleModal(Mode.HIDE)}
						disabled={isLoading}
					>
						Cancel
					</Button>

					<Button
						color="primary"
						onClick={() => { createInout() }}
						disabled={isLoading}
					>
						{ !isLoading ? 'Tambah' : <Loading type='basicSpinnerBorder' /> }
					</Button>
				</ModalFooter>
			)
			break
			case Mode.EDIT:
			title = <div><i className="fa fa-pencil" /> Ubah</div>
			footer = (
				<ModalFooter>
					<Button
						color="light"
						onClick={() => props.toggleModal(Mode.HIDE)}
						disabled={isLoading}
					>
						Cancel
					</Button>
					&nbsp;
					<Button
						color="primary"
						onClick={() => { updateInout(props.data._id) }}
						disabled={isLoading}
					>
						{ !isLoading ? 'Ubah' : <Loading type='basicSpinnerBorder' /> }
					</Button>
				</ModalFooter>
			)
			break
			default:
		}
		return {title, footer}
	}

	let { title, footer } = renderModalTitleAndFooter()

	return (
			<Modal
				isOpen={props.mode !== Mode.HIDE}
				toggle={() => props.toggleModal(Mode.HIDE)}
				className={props.className}
			>
			<ModalHeader toggle={() => props.toggleModal(Mode.HIDE)}>
				{title}
			</ModalHeader>
			<ModalBody>
        <Form onSubmit={e => e.preventDefault()}>
					<Row>
						<FormGroup className="col-md-6">
							<Label htmlFor="date">Tanggal</Label>
							<DatePicker
								name="date"
								id="date"
								style={{width: '100%'}}
								autoComplete="off"
								selected={form.date}
								onChange={(v) => handleChangeByName('date', v)}
							/>
							<br />
						</FormGroup>

						<FormGroup className="col-md-6">
							<Label htmlFor="inoutType">Tipe</Label>
							<Select
								id="inoutType"
								name="inoutType"
								className="basic-single"
								options={optionInoutType}
								value={form.inoutType}
								classNamePrefix="select"
								placeholder="Masukan tipe"
								onChange={(v) => handleChangeByName('inoutType', v)}
							/>
						</FormGroup>
					</Row>
					<FormGroup>
						<Label htmlFor="description">Keterangan </Label>
						<Input
							name="description"
							id="description"
							className=""
							placeholder="Masukan keterangan"
							value={form.description}
							onChange={handleChange}
							autoComplete="off"
							required 
						/>
					</FormGroup>
					<FormGroup>
						<Label htmlFor="name">Jumlah Uang</Label>
						<Input
							type="number"
							min={1}
							name="amount"
							id="amount"
							className=""
							placeholder="Masukan jumlah uang"
							value={form.amount}
							onChange={handleChange}
							autoComplete="off"
							required 
						/>
					</FormGroup>
				</Form>
			</ModalBody>
			{footer}
		</Modal>
	)
}

InoutModal.propTypes = {
  form: PropTypes.object,
  product: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
    isError: PropTypes.bool.isRequired,
    message: PropTypes.array,
  }),
  createInout: PropTypes.func.isRequired,
  updateInout: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  inout: state.inout,
})


export default connect( mapStateToProps, {
  createInout,
	updateInout,
})(InoutModal)

