import Swal from 'sweetalert2'
import * as Type from 'store/actionTypes/productType'
import { xhr } from 'utils'

export const getProductType = (params = '') => async (dispatch) => {
  try {
    dispatch({
      type: Type.PRODUCT_TYPE_REQUEST,
    })
    const res = await xhr.get(`/product-type?${params}`)
    dispatch({
      type: Type.GET_PRODUCT_TYPE_SUCCESS,
      payload: res.data,
    })
  } catch (err) {
    Swal.fire('error', err.message, 'error')
    dispatch({
      type: Type.GET_PRODUCT_TYPE_ERROR,
      payload: err.message,
    })
  }
}

export const createProductType = (data) => async (dispatch) => {
  try {
    dispatch({
      type: Type.PRODUCT_TYPE_REQUEST,
    })
    const res = await xhr.post('/product-type', data)
    Swal.fire('success', res.message, 'success')
    dispatch({
      type: Type.CREATE_PRODUCT_TYPE_SUCCESS,
      payload: res.message,
    })
  } catch (err) {
    Swal.fire('error', err.message, 'error')
    dispatch({
      type: Type.CREATE_PRODUCT_TYPE_ERROR,
      payload: err.message,
    })
  }
}

export const updateProductType = (data, id) => async (dispatch) => {
  try {
    dispatch({
      type: Type.PRODUCT_TYPE_REQUEST,
    })
    console.log(data)
    const res = await xhr.put(`/product-type/${id}`, data)
    Swal.fire('success', res.message, 'success')
    dispatch({
      type: Type.UPDATE_PRODUCT_TYPE_SUCCESS,
      payload: res.message,
    })
  } catch (err) {
    Swal.fire('error', err.message, 'error')
    dispatch({
      type: Type.UPDATE_PRODUCT_TYPE_ERROR,
      payload: err.message,
    })
    
  }
}

export const deleteProductType = (id) => async (dispatch) => {
  try {
    dispatch({
      type: Type.PRODUCT_TYPE_REQUEST,
    })
    const res = await xhr.delete(`/product-type/${id}`)
    Swal.fire('success', res.message, 'success')
    dispatch({
      type: Type.DELETE_PRODUCT_TYPE_SUCCESS,
      payload: res.message,
    })
  } catch (err) {
    Swal.fire('error', err.message, 'error')
    dispatch({
      type: Type.DELETE_PRODUCT_TYPE_ERROR,
      payload: err.message,
    })
  }
}
