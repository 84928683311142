import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap";

import PropTypes from "prop-types";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { connect } from "react-redux";
import _ from "lodash";
import Swal from "sweetalert2";
import { xhr } from "utils";
import { getUser } from "utils";

import { Mode, modeModalProduct } from "./ProductPage";
import {
  createProduct,
  updateProduct,
  getProductType,
  getProductModel,
  getProductColor,
} from "store/actions";
import { sizeType, apiURL } from "constant";
const Loading = () => "Loading ...";

const ProductModal = (props) => {
  const initForm = {
    name: "",
    description: "",
    size: [],
    color: [],
    variation: [],
  };
  const [isSubmitting, setSubmittiing] = useState(false);
  const [error, setError] = useState(false);
  const [form, setForm] = useState(initForm);
  const [isLoadingColor, set_isLoadingColor] = useState(false);
  const account = getUser();

  const { isLoading, isError, message } = props.product;

  const {
    productType: productTypeList,
    productModel: productModelList,
    productColor: productColorList,
  } = props;

  const optionProductType = productTypeList.data.data.map((row) => {
    return {
      label: row.name,
      value: row._id,
    };
  });
  const optionProductModel = productModelList.data.data.map((row) => {
    return {
      label: row.name,
      value: row._id,
    };
  });
  const optionProductColor = productColorList.data.data.map((row) => {
    return {
      label: row.name,
      value: row.name,
    };
  });

  const optionSize = sizeType;

  //componentDidMount
  useEffect(() => {
    fetchData();
  }, []);
  //componentDidUpdate
  useEffect(() => {
    if (props.mode === Mode.EDIT) {
      let newProps = { ...props.data };

      let productTypeId = newProps.productType && newProps.productType._id;
      let productModelId = newProps.productModel && newProps.productModel._id;
      let sizeIds = newProps.size; //&& newProps.productModel._id
      let colorIds = newProps.color; //&& newProps.productModel._id
      newProps.productType = _.find(optionProductType, {
        value: productTypeId,
      });
      newProps.productModel = _.find(optionProductModel, {
        value: productModelId,
      });
      newProps.size = sizeIds.map((row) => {
        return _.find(optionSize, { value: row });
      });
      newProps.color = colorIds.map((row) => {
        return _.find(optionProductColor, { value: row });
      });
      console.log(newProps.color);
      setForm(newProps);
    } else {
      setForm(initForm);
    }
  }, [props.data]);

  const fetchData = async () => {
    try {
      props.getProductType();
      props.getProductModel();
      props.getProductColor();
    } catch (e) {
      console.log(e.message);
    }
  };

  const handleChangeFile = (name, target) => {
    const image = target.files[0];

    if (image.size > 2 * 1024 * 1024) {
      return Swal.fire(
        "Oops ...",
        "File tidak boleh melebihi dari 2mb",
        "error"
      );
    }

    image.url = URL.createObjectURL(image);

    setForm({
      ...form,
      [name]: image,
    });
  };

  const handleChangeByName = (name, value) => {
    let newVariation = [...form.variation];
    if (name === "size") {
      value = value == null ? [] : value;

      if (value.length < form.size.length) {
        newVariation = newVariation.filter((row, idx) => {
          const sizeArr = value.map((row) => row.value);
          return sizeArr.includes(row.size);
        });
      } else if (value.length > form.size.length) {
        const idx = value.length - 1;
        newVariation[idx] = {
          size: value[idx].value,
          priceCapital: 0,
          priceReseller: 0,
          priceRetail: 0,
          colors: form.color.map((rowColor) => {
            return {
              color: rowColor.value,
              stock: 0,
              barcode: "",
              shopeeIds: "",
              tiktokIds: "",
            };
          }),
        };
      }
    } else if (name === "color") {
      value = value == null ? [] : value;

      if (value.length < form.color.length) {
        newVariation = newVariation.map((rowSize) => {
          return {
            ...rowSize,
            colors: rowSize.colors.filter((rowColor, idx) => {
              const sizeArr = value.map((row) => row.value);
              return sizeArr.includes(rowColor.color);
            }),
          };
        });
      } else if (value.length > form.color.length) {
        const idx = value.length - 1;
        newVariation = newVariation.map((rowSize) => {
          return {
            ...rowSize,
            colors: [
              ...rowSize.colors,
              {
                color: value[idx].value,
                stock: 0,
                barcode: "",
                shopeeIds: "",
                tiktokIds: "",
              },
            ],
          };
        });
      }
    } else if (
      name.includes("priceCapital") ||
      name.includes("priceReseller") ||
      name.includes("priceRetail") ||
      name.includes("stock")
    ) {
      const nameSplit = name.split(".");
      value = Number(String(value).replace(",", ""));
      value = String(value);
      if (nameSplit.length === 2) {
        const field = nameSplit[0];
        const indexSize = nameSplit[1];
        newVariation[indexSize][field] = Number(value);
      } else if (nameSplit.length === 3) {
        const field = nameSplit[0];
        const indexSize = nameSplit[1];
        const indexColor = nameSplit[2];
        newVariation[indexSize].colors[indexColor][field] = Number.isInteger(
          value
        )
          ? Number(value)
          : value;
      }
    } else if (name.includes("shopeeIds") || name.includes("tiktokIds")) {
      const nameSplit = name.split(".");
      if (nameSplit.length === 3) {
        const field = nameSplit[0];
        const indexSize = nameSplit[1];
        const indexColor = nameSplit[2];
        newVariation[indexSize].colors[indexColor][field] = value;
      }
    }

    setForm({
      ...form,
      [name]: value,
      variation: newVariation,
    });
  };

  const createProduct = async () => {
    const sendForm = {
      ...form,
      productType: form.productType ? form.productType.value : null,
      productModel: form.productModel ? form.productModel.value : null,
      size: form.size.map((row) => row.value),
      color: form.color.map((row) => row.value),
    };
    props.createProduct(sendForm);
  };

  const updateProduct = async (id) => {
    const sendForm = {
      ...form,
      productType: form.productType ? form.productType.value : null,
      productModel: form.productModel ? form.productModel.value : null,
      size: form.size.map((row) => row.value),
      color: form.color.map((row) => row.value),
    };
    props.updateProduct(sendForm, id);
  };

  const handleCreateColor = async (inputValue: any) => {
    set_isLoadingColor(true);
    const res = await xhr.post("/product-color", { name: inputValue });

    const name = "color";
    const value = form.color.concat([
      {
        label: res.data.name,
        value: res.data.name,
      },
    ]);

    fetchData();
    set_isLoadingColor(false);
    handleChangeByName(name, value);
  };

  const renderModalTitleAndFooter = () => {
    let title;
    let footer;

    switch (props.mode) {
      case Mode.ADD:
        title = (
          <div>
            <i className="fa fa-plutext, allowOutsideClick: false, allowEscapeKey: false, allowEnterKey: falses-circle" />{" "}
            Tambah Produk
          </div>
        );
        footer = (
          <ModalFooter>
            <Button
              color="light"
              onClick={() => props.toggleModal(Mode.HIDE)}
              disabled={isLoading}
            >
              Cancel
            </Button>

            <Button
              color="primary"
              onClick={() => {
                createProduct();
              }}
              disabled={isLoading}
            >
              {!isLoading ? "Tambah" : <Loading type="basicSpinnerBorder" />}
            </Button>
          </ModalFooter>
        );
        break;
      case Mode.EDIT:
        title = (
          <div>
            <i className="fa fa-pencil" /> Ubah Produk
          </div>
        );
        footer = (
          <ModalFooter>
            <Button
              color="light"
              onClick={() => props.toggleModal(Mode.HIDE)}
              disabled={isLoading}
            >
              Cancel
            </Button>
            &nbsp;
            <Button
              color="primary"
              onClick={() => {
                updateProduct(props.data._id);
              }}
              disabled={isLoading}
            >
              {!isLoading ? "Ubah" : <Loading type="basicSpinnerBorder" />}
            </Button>
          </ModalFooter>
        );
        break;
      default:
    }
    return { title, footer };
  };

  let { title, footer } = renderModalTitleAndFooter();

  return (
    <Modal
      isOpen={props.mode !== Mode.HIDE}
      toggle={() => props.toggleModal(Mode.HIDE)}
      className={props.className}
    >
      <ModalHeader toggle={() => props.toggleModal(Mode.HIDE)}>
        {title}
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={(e) => e.preventDefault()}>
          <Row>
            <FormGroup className="col-md-3">
              <Label htmlFor="image">Foto Produk </Label> <br />
              <img
                alt="..."
                className="rounded"
                src={
                  form.image
                    ? form.image.url
                      ? form.image.url
                      : apiURL + form.image
                    : "https://via.placeholder.com/150"
                }
              ></img>
              <Input
                name="image"
                id="image"
                type="file"
                onChange={(e) => handleChangeFile("image", e.target)}
              />
            </FormGroup>
          </Row>
          <Row>
            <FormGroup className="col-md-6">
              <Label htmlFor="name">Nama </Label>
              <Input
                name="name"
                id="name"
                className=""
                placeholder="Masukan nama produk"
                value={form.name}
                onChange={(e) => handleChangeByName("name", e.target.value)}
                autoComplete="off"
                required
              />
            </FormGroup>
          </Row>

          <FormGroup>
            <Label htmlFor="productType">Jenis Produk</Label>
            <Select
              id="productType"
              name="productType"
              className="basic-single"
              options={optionProductType}
              value={form.productType}
              classNamePrefix="select"
              placeholder="Masukan jenis produk"
              onChange={(v) => handleChangeByName("productType", v)}
              isClearable
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="productModel">Model Produk</Label>
            <Select
              id="productModel"
              name="productModel"
              className="basic-single"
              options={optionProductModel}
              value={form.productModel}
              classNamePrefix="select"
              placeholder="Masukan model produk"
              onChange={(v) => handleChangeByName("productModel", v)}
              isClearable
            />
          </FormGroup>

          <FormGroup>
            <Label htmlFor="size">Ukuran</Label>
            <Select
              id="size"
              name="size"
              className="basic-single"
              options={optionSize}
              value={form.size}
              classNamePrefix="select"
              placeholder="Masukan ukuran"
              onChange={(v) => handleChangeByName("size", v)}
              isClearable
              isMulti
            />
          </FormGroup>

          <FormGroup>
            <Label htmlFor="color">Warna</Label>
            <CreatableSelect
              id="color"
              name="color"
              placeholder="Masukan warna"
              isDisabled={props.productColor.isLoading || isLoadingColor}
              isLoading={props.productColor.isLoading || isLoadingColor}
              onChange={(v) => handleChangeByName("color", v)}
              onCreateOption={handleCreateColor}
              options={optionProductColor}
              value={form.color}
              isClearable={props.mode !== Mode.EDIT}
              isMulti
            />
          </FormGroup>

          {form.variation &&
            form.variation.map((rowSize, indexSize) => {
              return (
                <Card>
                  <CardBody>
                    <FormGroup className="col-md-4">
                      <Label>
                        <b>Ukuran {rowSize.size} </b>
                      </Label>
                    </FormGroup>
                    <Row>
                      <Col className="col-md-4">
                        <Row>
                          <FormGroup className="col-md-6">
                            <Label htmlFor="priceCapital">Harga Modal</Label>
                            <Input
                              name={`priceCapital.${indexSize}`}
                              id={`priceCapital.${indexSize}`}
                              type="number"
                              min={1}
                              className=""
                              placeholder="Masukan harga modal"
                              value={form.variation[indexSize].priceCapital}
                              onChange={(e) =>
                                handleChangeByName(
                                  `priceCapital.${indexSize}`,
                                  e.target.value
                                )
                              }
                              autoComplete="off"
                              required
                            />
                          </FormGroup>
                          {/* <FormGroup  className="col-md-12">
                      <Label htmlFor="priceReseller">Harga Reseller</Label>
                      <Input
                        name={`priceReseller.${indexSize}`}
                        id={`priceReseller.${indexSize}`}
                        type="number"
                        min={1}
                        className=""
                        placeholder="Masukan harga retail"
                        value={form.variation[indexSize].priceReseller}
                        onChange={e => handleChangeByName(`priceReseller.${indexSize}`, e.target.value)}
                        autoComplete="off"
                        required 
                      />
                    </FormGroup> */}
                          <FormGroup className="col-md-6">
                            <Label htmlFor="priceRetail">Harga Ritel</Label>
                            <Input
                              name={`priceRetail.${indexSize}`}
                              id={`priceRetail.${indexSize}`}
                              type="number"
                              min={1}
                              className=""
                              placeholder="Masukan harga retail"
                              value={form.variation[indexSize].priceRetail}
                              onChange={(e) =>
                                handleChangeByName(
                                  `priceRetail.${indexSize}`,
                                  e.target.value
                                )
                              }
                              autoComplete="off"
                              required
                            />
                          </FormGroup>
                        </Row>
                      </Col>
                      <Col className="col-md-8">
                        {rowSize.colors &&
                          rowSize.colors.map((rowColor, indexColor) => {
                            return (
                              <Row
                                style={{
                                  border: "1px solid #f1f1f1",
                                  borderRadius: 4,
                                  marginBottom: 4,
                                }}
                              >
                                <Col className="col-md-2">
                                  <b>{rowColor.color}</b>
                                  <br />
                                  {props.mode === Mode.EDIT && (
                                    <Link
                                      to={
                                        `/a/barcode/${form.variation[indexSize].colors[indexColor].barcode}` +
                                        `?productType=${
                                          form.productType
                                            ? form.productType.label
                                            : ""
                                        }` +
                                        `&productModel=${
                                          form.productModel
                                            ? form.productModel.label
                                            : ""
                                        }` +
                                        `&size=${rowSize.size}` +
                                        `&color=${rowColor.color}`
                                      }
                                      target="_blank"
                                      id="navbar-brand"
                                      style={{
                                        marginBottom: "20px",
                                        textAlign: "right",
                                      }}
                                    >
                                      <small>
                                        <b>
                                          <i className="fa fa-eye"></i> barcode
                                        </b>
                                      </small>
                                    </Link>
                                  )}
                                </Col>

                                <Col className="col-md-2">
                                  <FormGroup>
                                    <Label htmlFor="stock">Stok</Label>
                                    <Input
                                      name={`stock.${indexSize}.${indexColor}`}
                                      id={`stock.${indexSize}.${indexColor}`}
                                      placeholder="Masukan jumlah stok"
                                      value={
                                        form.variation[indexSize].colors[
                                          indexColor
                                        ].stock
                                      }
                                      onChange={(e) =>
                                        handleChangeByName(
                                          `stock.${indexSize}.${indexColor}`,
                                          e.target.value
                                        )
                                      }
                                      autoComplete="off"
                                      // disabled={account.role !== 'superadmin'}
                                      required
                                    />
                                  </FormGroup>
                                </Col>
                                <Col className="col-md-4">
                                  <FormGroup>
                                    <Label htmlFor="shopeeIds">
                                      Shopee Ids
                                    </Label>
                                    <Input
                                      name={`shopeeIds.${indexSize}.${indexColor}`}
                                      id={`shopeeIds.${indexSize}.${indexColor}`}
                                      placeholder="Masukan Shopee Ids"
                                      value={
                                        form.variation[indexSize].colors[
                                          indexColor
                                        ].shopeeIds
                                      }
                                      onChange={(e) =>
                                        handleChangeByName(
                                          `shopeeIds.${indexSize}.${indexColor}`,
                                          e.target.value
                                        )
                                      }
                                      autoComplete="off"
                                      // disabled={account.role !== 'superadmin'}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col className="col-md-4">
                                  <FormGroup>
                                    <Label htmlFor="tiktokIds">
                                      Tiktok Ids
                                    </Label>
                                    <Input
                                      name={`tiktokIds.${indexSize}.${indexColor}`}
                                      id={`tiktokIds.${indexSize}.${indexColor}`}
                                      placeholder="Masukan Tiktok Ids"
                                      value={
                                        form.variation[indexSize].colors[
                                          indexColor
                                        ].tiktokIds
                                      }
                                      onChange={(e) =>
                                        handleChangeByName(
                                          `tiktokIds.${indexSize}.${indexColor}`,
                                          e.target.value
                                        )
                                      }
                                      autoComplete="off"
                                      // disabled={account.role !== 'superadmin'}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                            );
                          })}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              );
            })}
        </Form>
      </ModalBody>
      {footer}
    </Modal>
  );
};

ProductModal.propTypes = {
  form: PropTypes.object,
  product: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
    isError: PropTypes.bool.isRequired,
    message: PropTypes.array,
  }),
  createProduct: PropTypes.func.isRequired,
  updateProduct: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  product: state.product,
  productType: state.productType,
  productModel: state.productModel,
  productColor: state.productColor,
});

export default connect(mapStateToProps, {
  createProduct,
  updateProduct,
  getProductType,
  getProductModel,
  getProductColor,
})(ProductModal);
