import React, {useEffect, useState} from "react"
import {NavLink, Row, Col, Card, CardBody, Table, Button, Modal, Form, InputGroup, FormControl} from "reactstrap"
import PropTypes from "prop-types"
import swal from "sweetalert2"
import {connect} from "react-redux"
import ReactTable from "react-table"
import "react-table/react-table.css"
import AuthenticatedNavbar from "components/Navbars/AuthenticatedNavbar.js";
import CustomerModal from "./CustomerModal"
import { 
  getCustomer,
  updateCustomer,
  deleteCustomer
} from "store/actions"
import {
  img
} from 'assets'

export const Mode = {
  HIDE: "0",
  ADD: "1",
  EDIT: "2",
  DELETE: "3"
}

const CustomerPage = (props) => {
  const [modeModalCustomer, setModeModalCustomer] = useState(Mode.HIDE)
  const [formModalCustomer, setFormModalCustomer] = useState({})

  const fetchData = async (params) => {
    await props.getCustomer(params)
  }
  const onFetchData = async (rtState = {}) => {
    const { page, pageSize, filtered, sorted } = rtState
    console.log('rtstate', rtState)
    const filteredStr = JSON.stringify(filtered)
    const sortedStr = JSON.stringify(sorted)

    let params = `page=${Number(page)}&pageSize=${pageSize}&filtered=${filteredStr}`

    fetchData(params)
  }
  function doShowModal(mode, data = null)
  {
    let form
    switch (mode) {
      case Mode.EDIT:
      case Mode.DELETE:
        form = data
        break
      case Mode.ADD:
        form = {}
        break
      default:
        break
    }

    setModeModalCustomer(mode)
    setFormModalCustomer(form)
  }

  const handleDelete =(data) => {
    let id = data._id
    swal.fire({
      title: `Apakah anda yakin ingin menghapus customer ini?`,
      text: "Data yang sudah terhapus tidak dapat dikembalikan",
      type: "warning", 
      showCancelButton: true,
      confirmButtonColor: "#d33", 
      confirmButtonText: "Ya", 
      cancelButtonColor: "#3085d6",
    }).then(async(result) => {
      if(result.value){
        await props.deleteCustomer(id)
        fetchData()
      }
    })
  }

  const { customer } = props
  const { isLoading, isError, message, data } = customer
  const { data: dataList, page, pageSize, totalPage } = data

  const columns = [
    {
      Header: "#",
      width: 60,
      filterable: false,
      Cell: (props) => <p className="text-center">{(page * pageSize) + props.index + 1}</p>,
    },
    {
      Header: "Nama",
      accessor: "name",
      filterable: true,
    },
    {
      Header: "No. Telp",
      accessor: "phone",
      filterable: true,
    },
    {
      Header: "Sumber",
      accessor: "sourceOfSales",
      filterable: true,
    },
    {
      Header: "Tipe",
      accessor: "priceType",
      filterable: true,
    },
    {
      Header: "Aksi",
      width: 100,
      Cell: (props) => (
        <div className="text-center"> 
          <button 
            type="button" 
            className="btn btn-sm btn-success" 
            onClick={() => doShowModal(Mode.EDIT, props.original)}
            >
            <i className="fa fa-pen" />
          </button> 
          &nbsp;
          <button 
            type="button" 
            className="btn btn-sm btn-danger" 
            onClick={() => handleDelete(props.original)}
            >
            <i className="fa fa-trash" />
          </button> 
        </div>
      )
    },
  ]

  return (
    <>
      <AuthenticatedNavbar />

      <div id="content" className="container-fluid pt-4 pb-4">
        <div className="title-lb">
          <label>Customer</label> <br />
          <span 
            onClick={() => props.history.goBack()}>
            <i className="fa fa-arrow-left" /> Kembali
          </span>
        </div>

        <Row>
          <Col md="6">
            <Card className="shadow-sm">
              <CardBody>

                <Button 
                  type="button" 
                  className="btn btn-sm btn-success float-right" 
                  onClick={() => doShowModal(Mode.ADD)}
                  >
                  <i className="fa fa-plus" /> Tambah
                </Button>
                <br />
                <br />
                <br />
                <ReactTable
                  manual
                  data={dataList}
                  columns={columns}
                  pages={totalPage}
                  page={page}
                  defaultPageSize={5}
                  loading={isLoading}
                  minRows={8}
                  onFetchData={onFetchData}
                />
              </CardBody>
            </Card>

            <CustomerModal
              toggleModal={setModeModalCustomer}
              data={formModalCustomer}
              mode={modeModalCustomer}
              refresh={() => fetchData()}
            />
          </Col>
          <Col md="6">
            <img
              alt="..."
              className=""
              src={img.customer}
            ></img>
          </Col>
        </Row>
      </div>
    </>
  )
}

CustomerPage.propTypes = {
  customer: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
    isError: PropTypes.bool.isRequired,
    message: PropTypes.array,
    data: PropTypes.object.isRequired,
  }),
  getCustomer: PropTypes.func.isRequired,
  updateCustomer: PropTypes.func.isRequired,
  deleteCustomer: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  customer: state.customer,
})

export default connect( mapStateToProps, {
	getCustomer,
	updateCustomer,
	deleteCustomer,
})(CustomerPage)
