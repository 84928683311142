import * as Type from 'store/actionTypes/profileType'

const initialStateWithoutData = {
  isLoading: false,
  isError: false,
  message: [],
  isLoadingCud: false,
  isErrorCud: false,
  messageCud: [],
}

const initialState = {
  ...initialStateWithoutData,
  data: {},
  dataDetail: {},
}

const request = state => ({
  ...state,
  ...initialStateWithoutData,
  isLoading: true,
})

const requestError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  message: payload,
})

const requestCud = state => ({
  ...state,
  ...initialStateWithoutData,
  isLoadingCud: true,
})

const requestErrorCud = (state, payload) => ({
  ...state,
  isLoadingCud: false,
  isErrorCud: true,
  messageCud: payload,
})


const getProfileSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  data: payload,
})

const updateProfileSuccess = (state, payload) => ({
  ...state,
  isLoadingCud: false,
  messageCud: payload,
})

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case Type.PROFILE_REQUEST: return request(state)
    case Type.PROFILE_REQUEST_CUD: return requestCud(state)

    case Type.GET_PROFILE_SUCCESS: return getProfileSuccess(state, action.payload)
    case Type.GET_PROFILE_ERROR: return requestError(state, action.payload)

    case Type.UPDATE_PROFILE_SUCCESS: return updateProfileSuccess(state, action.payload)
    case Type.UPDATE_PROFILE_ERROR: return requestErrorCud(state, action.payload)

    default:
      return state
  }
}

export default profileReducer
