import * as Type from "store/actionTypes/inout";

const initialStateWithoutData = {
  isLoading: false,
  isError: false,
  message: [],
};

const initialState = {
  ...initialStateWithoutData,
  data: {
    data: [],
    page: 0,
    pageSize: 10,
    totalPage: 0,
  },
  dataDetail: {},
};

const request = (state) => ({
  ...state,
  ...initialStateWithoutData,
  isLoading: true,
});

const requestError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  message: payload,
});

const getInoutSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  data: payload,
});

const getInoutDetailSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  dataDetail: payload,
});

const createInoutSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  message: payload,
});

const updateInoutSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  message: payload,
});

const deleteInoutSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  message: payload,
});

const revertProductInSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  message: payload,
});

const revertProductOutSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  message: payload,
});

const checklistProductOutSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  message: payload.message,
  data: {
    ...state.data.data,
    data: state.data.data.map((data) => {
      if (data._id === payload.data._id) {
        return {
          ...data,
          isChecked: payload.data.isChecked,
        };
      }
      return data;
    }),
    totalUnchecked: payload.data.isChecked
      ? state.data.totalUnchecked - 1
      : state.data.totalUnchecked + 1,
  },
});

const productInoutSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  message: payload,
});

const inoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case Type.INOUT_REQUEST:
      return request(state);
    case Type.INOUT_REQUEST_CUD:
      return request(state);

    case Type.GET_INOUT_SUCCESS:
      return getInoutSuccess(state, action.payload);
    case Type.GET_INOUT_ERROR:
      return requestError(state, action.payload);

    case Type.GET_INOUT_DETAIL_SUCCESS:
      return getInoutDetailSuccess(state, action.payload);
    case Type.GET_INOUT_DETAIL_ERROR:
      return requestError(state, action.payload);

    case Type.CREATE_INOUT_SUCCESS:
      return createInoutSuccess(state, action.payload);
    case Type.CREATE_INOUT_ERROR:
      return requestError(state, action.payload);

    case Type.UPDATE_INOUT_SUCCESS:
      return updateInoutSuccess(state, action.payload);
    case Type.UPDATE_INOUT_ERROR:
      return requestError(state, action.payload);

    case Type.DELETE_INOUT_SUCCESS:
      return deleteInoutSuccess(state, action.payload);
    case Type.DELETE_INOUT_ERROR:
      return requestError(state, action.payload);

    case Type.PRODUCT_INOUT_SUCCESS:
      return productInoutSuccess(state, action.payload);
    case Type.PRODUCT_INOUT_ERROR:
      return requestError(state, action.payload);

    case Type.REVERT_PRODUCT_IN_SUCCESS:
      return revertProductInSuccess(state, action.payload);
    case Type.REVERT_PRODUCT_IN_ERROR:
      return requestError(state, action.payload);

    case Type.REVERT_PRODUCT_OUT_SUCCESS:
      return revertProductOutSuccess(state, action.payload);
    case Type.REVERT_PRODUCT_OUT_ERROR:
      return requestError(state, action.payload);

    case Type.CHECKLIST_PRODUCT_OUT_SUCCESS:
      return checklistProductOutSuccess(state, action.payload);
    case Type.CHECKLIST_PRODUCT_OUT_ERROR:
      return requestError(state, action.payload);
    default:
      return state;
  }
};

export default inoutReducer;
