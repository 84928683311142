
import React, {useEffect, useState} from "react"
import { Link } from "react-router-dom"
import {NavLink, Row, Col, Card, CardBody, Table, Button, Modal, Form, InputGroup, Container, Alert, FormGroup, Label } from "reactstrap"
import PropTypes from "prop-types"
import swal from "sweetalert2"
import {connect} from "react-redux"
import ReactTable from "react-table"
import "react-table/react-table.css"
import AuthenticatedNavbar from "components/Navbars/AuthenticatedNavbar.js";
import ExportCSV from "components/Common/ExportCSV.js";
// import ProductInModal from "./ProductInModal"
import { 
  getInout,
  updateInout,
  revertProductIn,
} from "store/actions"
import { formatNumber } from 'utils'
import moment from 'moment'
import { img } from 'assets'
import { getUser, xhr } from 'utils'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

export const Mode = {
  HIDE: "0",
  ADD: "1",
  EDIT: "2",
  DELETE: "3"
}

const ProductInPage = (props) => {
  const [modeModalProductIn, setModeModalProductIn] = useState(Mode.HIDE)
  const [formModalProductIn, setFormModalProductIn] = useState({})
  const [dataCSV, setDataSCV] = useState([])
  const [form, setForm] = useState({
    dateFrom: new Date(),
    dateTo: new Date()
  })
  const [isLoadingCSV, setIsloadingCSV] = useState(false)

  const account = getUser()

  useEffect(() =>{
    fetchDataForCSV()
  }, [form])

  const fetchData = async (params) => {
    await props.getInout(params)
  }
  const onFetchData = async (rtState = {}) => {
    const { page, pageSize, filtered, sorted } = rtState

		let filter = filtered ? [...filtered] : []
		let sort = sorted ? [...sorted] : []

		filter.push({"id": "inoutType", "value": "Pengeluaran"})
		filter.push({"id": "inoutCategory", "value": "barcode"})

    const filteredStr = JSON.stringify(filter)
    const sortedStr = JSON.stringify(sort)

    let params = `page=${Number(page)}&pageSize=${pageSize}&filtered=${filteredStr}&sorterd=${sortedStr}`

    fetchData(params)
  }
  function doShowModal(mode, data = null)
  {
    let form
    switch (mode) {
      case Mode.EDIT:
      case Mode.DELETE:
        form = data
        break
      case Mode.ADD:
        form = {}
        break
      default:
        break
    }

    setModeModalProductIn(mode)
    setFormModalProductIn(form)
  }

  const handleRevert =(data) => {
    let id = data._id
    swal.fire({
      title: ``,
      text: "Salah input?",
      type: "warning", 
      showCancelButton: true,
      confirmButtonColor: "#d33", 
      confirmButtonText: "Pulihkan Stok", 
      cancelButtonColor: "#3085d6",
    }).then(async(result) => {
      if(result.value){
        await props.revertProductIn(id)
        onFetchData()
      }
    })
  }

  const handleChangeByName = (name, value) => {
    setForm({
    	...form,
      [name]: value
    })
  }

  const fetchDataForCSV = async() => {
    setIsloadingCSV(true)

    const date1 = moment(form.dateFrom).startOf('day').format('x')
    const date2 = moment(form.dateTo).endOf('day').format('x')
    const filtered = [
      {"id": "createdAt", "value": `${date1}.${date2}`, "type": "between-date"},
      {"id": "inoutType", "value": `Pengeluaran`, "type": "equal"}
    ]
    const params = `filtered=${JSON.stringify(filtered)}&pageSize=100000`
    const res = await xhr.get(`/inout?${params}`)
    const data = res.data.data


    let inoutdata = []
    for (let i = 0; i < data.length; i++) {
      if(!data[i].wrongInput) {
        inoutdata.push({
          TANGGAL: moment(data[i].createdAt).format('DD-MM-YYYY HH:mm'),
          // nama: data[i].customer?.name,
          'KETERANGAN BARANG MASUK': data[i].description,
          // tipe: data[i].priceType,
          // sumber: data[i].sourceOfSales,
          QTY: data[i].qty,
          // diskon: data[i].discount,
          // 'admin fee': data[i].platformFee,
        })
      }
    }
    setDataSCV(inoutdata)
    setIsloadingCSV(false)
  }

  const { inout } = props
  const { isLoading, isError, message, data } = inout
  const { data: dataList, page, pageSize, totalPage } = data

  let columns = [
    {
      Header: "#",
      width: 50,
      filterable: false,
      Cell: (props) => <p className="text-center">{(page * pageSize) + props.index + 1}</p>,
    },
    {
      Header: "Tanggal",
      width: 100,
      accessor: "createdAt",
      style: { 'whiteSpace': 'unset' }, 

      Cell: (props) => <small>{moment(props.value).format('DD MMM YYYY, HH:mm:ss')}</small>,
    },
    {
      Header: "Keterangan",
      accessor: "description",
      filterable: true,
      style: { 'whiteSpace': 'unset' }, 
      Cell: (props) => <div>
        <div style={{
          textDecoration: props.original.wrongInput
            ? 'line-through'
            : ''
        }}>
        {props.value} {props.original.qty} pcs 
        {props.original.discountPercent ? `, disc: ${props.original.discountPercent}%` : ''}
        {props.original.platformFeePercent ? `, adm: ${props.original.platformFeePercent}%` : ''}
       </div>
        {props.original.wrongInput && <small><i>salah input, stok dikembalikan</i></small>}
      </div>,
    },
    // {
    //   Header: "Jenis",
    //   accessor: "inoutType",
    //   width: 120,
    // },
  ]

	if (account.role === 'superadmin' || account.role === 'owner'){
    columns.push({
      Header: "Jumlah",
      accessor: "amount",
      width: 120,
      Cell: (props) => <p
        className="text-right"
        style={{
          textDecoration: props.original.wrongInput
            ? 'line-through'
            : ''
        }}>{formatNumber(props.value)}</p>,
    })
  }

  columns.push({
    Header: "Aksi",
    width: 50,
    Cell: (props) => (
      <div className="text-center"> 
        &nbsp;
        { moment(props.original.createdAt) > moment().subtract(300, 'day') &&
          !props.original.wrongInput &&
          <button 
            type="button" 
            className="btn btn-sm btn-danger" 
            onClick={() => handleRevert(props.original)}
            >
            x
          </button> 
        }
      </div>
    )
  })

  return (
    <>
      <AuthenticatedNavbar />

      <div id="content" className="container-fluid pt-4 pb-4">
        <div className="title-lb">
          <label>Barang Masuk</label> <br />
          <span 
            onClick={() => props.history.goBack()}>
            <i className="fa fa-arrow-left" /> Kembali
          </span>
        </div>
        <Row>
          <Col md="6">  
            <div className="px-2 bg-light" style={{borderRadius: '8px'}}>
              <span> Input barang masuk yuk</span>
              &nbsp;
              <Link 
                to="/a/product/entered/create"
                className="btn btn-success btn-sm" 
                >
                Oke, Siap
              </Link>
            </div>

            <br />

            <ReactTable
              manual
              data={dataList}
              columns={columns}
              pages={totalPage}
              page={page}
              defaultPageSize={8}
              loading={isLoading}
              onFetchData={onFetchData}
            />
            <Row>
              <FormGroup className="col-md-12">
              <br />
                <Card className="bg-light text-center"><CardBody>
                <Row>
                  <Col className="col-md-6">
                    <DatePicker
                      name="date"
                      id="date"
                        style={{width: '100%'}}
                        autoComplete="off"
                        selected={form.dateFrom}
                        onChange={(v) => handleChangeByName('dateFrom', v)}
                      />
                  </Col>
                  <Col className="col-md-6">
                    <DatePicker
                      name="dateTo"
                      id="dateTo"
                      style={{width: '100%'}}
                      autoComplete="off"
                      selected={form.dateTo}
                      onChange={(v) => handleChangeByName('dateTo', v)}
                    />
                  </Col>
                  </Row>
                <ExportCSV csvData={dataCSV} fileName={'csv'} loading={isLoadingCSV} />
                </CardBody></Card>
              </FormGroup>
            </Row>
          
          </Col>
          <Col md="6">
            <img
              alt="..."
              className=""
              src={img.note}
            ></img>
          </Col>
        </Row>
      </div>
    </>
  )
}

ProductInPage.propTypes = {
  inout: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
    isError: PropTypes.bool.isRequired,
    message: PropTypes.array,
    data: PropTypes.object.isRequired,
  }),
  getInout: PropTypes.func.isRequired,
  updateInout: PropTypes.func.isRequired,
  revertProductIn: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  inout: state.inout,
})

export default connect( mapStateToProps, {
	getInout,
	updateInout,
	revertProductIn,
})(ProductInPage)
