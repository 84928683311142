import React, { useEffect, useState } from "react";
import "react-table/react-table.css";
import AuthenticatedNavbar from "components/Navbars/AuthenticatedNavbar.js";
import { xhr, formatNumber } from "utils";
import _ from "lodash";
import dataFake from "./data.json";

const ShopeeItemPage = (props) => {
  const [data, setData] = useState([]);
  const fetchData = async (params) => {
    setData(dataFake);
    const data = await xhr.get(`/shopee/item`);
    setData(data.data.item);
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <AuthenticatedNavbar />
      <table style={{ border: "1px solid", width: "100%" }}>
        <tr style={{ border: "1px solid" }}>
          <th style={{ width: "5%" }}>no</th>
          <th style={{ width: "10%" }}>item_id</th>
          <th style={{ width: "15%" }}>item_name</th>
          <th style={{ width: "10%" }}>item_sku</th>
          <th style={{ width: "60%" }}>models</th>
        </tr>
        {data.map((item, idx) => (
          <tr style={{ border: "1px solid" }}>
            <td>{idx + 1}</td>
            <td>{item.item_id}</td>
            <td>{item.item_detail?.item_name}</td>
            <td>{item.item_detail?.item_sku}</td>
            <table style={{ border: "1px solid", width: "100%" }}>
              <tr style={{ border: "1px solid" }}>
                <th style={{ width: "10%" }}>model_id</th>
                <th style={{ width: "20%" }}>model_sku</th>
                {/* <th style={{ width: "15%" }}>options</th> */}
                <th style={{ width: "15%" }}>original price</th>
                <th style={{ width: "15%" }}>current price</th>
              </tr>
              {_.sortBy(item.models.model, ["model_sku"]).map((model, idxM) => (
                <tr style={{ border: "1px solid" }}>
                  <td>{model.model_id}</td>
                  <td>{model.model_sku}</td>
                  {/* <td>{_.find(item.models.tier_variation, ['name', 'Ukuran'])?.option_list[idxM]?.option}</td> */}
                  <td>{formatNumber(model.price_info[0].original_price)}</td>
                  <td>{formatNumber(model.price_info[0].current_price)}</td>
                </tr>
              ))}
            </table>
          </tr>
        ))}
      </table>
    </>
  );
};

export default ShopeeItemPage;
