import Swal from "sweetalert2";
import { xhr } from "utils";

export const syncStockShopee = async () => {
  Swal.fire({
    title: "Masukan Persentase Stok",
    input: "number",
    inputPlaceholder: "Contoh: 100",
  }).then(async function (result) {
    try {
      if (result.value === null || result.value === undefined) {
        return Swal.fire("error", "You need to write something!", "error");
      }

      const res = await xhr.post(`/shopee/item/update-stock`, {
        percentage: result.value,
      });
      return Swal.fire("info", res.message, "info");
    } catch (e) {
      return Swal.fire("error", e.message, "error");
    }
  });
};
