import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import { Button, Container, Row, Col, CardBody, Card } from "reactstrap";
import { getUser } from "utils";

// core components
import AuthenticatedNavbar from "components/Navbars/AuthenticatedNavbar.js";
import { img } from "assets";
import { syncStockShopee } from "utils";

function DashboardPage() {
  const account = getUser();

  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <AuthenticatedNavbar />
      <div className="wrapper">
        <br />
        <br />
        <br />
        <Container>
          <Row>
            <Col md="6">
              <img alt="..." className="" src={img.onlineShop}></img>
            </Col>
            <Col md="6">
              <br />
              <br />
              <br />
              <br />
              <Row>
                <Col className="ml-auto mr-auto text-center" md="6">
                  <Link to="/a/product/entered">
                    <Card>
                      <CardBody>
                        <b>Masuk Barang</b>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>
                <Col className="ml-auto mr-auto text-center" md="6">
                  <Link to="/a/product/out">
                    <Card>
                      <CardBody>
                        <b>Keluar Barang</b>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>
                {(account.role === "superadmin" ||
                  account.role === "owner") && (
                  <Col className="ml-auto mr-auto text-center" md="6">
                    <Link to="/a/recap">
                      <Card>
                        <CardBody>
                          <b>Omset</b>
                        </CardBody>
                      </Card>
                    </Link>
                  </Col>
                )}
              </Row>
              <Row>
                <Col className="ml-auto mr-auto text-center" md="6">
                  <Link to="/a/inout">
                    <Card>
                      <CardBody>
                        <b>Catatan Manual</b>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>
                <Col className="ml-auto mr-auto text-center" md="6">
                  <Link to="/a/product">
                    <Card>
                      <CardBody>
                        <b>Produk</b>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>
                <Col className="ml-auto mr-auto text-center" md="6">
                  <Link to="/a/customer">
                    <Card>
                      <CardBody>
                        <b>Customer</b>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>

                {(account.role === "superadmin" ||
                  account.role === "owner") && (
                  <Col className="ml-auto mr-auto text-center" md="6">
                    <Link to="/a/setting">
                      <Card>
                        <CardBody>
                          <b>Pengaturan</b>
                        </CardBody>
                      </Card>
                    </Link>
                  </Col>
                )}
                {(account.role === "superadmin" ||
                  account.role === "owner") && (
                  <Col className="ml-auto mr-auto text-center" md="6">
                    <Link to="/a/shopee/item">
                      <Card>
                        <CardBody>
                          <b>Shopee Item</b>
                        </CardBody>
                      </Card>
                    </Link>
                  </Col>
                )}
                {(account.role === "superadmin" ||
                  account.role === "owner") && (
                  <Col className="ml-auto mr-auto text-center" md="6">
                    <Link to="/a/shopee/order">
                      <Card>
                        <CardBody>
                          <b>Shopee Order</b>
                        </CardBody>
                      </Card>
                    </Link>
                  </Col>
                )}

                <Col className="ml-auto mr-auto text-center" md="6">
                  <Card>
                    <CardBody>
                      <Button color="link" onClick={() => syncStockShopee()}>
                        <b>Update Stock Shopee</b>
                      </Button>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default DashboardPage;
