import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  Nav,
  Container,
  UncontrolledTooltip,
} from "reactstrap";
import { verifyToken } from "utils";
import { connect } from "react-redux";

import { logout } from "store/actions";

function AuthenticatedNavbar(props) {
  const [collapseOpen, setCollapseOpen] = React.useState(false);

  const [notif, setNotif] = React.useState([]);

  const user = verifyToken();
  const role = user.role;

  const handleLogout = () => {
    props.logout();
  };

  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className={"sticky-top"} expand="md" color="info">
        <Container>
          <div className="navbar-translate">
            <NavbarBrand href="/" target="_blank" id="navbar-brand">
              <b>Dzakira</b>
            </NavbarBrand>
            <UncontrolledTooltip target="#navbar-brand">
              family outfit
            </UncontrolledTooltip>
            <button
              className="navbar-toggler navbar-toggler"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              type="button"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse
            className="justify-content-end"
            isOpen={collapseOpen}
            navbar
          >
            <Nav navbar>
              <button
                className="btn btn-outline-light"
                type="button"
                onClick={() => handleLogout()}
              >
                Keluar
              </button>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logout })(AuthenticatedNavbar);
