export const PRODUCT_MODEL_REQUEST = 'PRODUCT_MODEL_REQUEST'
export const PRODUCT_MODEL_REQUEST_CUD = 'PRODUCT_MODEL_REQUEST_CUD'
// get
export const GET_PRODUCT_MODEL_SUCCESS = 'GET_PRODUCT_MODEL_SUCCESS'
export const GET_PRODUCT_MODEL_ERROR = 'GET_PRODUCT_MODEL_ERROR'
// get detail
export const GET_PRODUCT_MODEL_DETAIL_SUCCESS = 'GET_PRODUCT_MODEL_DETAIL_SUCCESS'
export const GET_PRODUCT_MODEL_DETAIL_ERROR = 'GET_PRODUCT_MODEL_DETAIL_ERROR'
// create
export const CREATE_PRODUCT_MODEL_SUCCESS = 'CREATE_PRODUCT_MODEL_SUCCESS'
export const CREATE_PRODUCT_MODEL_ERROR = 'CREATE_PRODUCT_MODEL_ERROR'
// update
export const UPDATE_PRODUCT_MODEL_SUCCESS = 'UPDATE_PRODUCT_MODEL_SUCCESS'
export const UPDATE_PRODUCT_MODEL_ERROR = 'UPDATE_PRODUCT_MODEL_ERROR'
// delete
export const DELETE_PRODUCT_MODEL_SUCCESS = 'DELETE_PRODUCT_MODEL_SUCCESS'
export const DELETE_PRODUCT_MODEL_ERROR = 'DELETE_PRODUCT_MODEL_ERROR'