export const formatNumber = (
	value = 0,
) => {
	let result
	const num = Math.round(value)

	if (Number.isInteger(num)) {
		const isNegative = num < 0 ? true : false
		const reverse = num.toString().split('').reverse().join('')
		result = reverse.match(/\d{1,3}/g)
		result = result.join('.').split('').reverse().join('')
		result = isNegative ? `-${result}` : result
	}

	return result
}
