/*

=========================================================
* Now UI Kit React - v1.4.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-kit-react
* Copyright 2020 Creative Tim (http://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/now-ui-kit-react/blob/master/LICENSE.md)

* Designed by www.invisionapp.com Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component, Suspense } from "react";
import { createStore, applyMiddleware } from 'redux'
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { getUser } from 'utils'

// styles for this kit
import "index.css";
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss?v=1.4.0";
import "assets/demo/demo.css?v=1.4.0";
import "assets/demo/nucleo-icons-page-styles.css?v=1.4.0";
// pages for this kit
import ScrollToTop from 'components/ScrollToTop';
import Index from "views/Index";
import NucleoIcons from "views/NucleoIcons";
import LoginPage from "views/examples/LoginPage";
import LandingPage from "views/examples/LandingPage";
import ProfilePage from "views/examples/ProfilePage";
import DashboardPage from "views/dashboard/DashboardPage";
import ProductTypePage from "views/productType/ProductTypePage";
import ProductModelPage from "views/productModel/ProductModelPage";
import ProductPage from "views/product/ProductPage";
import BarcodePage from "views/product/BarcodePage";
import CustomerPage from "views/customer/CustomerPage";
import SettingPage from "views/setting/SettingPage";
import ShopeeItemPage from "views/shopeeItem/ShopeeItemPage";
import ShopeeOrderPage from "views/shopeeOrder/ShopeeOrderPage";
import InoutPage from "views/inout/InoutPage";
import ProductInPage from "views/inout/ProductInPage";
import ProductOutPage from "views/inout/ProductOutPage";
import ProductInCreatePage from "views/inout/ProductInCreatePage";
import ProductOutCreatePage from "views/inout/ProductOutCreatePage";
import RecapPage from "views/recap/RecapPage";

class App extends Component {
  render() {
		const account = getUser()

    return (
			<BrowserRouter>
				<Suspense fallback={'Loading...'}>
					<ScrollToTop />
					<Switch>
						<Route path="/index" render={(props) => <Index {...props} />} />
						<Route
							exact
							path="/nucleo-icons"
							render={(props) => <NucleoIcons {...props} />}
						/>
						<Route
							exact
							path="/landing-page"
							render={(props) => <LandingPage {...props} />}
						/>
						<Route
							exact
							path="/profile-page"
							render={(props) => <ProfilePage {...props} />}
						/>
						<Route
							exact
							path="/login"
							render={(props) => <LoginPage {...props} />}
						/>
						<Route
							exact
							path="/a/dashboard"
							render={(props) => <DashboardPage {...props} />}
						/>
						<Route
							exact
							path="/a/product-type"
							render={(props) => <ProductTypePage {...props} />}
						/>
						<Route
							exact
							path="/a/product-model"
							render={(props) => <ProductModelPage {...props} />}
						/>
						<Route
							exact
							path="/a/product"
							render={(props) => <ProductPage {...props} />}
						/>
						<Route
							exact
							path="/a/recap"
							render={(props) => <RecapPage {...props} />}
						/>
						<Route
							exact
							path="/a/customer"
							render={(props) => <CustomerPage {...props} />}
						/>
						<Route
							exact
							path="/a/setting"
							render={(props) => <SettingPage {...props} />}
						/>
						<Route
							exact
							path="/a/shopee/item"
							render={(props) => <ShopeeItemPage {...props} />}
						/>
						<Route
							exact
							path="/a/shopee/order"
							render={(props) => <ShopeeOrderPage {...props} />}
						/>
						<Route
							exact
							path="/a/inout"
							render={(props) => <InoutPage {...props} />}
						/>
						<Route
							exact
							path="/a/product/entered"
							render={(props) => <ProductInPage {...props} />}
						/>
						<Route
							exact
							path="/a/product/entered/create"
							render={(props) => <ProductInCreatePage {...props} />}
						/>
						<Route
							exact
							path="/a/product/out"
							render={(props) => <ProductOutPage {...props} />}
						/>
						<Route
							exact
							path="/a/product/out/create"
							render={(props) => <ProductOutCreatePage {...props} />}
						/>
						<Route
							exact
							path="/a/barcode/:id"
							render={(props) => <BarcodePage {...props} />}
						/>
						<Redirect to="/index" />
						<Redirect from="/" to="/index" />
					</Switch>
				</Suspense>
			</BrowserRouter>
		)
	}
};

export default App;
