import * as Type from 'store/actionTypes/customer'

const initialStateWithoutData = {
  isLoading: false,
  isError: false,
  message: [],
}

const initialState = {
  ...initialStateWithoutData,
  data: {
    data: [], 
    page: 0, 
    pageSize: 10, 
    totalPage: 0
  },
  dataDetail: {},
}

const request = state => ({
  ...state,
  ...initialStateWithoutData,
  isLoading: true,
})

const requestError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  message: payload,
})


const getCustomerSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  data: payload,
})

const getCustomerDetailSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  dataDetail: payload,
})

const createCustomerSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  message: payload,
})

const updateCustomerSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  message: payload,
})

const deleteCustomerSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  message: payload,
})


const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    case Type.CUSTOMER_REQUEST: return request(state)
    case Type.CUSTOMER_REQUEST_CUD: return request(state)

    case Type.GET_CUSTOMER_SUCCESS: return getCustomerSuccess(state, action.payload)
    case Type.GET_CUSTOMER_ERROR: return requestError(state, action.payload)

    case Type.GET_CUSTOMER_DETAIL_SUCCESS: return getCustomerDetailSuccess(state, action.payload)
    case Type.GET_CUSTOMER_DETAIL_ERROR: return requestError(state, action.payload)

    case Type.CREATE_CUSTOMER_SUCCESS: return createCustomerSuccess(state, action.payload)
    case Type.CREATE_CUSTOMER_ERROR: return requestError(state, action.payload)

    case Type.UPDATE_CUSTOMER_SUCCESS: return updateCustomerSuccess(state, action.payload)
    case Type.UPDATE_CUSTOMER_ERROR: return requestError(state, action.payload)

    case Type.DELETE_CUSTOMER_SUCCESS: return deleteCustomerSuccess(state, action.payload)
    case Type.DELETE_CUSTOMER_ERROR: return requestError(state, action.payload)
    default:
      return state
  }
}

export default customerReducer
