import Swal from 'sweetalert2'
import * as Type from 'store/actionTypes/productColor'
import { xhr } from 'utils'

export const getProductColor = (params = '') => async (dispatch) => {
  try {
    dispatch({
      type: Type.PRODUCT_COLOR_REQUEST,
    })
    const res = await xhr.get(`/product-color?${params}`)
    dispatch({
      type: Type.GET_PRODUCT_COLOR_SUCCESS,
      payload: res.data,
    })
  } catch (err) {
    Swal.fire('error', err.message, 'error')
    dispatch({
      type: Type.GET_PRODUCT_COLOR_ERROR,
      payload: err.message,
    })
  }
}

export const createProductColor = (data) => async (dispatch) => {
  try {
    dispatch({
      type: Type.PRODUCT_COLOR_REQUEST,
    })
    const res = await xhr.post('/product-color', data)
    Swal.fire('success', res.message, 'success')
    dispatch({
      type: Type.CREATE_PRODUCT_COLOR_SUCCESS,
      payload: res.message,
    })
  } catch (err) {
    Swal.fire('error', err.message, 'error')
    dispatch({
      type: Type.CREATE_PRODUCT_COLOR_ERROR,
      payload: err.message,
    })
  }
}

export const updateProductColor = (data, id) => async (dispatch) => {
  try {
    dispatch({
      type: Type.PRODUCT_COLOR_REQUEST,
    })
    console.log(data)
    const res = await xhr.put(`/product-color/${id}`, data)
    Swal.fire('success', res.message, 'success')
    dispatch({
      type: Type.UPDATE_PRODUCT_COLOR_SUCCESS,
      payload: res.message,
    })
  } catch (err) {
    Swal.fire('error', err.message, 'error')
    dispatch({
      type: Type.UPDATE_PRODUCT_COLOR_ERROR,
      payload: err.message,
    })
    
  }
}

export const deleteProductColor = (id) => async (dispatch) => {
  try {
    dispatch({
      type: Type.PRODUCT_COLOR_REQUEST,
    })
    const res = await xhr.delete(`/product-color/${id}`)
    Swal.fire('success', res.message, 'success')
    dispatch({
      type: Type.DELETE_PRODUCT_COLOR_SUCCESS,
      payload: res.message,
    })
  } catch (err) {
    Swal.fire('error', err.message, 'error')
    dispatch({
      type: Type.DELETE_PRODUCT_COLOR_ERROR,
      payload: err.message,
    })
  }
}
