


import React, {useEffect, useState} from "react"
import {
	NavLink,
	Row,
	Col,
	Input,
	CardHeader as CardHeaderComponent,
	CardBody,
	Table,
	Button,
	Modal,
	Form,
	FormGroup,
	Label,
	Container,
} from "reactstrap"
import PropTypes from "prop-types"
import _ from "lodash"
import swal from "sweetalert2"
import {connect} from "react-redux"
import ReactTable from "react-table"
import "react-table/react-table.css"
import Chart,{Doughnut,Bar, Pie, Polar, Line} from 'react-chartjs-2';

import AuthenticatedNavbar from "components/Navbars/AuthenticatedNavbar.js";
import { 
  generateCredShopee,
} from "store/actions"

const Loading = () =>  'Loading ...'

const SettingPage = (props) => {
  const { setting } = props
  const { isLoading, isError, message, data } = setting

  const [form, setForm] = useState({})

  const handleChange = ({target}) => {
    setForm({
    	...form,
      [target.name]: target.value,
    })
  }

	const submitShopee = async () => {
		const { partner_id, partner_key, shop_id, code } = form

		props.generateCredShopee({ partner_id, partner_key, shop_id, code })
	}

  return (
    <>
      <AuthenticatedNavbar />

      <div id="content" className="container-fluid pt-4 pb-4">
				<Container>

					<div className="title-lb">
						<span 
							onClick={() => props.history.goBack()}>
							<i className="fa fa-arrow-left" /> Kembali
						</span>
					</div>

					<Form onSubmit={e => e.preventDefault()}>
						<Row>
							<Col md={{size: 4, offset: 4}} className="text-center">
								<h4><b>Pengaturan</b></h4>
							</Col>
						</Row>

						<Row>
							<Col md={{ size: 6, offset: 3 }} className="text-center">
								<Row>
									<FormGroup className="col-md-12">
										<Label htmlFor="code">Generate Credential Shopee</Label>
										<Input
											name="partner_id"
											id="partner_id"
											className=""
											placeholder="Masukan partner id"
											value={form.partner_id}
											onChange={handleChange}
											autoComplete="off"
											required 
										/>
									</FormGroup>
									<FormGroup className="col-md-12">
										<Input
											name="partner_key"
											id="partner_key"
											className=""
											placeholder="Masukan partner key"
											value={form.partner_key}
											onChange={handleChange}
											autoComplete="off"
											required 
										/>
									</FormGroup>
									<FormGroup className="col-md-12">
										<Input
											name="shop_id"
											id="shop_id"
											className=""
											placeholder="Masukan shop id"
											value={form.shop_id}
											onChange={handleChange}
											autoComplete="off"
											required 
										/>
									</FormGroup>
									<FormGroup className="col-md-12">
										<Input
											name="code"
											id="code"
											className=""
											placeholder="Masukan code"
											value={form.code}
											onChange={handleChange}
											autoComplete="off"
											required 
										/>
									</FormGroup>
								</Row>
								<Button
									color="primary"
									size="lg"
									onClick={() => { submitShopee() }}
									disabled={isLoading}
								>
									{ !isLoading ? 'Submit' : <Loading type='basicSpinnerBorder' /> }
								</Button>
							</Col>
						</Row>
					</Form>
				</Container>
      </div>
    </>
  )
}

SettingPage.propTypes = {
  generateCredShopee: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
	setting: state.setting
})

export default connect( mapStateToProps, {
	generateCredShopee,
})(SettingPage)
