
import React, {useEffect, useState} from "react"
import { Link } from "react-router-dom"
import {NavLink, Row, Col, Card, CardBody, Table, Button, Modal, Form, FormGroup, Label, Input, FormControl, InputGroup, InputGroupAddon } from "reactstrap"
import PropTypes from "prop-types"
import swal from "sweetalert2"
import {connect} from "react-redux"
import ReactTable from "react-table"
import "react-table/react-table.css"
import Scanner from "modules/Scanner"
import _ from "lodash"
import AuthenticatedNavbar from "components/Navbars/AuthenticatedNavbar.js"
// import ProductInModal from "./ProductInModal"
import Select from 'react-select'
import { 
  getProduct,
  getInout,
  productInout,
  deleteInout
} from "store/actions"
import { formatNumber } from "utils"
import moment from "moment"
import Quagga from "quagga"
import {Howl} from "howler"
import { audio } from "assets"
import { getUser } from 'utils'

export const Mode = {
  HIDE: "0",
  ADD: "1",
  EDIT: "2",
  DELETE: "3"
}
 

const sound = new Howl({
  src: [audio.beepCashier]
})

const ProductInPage = (props) => {
  const [form, setForm] = useState([])
  const [barcode, setBarcode] = useState('')
  const account = getUser()

  const dataProduct = props.product.data.data
  let productList = []
  dataProduct.forEach(rowProduct => {
    rowProduct.variation.forEach(rowVariation => {
      rowVariation.colors.forEach(rowColor => {
        productList.push({
          label: `${rowProduct.name} `
                + `${rowColor.color} ` 
                + `${rowVariation.size} ` 
                + `(${rowColor.stock})`, 
          value: rowColor.barcode,
        })
      })
    })
  })

	//componentDidMount
  useEffect(() => {
    fetchData()
  }, [])
	
//componentDidMount
  useEffect(() => {
    _onDetected(barcode)
  }, [barcode])

  const fetchData = async (params) => {
    await props.getProduct()
  }

	const onSubmit = async (e) => {
		props.productInout({products: form })
		if(!props.inout.isError){
			_.delay(()=> {
				window.location.reload()
			},2000)
		}
	}

  const _onDetected = result => {
    const barcode = typeof(result) == 'string' 
      ? result
      : result.codeResult.code 

    let newForm = [...form]

    let pd

    dataProduct.forEach((rowProduct, idxProduct) => {
      rowProduct.variation.forEach((rowSize, idxSize) => {
        rowSize.colors.forEach((rowColor, idxColor) => {
          if (rowColor.barcode === barcode) {
            pd = {
              ...dataProduct[idxProduct],
              variation: {
                ...dataProduct[idxProduct].variation[idxSize],
                colors: dataProduct[idxProduct].variation[idxSize].colors[idxColor],
              }
            }
          }
        })
      })  
    })  

    // no product match
    if (!pd) {
      return
    }

    sound.play()

    // product match, then continue
		const variation = pd.variation
		const size = variation.size
		const colors = variation.colors

    const existProductIdx = newForm.findIndex(row => {
      return row.product === pd._id
        && row.variation === variation._id
        && row.colors === colors._id
    })  

    if (existProductIdx !== -1) {
      newForm[existProductIdx].qty += 1
      newForm[existProductIdx].amount = Number(newForm[existProductIdx].qty) * Number(newForm[existProductIdx].pricePerItem)
    }
    else {
      const  newItem = {
        productObj: pd,
        product: pd._id,
        variation: variation._id,
        colors: colors._id,
        colorName: colors.color,
        description: `${pd.name}-${size}-${colors.color}`,
        inoutType: 'Pengeluaran',
        inoutCategory: 'barcode',
        priceType: 'priceCapital',
        pricePerItem: variation['priceCapital'],
        qty: 1,
        amount: variation['priceCapital'],
      }

      newForm = newForm.concat([newItem])
    }

    setForm(newForm)
    setBarcode('')
  }

  const handleChangeBarcode = async ({target}) => {
    setBarcode(target.value)
  }

  const handleChangeForm2 = async (name, value) => {
		if(name === 'barcode'){
			setBarcode(value)	
		}
  }

  const handleChangeQty = (e, typeOrValue, idx) => {
    // e.preventDefault()
    sound.play()
    let newForm = [...form]
    if (typeOrValue === '+') {
      newForm[idx].qty += 1
    }
    else if (typeOrValue === '-') {
      if (newForm[idx].qty !== 1) {
        newForm[idx].qty -= 1
      }
    }
    else {
      newForm[idx].qty = Number(typeOrValue)
    }

    newForm[idx].amount = Number(newForm[idx].qty) * Number(newForm[idx].pricePerItem)
    setForm(newForm)
  }

  return (
    <>
      <AuthenticatedNavbar />

      <div id="content" className="container-fluid pt-4 pb-4">
        <div className="title-lb">
          <label>Scan Barang Masuk</label> <br />
          <span 
            onClick={() => props.history.goBack()}>
            <i className="fa fa-arrow-left" /> Kembali
          </span>
        </div>

        <Row>
          <Col md="6" className="container-barcode-scanner">
              <Scanner onDetected={_onDetected} />
          </Col>
          <Col md="6">
            <Row>
              <Col md="6">
                <FormGroup>
                  <Input
                    type="text"
                    min={1}
                    name="barcode"
                    id="barcode"
                    className=""
                    placeholder="input barcode manual"
                    value={barcode}
                    onChange={handleChangeBarcode}
                    autoComplete="off"
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Select
                    placeholder='Masukan produk'
                    onChange={(v) => handleChangeForm2('barcode', v.value)}
                    options={productList}
                    styles={{
                        // Fixes the overlapping problem of the component
                        menu: provided => ({ ...provided, zIndex: 9999 })
                      }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <hr />
            {
              form.map((row, index) => {
                const { name } = row.productObj
                const size = row.productObj.variation.size
                const { color, stock } = row.productObj.variation.colors
                const {
                  priceCapital,
                } = row.productObj.variation

                return <Card>
                  <CardBody>
                    <Row>
                      <Col md="1">
                        {index+1}.
                      </Col>
                      <Col md="7">
                        <b>{name}</b> <br />
                        {color} ({size}) <br /> <br />
                        <span>Stok: {stock}</span> <br />
                        { (account.role === 'superadmin' || account.role === 'owner') && 
                          <><span>Harga modal: Rp. {formatNumber(priceCapital)} x {row.qty}</span> <br /></>
                        }
                      </Col>
                      <Col md="4">
                        <InputGroup size="lg">
                          <InputGroupAddon addonType="prepend">
                            <Button className="btn" style={{margin:0}} onClick={e => handleChangeQty(e, '-', index)}><b>-</b></Button>
                          </InputGroupAddon>
                          <Input value={row.qty} onChange={e => handleChangeQty(e, e.target.value, index)} />
                          <InputGroupAddon addonType="append">
                            <Button className="btn" style={{margin:0}} onClick={e => handleChangeQty(e, '+', index)}><b>+</b></Button>
                          </InputGroupAddon>
                        </InputGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              })
            }

            { form.length > 0
              ? <Button 
                  type="button" 
                  className="btn btn-lg btn-success float-right" 
                  onClick={() => onSubmit()}
                  >
                  <i className="fa fa-plus" /> Simpan
                </Button>
              : <p className="text-center">Silahkan scan barcode dengan kamera atau input produk pada text box diatas</p>
            }       
          </Col>
        </Row>
      </div>
    </>
  )
}

ProductInPage.propTypes = {
  inout: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
    isError: PropTypes.bool.isRequired,
    message: PropTypes.array,
    data: PropTypes.object.isRequired,
  }),
  getInout: PropTypes.func.isRequired,
  productInout: PropTypes.func.isRequired,
  deleteInout: PropTypes.func.isRequired,
  getProduct: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  inout: state.inout,
  product: state.product,
})

export default connect( mapStateToProps, {
	getInout,
	productInout,
	deleteInout,
  getProduct
})(ProductInPage)
