export const PRODUCT_REQUEST = 'PRODUCT_REQUEST'
export const PRODUCT_REQUEST_CUD = 'PRODUCT_REQUEST_CUD'
// get
export const GET_PRODUCT_SUCCESS = 'GET_PRODUCT_SUCCESS'
export const GET_PRODUCT_ERROR = 'GET_PRODUCT_ERROR'
// get detail
export const GET_PRODUCT_DETAIL_SUCCESS = 'GET_PRODUCT_DETAIL_SUCCESS'
export const GET_PRODUCT_DETAIL_ERROR = 'GET_PRODUCT_DETAIL_ERROR'
// create
export const CREATE_PRODUCT_SUCCESS = 'CREATE_PRODUCT_SUCCESS'
export const CREATE_PRODUCT_ERROR = 'CREATE_PRODUCT_ERROR'
// update
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS'
export const UPDATE_PRODUCT_ERROR = 'UPDATE_PRODUCT_ERROR'
// delete
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS'
export const DELETE_PRODUCT_ERROR = 'DELETE_PRODUCT_ERROR'