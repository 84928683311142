import * as Type from 'store/actionTypes/productType'

const initialStateWithoutData = {
  isLoading: false,
  isError: false,
  message: [],
}

const initialState = {
  ...initialStateWithoutData,
  data: {
    data: [], 
    page: 0, 
    pageSize: 10, 
    totalPage: 0
  },
  dataDetail: {},
}

const request = state => ({
  ...state,
  ...initialStateWithoutData,
  isLoading: true,
})

const requestError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  message: payload,
})


const getProductTypeSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  data: payload,
})

const getProductTypeDetailSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  dataDetail: payload,
})

const createProductTypeSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  message: payload,
})

const updateProductTypeSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  message: payload,
})

const deleteProductTypeSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  message: payload,
})


const productTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case Type.PRODUCT_TYPE_REQUEST: return request(state)
    case Type.PRODUCT_TYPE_REQUEST_CUD: return request(state)

    case Type.GET_PRODUCT_TYPE_SUCCESS: return getProductTypeSuccess(state, action.payload)
    case Type.GET_PRODUCT_TYPE_ERROR: return requestError(state, action.payload)

    case Type.GET_PRODUCT_TYPE_DETAIL_SUCCESS: return getProductTypeDetailSuccess(state, action.payload)
    case Type.GET_PRODUCT_TYPE_DETAIL_ERROR: return requestError(state, action.payload)

    case Type.CREATE_PRODUCT_TYPE_SUCCESS: return createProductTypeSuccess(state, action.payload)
    case Type.CREATE_PRODUCT_TYPE_ERROR: return requestError(state, action.payload)

    case Type.UPDATE_PRODUCT_TYPE_SUCCESS: return updateProductTypeSuccess(state, action.payload)
    case Type.UPDATE_PRODUCT_TYPE_ERROR: return requestError(state, action.payload)

    case Type.DELETE_PRODUCT_TYPE_SUCCESS: return deleteProductTypeSuccess(state, action.payload)
    case Type.DELETE_PRODUCT_TYPE_ERROR: return requestError(state, action.payload)
    default:
      return state
  }
}

export default productTypeReducer
