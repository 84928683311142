import * as Type from 'store/actionTypes/authType'
import { xhr } from 'utils'
import Swal from 'sweetalert2'

export const login = (data) => async (dispatch) => {
  try {
    dispatch({
      type: Type.AUTH_REQUEST,
    })

    const res = await xhr.post('/login', data)

		let {accessToken,user} = res
		localStorage.setItem('accessToken',accessToken)
    Swal.fire('success', `Selamat datang ${user.username}`, 'success')
		window.location = '/a/dasboard'

  } catch (err) {
    Swal.fire('error', err.message, 'error')
  }
}
export const logout = (data) => async (dispatch) => {
  try {
		localStorage.setItem('accessToken', '')
		window.location = '/'

  } catch (err) {
    Swal.fire('error', err.message, 'error')
  }
}

