export const queryStringToJSON = (query) => {
  const pairs = query.slice(1).split('&')

  const result = {}
  pairs.forEach((pair) => {
    const itemPair = pair.split('=')
    result[itemPair[0]] = decodeURIComponent(itemPair[1] || '')
  })

  return JSON.parse(JSON.stringify(result))
}