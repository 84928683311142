import React, {useState} from "react"
import {
  Row,
  Col,
  Container,
  FormGroup,
  Input,
} from 'reactstrap'
import {connect} from "react-redux"
import _ from "lodash"
import { formatNumber } from 'utils'

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js"
import IndexHeader from "components/Headers/IndexHeader.js"
import DarkFooter from "components/Footers/DarkFooter.js"

// sections for this page
import {
  apiURL
} from "constant"
import ProductHomeModal from "./product/ProductHomeModal"
import { 
  getProduct,
} from "store/actions"
import { getUser } from 'utils'


export const Mode = {
  HIDE: "0",
  SHOW: "1",
}
function Index(props) {

  const [modeModalProduct, setModeModalProduct] = useState(Mode.HIDE)
  const [formModalProduct, setFormModalProduct] = useState({})
  const [form, setForm] = useState({})
  const account = getUser()

  const { product } = props
  const { isLoading, data } = product
  let { data: dataList } = data

  React.useEffect(() => {
    props.getProduct()
  }, [])

  React.useEffect(() => {
    document.body.classList.add("index-page")
    document.body.classList.add("sidebar-collapse")
    document.documentElement.classList.remove("nav-open")
    // window.scrollTo(0, 0)
    // document.body.scrollTop = 0
    return function cleanup() {
      document.body.classList.remove("index-page")
      document.body.classList.remove("sidebar-collapse")
    }
  })

  const handleChange = ({target}) => {
    setForm({
    	...form,
      [target.name]: target.value,
    })
  }

  function doShowModal(mode, data = null)
  {
    let form
    switch (mode) {
      case Mode.SHOW:
        form = data
        break
      default:
        break
    }
    setModeModalProduct(mode)
    setFormModalProduct(form)
  }

  dataList = _.filter(dataList, (row) => {
    if(form.search) {
      const val1 = _.lowerCase(row.name)
      const val2 = _.lowerCase(form.search)
      return val1.includes(val2)
    }
    else {
      return row
    }
  })

  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <IndexHeader />
        <div className="main">
          <Container>

            <br />
            <br />
            <br />
            <br />

            <center>
              <h2 style={{margin: '0'}}>Assalamu'alaikum Umaa 😍</h2> <br />
              <h3 style={{margin: 0, fontWeight: 'bold'}}>Bismillah 💫</h3> <br />
              <h4>Slamat datang di website resmi dzakira_official dari brand Dzakira</h4>
            </center>            
            <div id="images">
              <h3>Produk</h3>
              <FormGroup >
                <Input
                  name="search"
                  id="search"
                  className=""
                  placeholder="Cari produk"
                  value={form.name}
                  onChange={handleChange}
                  autoComplete="off" 
                />
              </FormGroup>
              <Row>
                { isLoading && 
                  <Col md="12" className="text-center">
                    Loading...
                  </Col>
                }

                { dataList.map(row => { 
                  return <Col
                    className="card-product"
                    sm="2"
                    onClick={() => doShowModal(Mode.SHOW, row)}
                    >
                    <div
                      style={{
                        height: '150px',
                        width: '100%',
                        marginBottom: '10px',
                        backgroundColor: '#f1f1f1',
                        backgroundImage: `url(${apiURL}${row.image})` || 'url(https://via.placeholder.com/300)',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center'
                      }}>
                    </div>
                      <p className="category">{row.name}</p>
                      <p className="text-right">
                        <div style={{fontSize: '12px'}}>{row.size.join()}</div>
                        Tersisa: <b>{formatNumber(row.variation.reduce((a, b) => Number(a) + (b.colors.reduce((c, d) => Number(c) + (d.stock), 0)), 0))} pcs </b><br />

                        { account.role
                          && row.variation.map((row) => {
                          return row.colors.map((color) => {
                            return <div><small>
                              {color.color} {row.size} - {color.stock} pcs
                            </small></div>
                          })
                        })}
                      </p>

                  </Col>
                })}
              </Row>
            </div>
          </Container>
        </div>
        <DarkFooter />
      </div>

      <ProductHomeModal
        toggleModal={setModeModalProduct}
        data={formModalProduct}
        mode={modeModalProduct}
        className={'modal-md'}
      />
    </>
  )
}


const mapStateToProps = (state) => ({
  product: state.product,
})

export default connect( mapStateToProps, {
	getProduct,
})(Index)
