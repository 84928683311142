import * as Type from 'store/actionTypes/recap'

const initialStateWithoutData = {
  isLoading: false,
  isError: false,
  message: [],
}

const initialState = {
  ...initialStateWithoutData,
  data: {
  },
  dataDetail: {},
}

const request = state => ({
  ...state,
  ...initialStateWithoutData,
  isLoading: true,
})

const requestError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  message: payload,
})


const getRecapSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  data: payload,
})

const recapReducer = (state = initialState, action) => {
  switch (action.type) {
    case Type.RECAP_REQUEST: return request(state)
    case Type.RECAP_REQUEST_CUD: return request(state)

    case Type.GET_RECAP_SUCCESS: return getRecapSuccess(state, action.payload)
    case Type.GET_RECAP_ERROR: return requestError(state, action.payload)
    default:
      return state
  }
}

export default recapReducer
