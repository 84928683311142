import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Button } from "reactstrap";
import PropTypes from "prop-types";
import swal from "sweetalert2";
import { connect } from "react-redux";
import ReactTable from "react-table";
import "react-table/react-table.css";
import AuthenticatedNavbar from "components/Navbars/AuthenticatedNavbar.js";
import ProductModal from "./ProductModal";
import { getProduct, updateProduct, deleteProduct } from "store/actions";
import { syncStockShopee } from "utils";

export const Mode = {
  HIDE: "0",
  ADD: "1",
  EDIT: "2",
  DELETE: "3",
};

const ProductPage = (props) => {
  const [modeModalProduct, setModeModalProduct] = useState(Mode.HIDE);
  const [formModalProduct, setFormModalProduct] = useState({});

  const fetchData = async (params) => {
    await props.getProduct(params);
  };
  const onFetchData = async (rtState = {}) => {
    const { page, pageSize, filtered, sorted } = rtState;

    const filteredStr = JSON.stringify(filtered);
    const sortedStr = JSON.stringify(sorted);

    let params = `page=${Number(
      page
    )}&pageSize=${pageSize}&filtered=${filteredStr}`;

    fetchData(params);
  };
  function doShowModal(mode, data = null) {
    let form;
    switch (mode) {
      case Mode.EDIT:
      case Mode.DELETE:
        form = data;
        break;
      case Mode.ADD:
        form = {};
        break;
      default:
        break;
    }

    setModeModalProduct(mode);
    setFormModalProduct(form);
  }

  const handleDelete = (data) => {
    let id = data._id;
    swal
      .fire({
        title: `Apakah anda yakin ingin menghapus product ini?`,
        text: "Data yang sudah terhapus tidak dapat dikembalikan",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        confirmButtonText: "Ya",
        cancelButtonColor: "#3085d6",
      })
      .then(async (result) => {
        if (result.value) {
          await props.deleteProduct(id);
          fetchData();
        }
      });
  };

  const { product } = props;
  const { isLoading, isError, message, data } = product;
  const { data: dataList, page, pageSize, totalPage } = data;

  const columns = [
    {
      Header: "#",
      width: 60,
      filterable: false,
      Cell: (props) => (
        <p className="text-center">{page * pageSize + props.index + 1}</p>
      ),
    },
    {
      Header: "Nama",
      accessor: "name",
      filterable: true,
      Cell: (props) => {
        const { variation } = props.original;
        const hasShopeeIds = variation[0].colors[0].shopeeIds;
        const hasTiktokIds = variation[0].colors[0].tiktokIds;
        return (
          <>
            {props.value}{" "}
            {hasShopeeIds && (
              <img src={"/shopee_icon.png"} width={20} height={20} />
            )}
            &nbsp;
            {hasTiktokIds && (
              <img src={"/tiktok_icon.webp"} width={20} height={20} />
            )}
          </>
        );
      },
    },
    {
      Header: "Jenis",
      accessor: "productType",
      Cell: (props) => <p className="text-center">{props.value?.name}</p>,
    },
    {
      Header: "Model",
      accessor: "productModel",
      Cell: (props) => <p className="text-center">{props.value?.name}</p>,
    },
    {
      Header: "Size",
      accessor: "size",
      style: { whiteSpace: "unset" },
      Cell: (props) => (
        <p className="text-center">
          <small>{props.value.join()}</small>
        </p>
      ),
    },
    {
      Header: "Model",
      accessor: "color",
      style: { whiteSpace: "unset" },
      Cell: (props) => (
        <p className="text-center">
          <small>{props.value?.join()}</small>
        </p>
      ),
    },
    {
      Header: "Aksi",
      width: 200,
      Cell: (props) => (
        <div className="text-center">
          <button
            type="button"
            className="btn btn-sm btn-success"
            onClick={() => doShowModal(Mode.EDIT, props.original)}
          >
            Lihat
          </button>
          &nbsp;
          <button
            type="button"
            className="btn btn-sm btn-danger"
            onClick={() => handleDelete(props.original)}
          >
            Hapus
          </button>
        </div>
      ),
    },
  ];

  return (
    <>
      <AuthenticatedNavbar />

      <div id="content" className="container-fluid pt-4 pb-4">
        <div className="title-lb">
          <label>Produk</label> <br />
          <span onClick={() => props.history.goBack()}>
            <i className="fa fa-arrow-left" /> Kembali
          </span>
        </div>

        <Card className="shadow-sm">
          <CardBody>
            <Button
              type="button"
              className="btn btn-sm btn-info float-right"
              onClick={() => syncStockShopee()}
            >
              Update Stock Shopee
            </Button>
            <Link
              to="/a/product-type"
              type="button"
              className="btn btn-sm btn-warning float-right"
            >
              <i className="fa fa-plus" /> Jenis
            </Link>

            <Link
              to="/a/product-model"
              type="button"
              className="btn btn-sm btn-warning float-right"
            >
              <i className="fa fa-plus" /> Model
            </Link>

            <Button
              type="button"
              className="btn btn-sm btn-success float-right"
              onClick={() => doShowModal(Mode.ADD)}
            >
              <i className="fa fa-plus" /> Tambah Produk
            </Button>
            <br />
            <br />
            <br />
            <br />
            <br />
            <ReactTable
              manual
              data={dataList}
              columns={columns}
              pages={totalPage}
              page={page}
              defaultPageSize={20}
              loading={isLoading}
              minRows={20}
              onFetchData={onFetchData}
            />
          </CardBody>
        </Card>

        <ProductModal
          toggleModal={setModeModalProduct}
          data={formModalProduct}
          mode={modeModalProduct}
          refresh={() => fetchData()}
          className={"modal-xl"}
        />
      </div>
    </>
  );
};

ProductPage.propTypes = {
  product: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
    isError: PropTypes.bool.isRequired,
    message: PropTypes.array,
    data: PropTypes.object.isRequired,
  }),
  getProduct: PropTypes.func.isRequired,
  updateProduct: PropTypes.func.isRequired,
  deleteProduct: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  product: state.product,
});

export default connect(mapStateToProps, {
  getProduct,
  updateProduct,
  deleteProduct,
})(ProductPage);
