//Image
import onlineShop from "assets/img/undraw_online_shopping_ga73.png";
import customer from "assets/img/undraw_empty_cart_co35.png";
import chartOnLaptop from "assets/img/undraw_finance_0bdk.png";
import personHoldMoney from "assets/img/undraw_personal_finance_tqcd.png";
import lookchart from "assets/img/undraw_revenue_3osh.png";
import underContruction from "assets/img/undraw_under_construction_46pa.png";
import wallet from "assets/img/undraw_wallet_aym5.png";
import note from "assets/img/undraw_diary_99km.png";
import homeBackground from "assets/img/background-texture-2110724_640.jpg";
import logo from "assets/img/logo-dzakira.png";

//Icon
// import icoAccount from 'assets/icon/Ico - Account.png'

//Audio
import beepCashier from "assets/audio/1417.wav";

export const img = {
  onlineShop,
  customer,
  chartOnLaptop,
  personHoldMoney,
  lookchart,
  underContruction,
  wallet,
  note,
  homeBackground,
  logo,
};

export const icon = {
  // icoAccount,
};
export const audio = {
  beepCashier,
};
