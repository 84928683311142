export const INOUT_REQUEST = "INOUT_REQUEST";
export const INOUT_REQUEST_CUD = "INOUT_REQUEST_CUD";
// get
export const GET_INOUT_SUCCESS = "GET_INOUT_SUCCESS";
export const GET_INOUT_ERROR = "GET_INOUT_ERROR";
// get detail
export const GET_INOUT_DETAIL_SUCCESS = "GET_INOUT_DETAIL_SUCCESS";
export const GET_INOUT_DETAIL_ERROR = "GET_INOUT_DETAIL_ERROR";
// create
export const CREATE_INOUT_SUCCESS = "CREATE_INOUT_SUCCESS";
export const CREATE_INOUT_ERROR = "CREATE_INOUT_ERROR";
// update
export const UPDATE_INOUT_SUCCESS = "UPDATE_INOUT_SUCCESS";
export const UPDATE_INOUT_ERROR = "UPDATE_INOUT_ERROR";
// delete
export const DELETE_INOUT_SUCCESS = "DELETE_INOUT_SUCCESS";
export const DELETE_INOUT_ERROR = "DELETE_INOUT_ERROR";
// product in out
export const PRODUCT_INOUT_SUCCESS = "PRODUCT_INOUT_SUCCESS";
export const PRODUCT_INOUT_ERROR = "PRODUCT_INOUT_ERROR";
// revert product in
export const REVERT_PRODUCT_IN_SUCCESS = "REVERT_PRODUCT_IN_SUCCESS";
export const REVERT_PRODUCT_IN_ERROR = "REVERT_PRODUCT_IN_ERROR";
// revert product out
export const REVERT_PRODUCT_OUT_SUCCESS = "REVERT_PRODUCT_OUT_SUCCESS";
export const REVERT_PRODUCT_OUT_ERROR = "REVERT_PRODUCT_OUT_ERROR";
// revert product out
export const CHECKLIST_PRODUCT_OUT_SUCCESS = "CHECKLIST_PRODUCT_OUT_SUCCESS";
export const CHECKLIST_PRODUCT_OUT_ERROR = "CHECKLIST_PRODUCT_OUT_ERROR";
