import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import Swal from "sweetalert2";

import PropTypes from "prop-types";
import swal from "sweetalert2";
import { connect } from "react-redux";
import "react-table/react-table.css";
import _ from "lodash";
import AuthenticatedNavbar from "components/Navbars/AuthenticatedNavbar.js";
// import ProductInModal from "./ProductInModal"
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import {
  getProduct,
  getCustomer,
  productInout,
  createCustomer,
  updateCustomer,
} from "store/actions";
import { formatNumber, xhr } from "utils";
import {
  priceType as priceTypeList,
  sourceOfSales as sourceOfSalesList,
  barcodeType as barcodeTypeList,
} from "constant";
import { Howl } from "howler";
import { audio } from "assets";

export const Mode = {
  HIDE: "0",
  ADD: "1",
  EDIT: "2",
  DELETE: "3",
};
const sound = new Howl({
  src: [audio.beepCashier],
});

const initValue = {
  barcodeType: localStorage.getItem("barcodeType") || "Shopee",
  barcodeTypeSelected: localStorage.getItem("barcodeTypeSelected")
    ? JSON.parse(localStorage.getItem("barcodeTypeSelected"))
    : barcodeTypeList[1],
  priceType: localStorage.getItem("priceRetail") || "priceRetail",
  priceTypeSelected: localStorage.getItem("priceTypeSelected")
    ? JSON.parse(localStorage.getItem("priceTypeSelected"))
    : {
        label: "priceRetail",
        value: "priceRetail",
      },
  sourceOfSales: localStorage.getItem("sourceOfSales") || "Shopee",
  sourceOfSalesSelected: localStorage.getItem("sourceOfSalesSelected")
    ? JSON.parse(localStorage.getItem("sourceOfSalesSelected"))
    : {
        label: "Shopee",
        value: "Shopee",
      },
  autoSubmit: Number(localStorage.getItem("autoSubmit")) || 0,
};

const ProductOutCreatePage = (props) => {
  const [form, setForm] = useState([]);

  const [autoSubmit, set_autoSubmit] = useState(initValue.autoSubmit);
  const [setsubmitting, set_setsubmitting] = useState(false);
  const [barcodeType, set_barcodeType] = useState(initValue.barcodeType);
  const [barcode, set_barcode] = useState("");
  const [discount, set_discount] = useState(0);
  const [discountPercent, set_discountPercent] = useState(5);
  const [platformFee, set_platformFee] = useState(0);
  const [platformFeePercent, set_platformFeePercent] = useState(3.5);
  const [priceType, set_priceType] = useState(initValue.priceType);
  const [sourceOfSales, set_sourceOfSales] = useState(initValue.sourceOfSales);
  const [customer, set_customer] = useState(null);
  const [phone, set_phone] = useState(null);
  const [priceTypeSelected, set_priceTypeSelected] = useState(
    initValue.priceTypeSelected
  );
  const [sourceOfSalesSelected, set_sourceOfSalesSelected] = useState(
    initValue.sourceOfSalesSelected
  );
  const [customerSelected, set_customerSelected] = useState(null);
  const [barcodeTypeSelected, set_barcodeTypeSelected] = useState(
    initValue.barcodeTypeSelected
  );
  const [isLoadingCustomer, set_isLoadingCustomer] = useState(false);
  const [resiPlatform, set_resiPlatform] = useState(null);

  const dataProductIsLoading = props.product.data.data.length == 0;
  const dataProduct = props.product.data.data;
  const dataCustomer = props.customer.data.data;

  let productList = [];
  dataProduct.forEach((rowProduct) => {
    rowProduct.variation.forEach((rowVariation) => {
      rowVariation.colors.forEach((rowColor) => {
        productList.push({
          label:
            `${rowProduct.name} ` +
            `${rowColor.color} ` +
            `${rowVariation.size} ` +
            `(${rowColor.stock})`,
          value: rowColor.barcode,
        });
      });
    });
  });
  const customerList = dataCustomer
    ? dataCustomer.map((row) => {
        return {
          ...row,
          label: row.name,
          value: row._id,
        };
      })
    : [];

  //componentDidMount
  useEffect(() => {
    fetchData();
  }, []);

  const generateAmount = () => {
    const newForm = form.map((row) => {
      let amountBeforeDiscount = row.pricePerItem * row.qty;
      let discount = (amountBeforeDiscount * discountPercent) / 100;
      let platformFee = (amountBeforeDiscount * platformFeePercent) / 100;
      let amount = amountBeforeDiscount - discount - platformFee;
      let profit = amount - row.priceCapital * row.qty;

      return {
        ...row,
        discount,
        discountPercent,
        platformFee,
        platformFeePercent,
        customer,
        phone,
        priceType,
        sourceOfSales,
        amountBeforeDiscount,
        amount,
        profit,
      };
    });
    setForm(newForm);
  };

  //componentDidMount
  useEffect(() => {
    if (barcode) {
      if (barcodeType === "Dzakira") _onDetectedProduct(barcode);
      else if (barcodeType === "Shopee") _onDetectedShopee(barcode);
      else if (barcodeType === "Tiktok") _onDetectedTiktok(barcode);
    } else generateAmount();
  }, [barcode]);

  useEffect(() => {
    generateAmount();
  }, [
    discountPercent,
    platformFeePercent,
    customer,
    phone,
    priceType,
    sourceOfSales,
  ]);

  const fetchData = async () => {
    props.getCustomer();
    props.getProduct();
  };

  const handleCreateCustomer = async (inputValue) => {
    set_isLoadingCustomer(true);
    const res = await xhr.post("/customer", { name: inputValue });
    const newCustomer = {
      ...res.data,
      label: res.data.name,
      value: res.data._id,
    };
    fetchData();
    set_customer(newCustomer.value);
    set_customerSelected(newCustomer);
    set_isLoadingCustomer(false);
    set_phone("");
  };

  const onSubmit = async (e) => {
    try {
      if (resiPlatform) {
        await xhr.post(`/order`, { orderNumber: resiPlatform });
      }

      props.productInout({ products: form });
    } catch (e) {
      Swal.fire("error", e.message, "error");
    } finally {
      if (customer) {
        props.updateCustomer({ priceType, sourceOfSales, phone }, customer);
      }

      _.delay(() => {
        setForm([]);
        set_resiPlatform(null);
        set_setsubmitting(false);
      }, 1000);
    }
  };

  const _onDetectedProduct = async (
    barcode,
    newQty = 1,
    isSaveForm = true,
    orderNumber = ""
  ) => {
    let newForm = [...form];
    barcode = String(barcode);

    let pd;

    dataProduct.forEach((rowProduct, idxProduct) => {
      rowProduct.variation.forEach((rowSize, idxSize) => {
        rowSize.colors.forEach((rowColor, idxColor) => {
          if (
            rowColor.barcode === barcode || // barcode dzakira
            rowColor.shopeeIds?.split(",").includes(barcode) || // resi shopee
            rowColor.tiktokIds?.split(",").includes(barcode) // resi tiktok
          ) {
            pd = {
              ...dataProduct[idxProduct],
              variation: {
                ...dataProduct[idxProduct].variation[idxSize],
                colors:
                  dataProduct[idxProduct].variation[idxSize].colors[idxColor],
              },
            };
          }
        });
      });
    });

    // no product match
    if (!pd) {
      return;
    }

    sound.play();

    // product match, then continue
    const variation = pd.variation;
    const size = variation.size;
    const colors = variation.colors;

    const existProductIdx = newForm.findIndex((row) => {
      return (
        row.product === pd._id &&
        row.variation === variation._id &&
        row.colors === colors._id
      );
    });

    if (existProductIdx !== -1) {
      if (colors.stock >= newForm[existProductIdx].qty + newQty) {
        newForm[existProductIdx].qty += newQty;

        newForm[existProductIdx].amountBeforeDiscount =
          Number(newForm[existProductIdx].qty) *
          Number(newForm[existProductIdx].pricePerItem);
        newForm[existProductIdx].amount =
          (newForm[existProductIdx].amountBeforeDiscount *
            (100 - discountPercent - platformFeePercent)) /
          100;
        newForm[existProductIdx].profit =
          newForm[existProductIdx].amount -
          newForm[existProductIdx].priceCapital * newForm[existProductIdx].qty;
      }
    } else {
      const amount =
        (variation["priceRetail"] *
          (100 - discountPercent - platformFeePercent)) /
        100;
      const newItem = {
        productObj: pd,
        product: pd._id,
        variation: variation._id,
        colors: colors._id,
        colorName: colors.color,
        stock: colors.stock,
        description: `${pd.name}-${size}-${colors.color}`,
        inoutType: "Pemasukan",
        inoutCategory: "barcode",
        priceType: "priceRetail",
        pricePerItem: variation["priceRetail"],
        priceCapital: variation["priceCapital"],
        qty: newQty,
        amount: amount,
        amountBeforeDiscount: variation["priceRetail"],
        profit: amount - variation["priceCapital"],
        orderNumber,
      };

      if (colors.stock > 0) {
        newForm = newForm.concat([newItem]);
      } else {
        swal.fire(
          "error",
          `${pd.name}-${pd.name}-${size}-${colors.color}: Stok kosong`,
          "error"
        );
      }
    }

    if (isSaveForm) {
      setForm(newForm);
      setForm(newForm);
      set_barcode("");
    } else {
      return newForm;
    }
  };

  useEffect(() => {
    if (setsubmitting) {
      onSubmit();
    }
  }, [setsubmitting]);

  const _onDetectedShopee = async (barcode) => {
    if (barcode.length !== 14) return;
    if (resiPlatform) {
      return Swal.fire("error", "Silahkan scan satu per satu", "error");
    }

    barcode = _.toUpper(barcode);

    const res = await xhr.get(`/shopee/order-detail/shopee-barcode/${barcode}`);
    const data = res.data.length ? res.data[0] : null;

    if (!data) return;

    const { buyer_username, item_list } = data;

    setForm([]);
    let newForm = [];
    let newItem = [];
    for (let i = 0; i < item_list.length; i++) {
      const item = item_list[i];

      let model_discounted_price = item.model_discounted_price;
      if (model_discounted_price === 0) {
        model_discounted_price = item.model_original_price * 0.95;
      }

      const disc =
        ((item.model_original_price - model_discounted_price) /
          item.model_original_price) *
        100;
      set_discountPercent(disc);
      newItem = await _onDetectedProduct(
        item.model_id,
        item.model_quantity_purchased,
        false,
        barcode
      );
      newForm = newForm.concat(newItem);
    }
    setForm(newForm);
    setForm(newForm);
    set_barcode("");

    const isExistCust = customerList.find(
      (row) => row.label === buyer_username
    );
    if (isExistCust) {
      handleChangeForm2("customer", isExistCust);
    } else {
      handleCreateCustomer(buyer_username);
    }

    set_platformFeePercent(3.5);
    set_priceType(priceTypeList[0].value);
    set_priceTypeSelected(priceTypeList[0]);
    set_sourceOfSales(sourceOfSalesList[0].value);
    set_sourceOfSalesSelected(sourceOfSalesList[0]);

    set_resiPlatform(barcode);

    _.delay(() => {
      if (autoSubmit) {
        set_setsubmitting(true);
      }
    }, 1000);
  };

  const _onDetectedTiktok = async (result) => {
    const barcode = typeof result == "string" ? result : result.codeResult.code;

    set_barcode("");
  };

  const handleChangeForm2 = async (name, value) => {
    if (name === "barcodeType") {
      set_barcodeType(value.value);
      set_barcodeTypeSelected(value);
      set_sourceOfSales(value.value);
      set_sourceOfSalesSelected(value);

      localStorage.setItem("barcodeType", value.value);
      localStorage.setItem("barcodeTypeSelected", JSON.stringify(value));
    }
    if (name === "barcode") {
      set_barcode(value);
    } else if (name === "discount") {
      set_discount(value);
    } else if (name === "discountPercent") {
      set_discountPercent(value);
    } else if (name === "platformFee") {
      set_platformFee(value);
    } else if (name === "platformFeePercent") {
      set_platformFeePercent(value);
    } else if (name === "priceType") {
      set_priceType(value.value);
      set_priceTypeSelected(value);
    } else if (name === "sourceOfSales") {
      set_sourceOfSales(value.value);
      set_sourceOfSalesSelected(value);
    } else if (name === "phone") {
      set_phone(value);
    } else if (name === "customer") {
      set_customer(value.value);
      set_customerSelected(value);

      if (value.priceType) {
        set_priceType(value.priceType);
        set_priceTypeSelected({
          label: value.priceType,
          value: value.priceType,
        });
      }
      if (value.sourceOfSales) {
        set_sourceOfSales(value.sourceOfSales);
        set_sourceOfSalesSelected({
          label: value.sourceOfSales,
          value: value.sourceOfSales,
        });
      }
      if (value.phone) {
        set_phone(value.phone);
      } else {
        set_phone("");
      }
    }
  };

  const handleChangeQty = async (e, typeOrValue, idx) => {
    // e.preventDefault()
    sound.play();
    let newForm = [...form];
    if (typeOrValue === "+") {
      if (newForm[idx].stock >= newForm[idx].qty + 1) {
        newForm[idx].qty += 1;
      }
    } else if (typeOrValue === "-") {
      if (newForm[idx].qty !== 1) {
        newForm[idx].qty -= 1;
      }
    } else {
      if (newForm[idx].stock >= Number(typeOrValue)) {
        newForm[idx].qty = Number(typeOrValue);
      }
    }

    // newForm[idx].amount = Number(newForm[idx].qty) * Number(newForm[idx].pricePerItem)
    setForm(newForm);

    // generate amount
    generateAmount(newForm);
  };

  return (
    <>
      <AuthenticatedNavbar />

      <div id="content" className="container-fluid pt-4 pb-4">
        <div className="title-lb">
          <label>Scan Barang Keluar</label> <br />
          <span onClick={() => props.history.goBack()}>
            <i className="fa fa-arrow-left" /> Kembali
          </span>
        </div>
        <Form>
          <Row>
            <Col md={{ size: 8, offset: 2 }}>
              <Row>
                <Col md="4">
                  <FormGroup>
                    <Select
                      id="barcodeType"
                      name="barcodeType"
                      className="basic-single"
                      options={barcodeTypeList}
                      value={barcodeTypeSelected}
                      classNamePrefix="select"
                      onChange={(v) => handleChangeForm2("barcodeType", v)}
                      isDisabled={dataProductIsLoading}
                    />
                  </FormGroup>
                </Col>
                {barcodeType !== "Dzakira" && (
                  <Col md="8">
                    <FormGroup>
                      <Input
                        type="text"
                        min={1}
                        name="barcode"
                        id="barcode"
                        className=""
                        placeholder="input barcode manual"
                        value={barcode}
                        onChange={(e) =>
                          handleChangeForm2("barcode", e.target.value)
                        }
                        autoComplete="off"
                        disabled={dataProductIsLoading}
                      />
                    </FormGroup>

                    {/* <FormGroup style={{ margin: "-10px 20px" }}>
                      <Input
                        type="checkbox"
                        name="autoSubmit"
                        id="autoSubmit"
                        checked={autoSubmit}
                        onClick={() => {
                          set_autoSubmit(Number(!autoSubmit));
                          localStorage.setItem(
                            "autoSubmit",
                            Number(!autoSubmit)
                          );
                        }}
                        autoComplete="off"
                        isDisabled={dataProductIsLoading}
                      />
                      <small>Auto Submit {String(autoSubmit)}</small>
                    </FormGroup> */}
                  </Col>
                )}
                {barcodeType === "Dzakira" && (
                  <Col md={barcodeType !== "Dzakira" ? 3 : 8}>
                    <FormGroup>
                      <Select
                        placeholder="Masukan produk"
                        onChange={(v) => handleChangeForm2("barcode", v.value)}
                        options={productList}
                        styles={{
                          // Fixes the overlapping problem of the component
                          menu: (provided) => ({ ...provided, zIndex: 9999 }),
                        }}
                        isDisabled={dataProductIsLoading}
                      />
                    </FormGroup>
                  </Col>
                )}
              </Row>
              <hr />
              {form.map((row, index) => {
                const { name } = row?.productObj;
                const size = row?.productObj?.variation?.size;
                const { color, stock } = row.productObj?.variation?.colors;
                const {
                  // priceCapital,
                  priceRetail,
                } = row.productObj?.variation;

                return (
                  <Card>
                    <CardBody>
                      <Row>
                        <Col md="1">{index + 1}.</Col>
                        <Col md="7">
                          <b>{name}</b> <br />
                          {color} ({size}) <br /> <br />
                          <span>Stok: {stock}</span> <br />
                          <b>
                            Harga: Rp. {formatNumber(priceRetail)} x {row.qty}
                          </b>
                        </Col>
                        <Col md="4">
                          <InputGroup size="lg">
                            <InputGroupAddon addonType="prepend">
                              <Button
                                className="btn"
                                style={{ margin: 0 }}
                                onClick={(e) => handleChangeQty(e, "-", index)}
                              >
                                <b>-</b>
                              </Button>
                            </InputGroupAddon>
                            <Input
                              value={row.qty}
                              onChange={(e) =>
                                handleChangeQty(e, e.target.value, index)
                              }
                            />
                            <InputGroupAddon addonType="append">
                              <Button
                                className="btn"
                                style={{ margin: 0 }}
                                onClick={(e) => handleChangeQty(e, "+", index)}
                              >
                                <b>+</b>
                              </Button>
                            </InputGroupAddon>
                          </InputGroup>

                          <br />
                          {row.amountBeforeDiscount === row.amount && (
                            <b>Rp. {formatNumber(row.amount)}</b>
                          )}
                          {row.amountBeforeDiscount !== row.amount && (
                            <b>
                              <span>
                                Rp. {formatNumber(row.amount)}
                                <small>
                                  <strike className="text-danger">
                                    {formatNumber(row.amountBeforeDiscount)}
                                  </strike>
                                </small>
                              </span>
                            </b>
                          )}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                );
              })}

              {form.length > 0 ? (
                <>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <Label htmlFor="discountPercent">
                          <div>
                            <b>Diskon (%)</b>
                          </div>
                        </Label>
                        <Input
                          value={discountPercent}
                          onChange={(e) =>
                            handleChangeForm2("discountPercent", e.target.value)
                          }
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="platformFeePercent">
                          <div>
                            <b>
                              Biaya Admin (%){" "}
                              <small>
                                <superscript>*</superscript>{" "}
                                shoope/tiktok/lazada
                              </small>
                            </b>
                          </div>
                        </Label>
                        <Input
                          value={platformFeePercent}
                          onChange={(e) =>
                            handleChangeForm2(
                              "platformFeePercent",
                              e.target.value
                            )
                          }
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="customer">
                          <div>
                            <b>Pembeli</b>
                          </div>
                        </Label>
                        <CreatableSelect
                          placeholder="Masukan pembeli"
                          isDisabled={
                            props.customer.isLoading || isLoadingCustomer
                          }
                          isLoading={
                            props.customer.isLoading || isLoadingCustomer
                          }
                          onChange={(v) => handleChangeForm2("customer", v)}
                          onCreateOption={handleCreateCustomer}
                          options={customerList}
                          value={customerSelected}
                          formatOptionLabel={({ value, label, phone }) => (
                            <div style={{ display: "flex" }}>
                              <div>{label}</div>
                              <div
                                style={{ marginLeft: "10px", color: "#ccc" }}
                              >
                                {phone}
                              </div>
                            </div>
                          )}
                        />
                      </FormGroup>

                      <FormGroup>
                        <Label htmlFor="phone">
                          <div>
                            <b>No. telp</b>
                          </div>
                        </Label>
                        <Input
                          value={phone}
                          onChange={(e) =>
                            handleChangeForm2("phone", e.target.value)
                          }
                          disabled={customerSelected?.phone}
                          type="number"
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="priceType">
                          <div>
                            <b>jenis</b>
                          </div>
                        </Label>
                        <Select
                          id="priceType"
                          name="priceType"
                          className="basic-single"
                          options={priceTypeList}
                          value={priceTypeSelected}
                          classNamePrefix="select"
                          onChange={(v) => handleChangeForm2("priceType", v)}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="sourceOfSales">
                          <div>
                            <b>Sumber penjualan</b>
                          </div>
                        </Label>
                        <Select
                          id="sourceOfSales"
                          name="sourceOfSales"
                          className="basic-single"
                          options={sourceOfSalesList}
                          value={sourceOfSalesSelected}
                          classNamePrefix="select"
                          onChange={(v) =>
                            handleChangeForm2("sourceOfSales", v)
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Button
                    type="button"
                    className="btn btn-lg btn-success float-right"
                    onClick={() => onSubmit()}
                  >
                    <i className="fa fa-plus" /> Simpan
                  </Button>
                </>
              ) : (
                <p className="text-center">
                  Silahkan scan barcode dengan kamera atau input produk pada
                  text box diatas
                </p>
              )}
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

ProductOutCreatePage.propTypes = {
  inout: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
    isError: PropTypes.bool.isRequired,
    message: PropTypes.array,
    data: PropTypes.object.isRequired,
  }),
  getCustomer: PropTypes.func.isRequired,
  productInout: PropTypes.func.isRequired,
  getProduct: PropTypes.func.isRequired,
  createCustomer: PropTypes.func.isRequired,
  updateCustomer: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  inout: state.inout,
  product: state.product,
  customer: state.customer,
});

export default connect(mapStateToProps, {
  getCustomer,
  productInout,
  getProduct,
  createCustomer,
  updateCustomer,
})(ProductOutCreatePage);
