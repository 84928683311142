/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";
// core components
import { img } from "assets";

function IndexHeader() {
  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.innerWidth > 591) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  return (
    <>
      <div
        className="page-header clear-filter"
        filter-color="blue"
        style={{
          minHeight: "70vh",
          maxHeight: "70vh",
        }}
      >
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + img.homeBackground + ")",
            backgroundColor: "#54ADF7",
            maxHeight: "70vh",
            minHeight: "70vh",
          }}
          ref={pageHeader}
        ></div>

        <div className="content-center brand">
          <img alt="..." className="n-logo" src={img.logo}></img>
          <h1 className="h1-seo">Dzakira Official</h1>
          <h3>Gamisnya anak saliha</h3>
        </div>
      </div>
    </>
  );
}

export default IndexHeader;
