import jwt from 'jsonwebtoken'

export const verifyToken = () => {
  const accessToken = localStorage.getItem('accessToken')
	if(accessToken){
	  const { user } = jwt.decode(accessToken)
	  return user || {}
	}else{
    window.location.href = `/`	  	
	}
}
