


import React, {useEffect, useState} from "react"
import { Link } from "react-router-dom"
import {
	NavLink,
	Row,
	Col,
	Card,
	CardHeader as CardHeaderComponent,
	CardBody,
	Table,
	Button,
	Modal,
	Form,
	FormGroup,
	Label,
	Container,
} from "reactstrap"
import PropTypes from "prop-types"
import _ from "lodash"
import swal from "sweetalert2"
import {connect} from "react-redux"
import ReactTable from "react-table"
import "react-table/react-table.css"
import Chart,{Doughnut,Bar, Pie, Polar, Line} from 'react-chartjs-2';

import AuthenticatedNavbar from "components/Navbars/AuthenticatedNavbar.js";
// import ProductInModal from "./ProductInModal"
import { 
  getRecap,
	getProduct,
} from "store/actions"
import { formatNumber } from 'utils'
import moment from 'moment'

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { img } from 'assets'
import { getUser } from 'utils'

const Loading = () =>  'Loading ...'
const CardHeader = (props) => <CardHeaderComponent style={{
		backgroundColor: orange,
		backgroundColor: 'white',
		fontSize: 16,
		fontWeight: 'bold',
		color: 'grey',
		padding: '15px',
	}}>
	{props.children}
</CardHeaderComponent>
const red = '#ff6384'
const blue = '#36a2eb'
const purple = '#cc65fe'
const yellow = '#ffce56'
const orange = '#EB6139'

const RecapPage = (props) => {
  const account = getUser()

  const [form, setForm] = useState({})
	const { 
		isLoading, 
		isError, 
		message,
		data,
	} = props.recap
	const { 
		isLoading: isLoadingProduct, 
		isError: isErrorProduct, 
		message: messageProduct,
		data: dataProduct,
	} = props.product

	useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async (params) => {
    await props.getProduct(params)
  }

  const handleChangeByName = (name, value) => {
    setForm({
    	...form,
      [name]: value
    })
  }

	const submit = async () => {
		const dateFrom = moment(form.dateFrom).toString()
		const dateTo = moment(form.dateTo).toString()
		const { withManualInput } = form

		const params = `dateFrom=${dateFrom}`
			+ `&dateTo=${dateTo}`
			+ `&withManualInput=${false}`

		props.getRecap(params)
	}

	const DetailStat = (data) => {
		const Detail = data?.data?.labels?.map((label, idx) => (
			<Row>
				<Col md="6" className="text-left">{idx+1}. {label}</Col>
				<Col md="6" className="text-right">
					<b>
						{data?.data?.datasets.length > 0 
							&& formatNumber(data?.data?.datasets[0].data[idx])
						}
						&nbsp;<small>{data.unit}</small>

						{ account.role === 'superadmin'
							&& data.useStock
							&& <small> (stock: &nbsp;
							{data?.data?.datasets.length > 0 
								&& formatNumber(data?.data?.datasets[0].stock[idx])
							}
							&nbsp;<small>{data.unit}</small>
							)</small>
						}
					</b>
				</Col>
			</Row>
		))
		
		return(
			<>
				<hr />
				{Detail}
			</>
		)
	}

	// Recap Product
	const totalStock = _.sumBy(dataProduct.data, (row) => {
		return _.sumBy(row.variation, (row2) => {
			return _.sumBy(row2.colors, 'stock')
		})
	})
	const totalModal = _.sumBy(dataProduct.data, (row) => {
		return _.sumBy(row.variation, (row2) => {
			const stock = _.sumBy(row2.colors, 'stock')
			return stock * row2.priceCapital
		})
	})
	const totalPotensiPenjualan = _.sumBy(dataProduct.data, (row) => {
		return _.sumBy(row.variation, (row2) => {
			const stock = _.sumBy(row2.colors, 'stock')
			return stock * row2.priceRetail
		})
	})
	const totalPotensiPendapatan = totalPotensiPenjualan - totalModal

	// Recap Transaksi
	const prefix = data.frequency === 1 ? 'Minggu ' : '' 
	const dataCountPemasukanPerRange = {
		labels: data?.amountPemasukanPerRange?.map(row => `${prefix}${row._id}`),
		datasets: [
			{
				// label: 'Jumlah barang masuk',
				label: '',
				pointBorderColor: blue,
				pointBorderWidth: 5,
				borderColor: blue,
				// backgroundColor: data?.amountPemasukanPerRange?.map(
				// 									(row, index) => index % 2 === 0 ? blue : '#F0F0F0'
				// 								),
				borderWidth: 1,
				data: data?.amountPemasukanPerRange?.map(row => row.count)
			},
		]
	}
	const dataAmountPemasukanPerRange = {
		labels: data?.amountPemasukanPerRange?.map(row => `${prefix}${row._id}`),
		datasets: [
			{
				// label: 'Nilai barang masuk',
				label: '',
				pointBorderColor: blue,
				pointBorderWidth: 5,
				borderColor: blue,
				// backgroundColor: data?.amountPemasukanPerRange?.map(
				// 									(row, index) => index % 2 === 0 ? blue : '#F0F0F0'
				// 								),
				borderWidth: 1,
				data: data?.amountPemasukanPerRange?.map(row => row.amount)
			},
		]
	}

	const dataCountPengeluaranPerRange = {
		labels: data?.amountPengeluaranPerRange?.map(row => `${prefix}${row._id}`),
		datasets: [
			{
				// label: 'Jumlah barang terjual',
				label: '',
				pointBorderColor: blue,
				pointBorderWidth: 5,
				borderColor: blue,
				// backgroundColor: data?.amountPengeluaranPerRange?.map(
				// 									(row, index) => index % 2 === 0 ? blue : '#F0F0F0'
				// 								),
				borderWidth: 1,
				data: data?.amountPengeluaranPerRange?.map(row => row.count)
			},
		]
	}
	const dataAmountPengeluaranPerRange = {
		labels: data?.amountPengeluaranPerRange?.map(row => `${prefix}${row._id}`),
		datasets: [
			{
				// label: 'Nilai barang terjual',
				label: '',
				pointBorderColor: blue,
				pointBorderWidth: 5,
				borderColor: blue,
				// backgroundColor: data?.amountPengeluaranPerRange?.map(
				// 									(row, index) => index % 2 === 0 ? blue : '#F0F0F0'
				// 								),
				borderWidth: 1,
				data: data?.amountPengeluaranPerRange?.map(row => row.amount)
			},
		]
	}

	const countPemasukanPerCustomer = _.take(_.orderBy(data?.amountPemasukanPerCustomer, ['count'], ['desc']), 20)
	const dataCountPemasukanPerCustomer = {
		labels: countPemasukanPerCustomer?.map(row => `${row._id?.name}`),
		datasets: [
			{
				// label: 'Jumlah Pemasukan per Customer',
				label: '',
				backgroundColor: countPemasukanPerCustomer?.map(
													(row, index) => index % 2 === 0 ? blue : '#F0F0F0'
												),
				borderWidth: 1,
				data: countPemasukanPerCustomer?.map(row => row.count),
			},
		]
	}

	const amountPemasukanPerCustomer = _.take(_.orderBy(data?.amountPemasukanPerCustomer, ['amount'], ['desc']), 20)
	const dataAmountPemasukanPerCustomer = {
		labels: amountPemasukanPerCustomer?.map(row => `${row._id?.name}`),
		datasets: [
			{
				// label: 'Nilai Pemasukan per Customer',
				label: '',
				backgroundColor: amountPemasukanPerCustomer?.map(
													(row, index) => index % 2 === 0 ? blue : '#F0F0F0'
												),
				borderWidth: 1,
				data: amountPemasukanPerCustomer?.map(row => row.amount)
			},
		]
	}

	const countPemasukanPerProductModel = _.take(_.orderBy(data?.amountPemasukanPerProductModel, ['count'], ['desc']), 20)
	const dataCountPemasukanPerProductModel = {
		labels: countPemasukanPerProductModel?.map(row => `${row?.description?.split('-')[0]}`),
		datasets: [
			{
				// label: 'Jumlah Pemasukan per ProductModel',
				label: '',
				backgroundColor: countPemasukanPerProductModel?.map(
													(row, index) => index % 2 === 0 ? blue : '#F0F0F0'
												),
				borderWidth: 1,
				data: countPemasukanPerProductModel?.map(row => row.count),
			},
		]
	}
	const amountPemasukanPerProductModel = _.take(_.orderBy(data?.amountPemasukanPerProductModel, ['amount'], ['desc']), 20)
	const dataAmountPemasukanPerProductModel = {
		labels: amountPemasukanPerProductModel?.map(row => `${row?.description?.split('-')[0]}`),
		datasets: [
			{
				// label: 'Nilai Pemasukan per ProductModel',
				label: '',
				backgroundColor: amountPemasukanPerProductModel?.map(
													(row, index) => index % 2 === 0 ? blue : '#F0F0F0'
												),
				borderWidth: 1,
				data: amountPemasukanPerProductModel?.map(row => row.amount)
			},
		]
	}

	const countPemasukanPerProductType = _.take(_.orderBy(data?.amountPemasukanPerProductType, ['count'], ['desc']), 20)
	const dataCountPemasukanPerProductType = {
		labels: countPemasukanPerProductType?.map(row => `${row?._id?.productTypeName}`),
		datasets: [
			{
				// label: 'Jumlah Pemasukan per ProductType',
				label: '',
				backgroundColor: countPemasukanPerProductType?.map(
													(row, index) => index % 2 === 0 ? blue : '#F0F0F0'
												),
				borderWidth: 1,
				data: countPemasukanPerProductType?.map(row => row.count),
			},
		]
	}
	const amountPemasukanPerProductType = _.take(_.orderBy(data?.amountPemasukanPerProductType, ['amount'], ['desc']), 20)
	const dataAmountPemasukanPerProductType = {
		labels: amountPemasukanPerProductType?.map(row => `${row?._id?.productTypeName}`),
		datasets: [
			{
				// label: 'Nilai Pemasukan per ProductType',
				label: '',
				backgroundColor: amountPemasukanPerProductType?.map(
													(row, index) => index % 2 === 0 ? blue : '#F0F0F0'
												),
				borderWidth: 1,
				data: amountPemasukanPerProductType?.map(row => row.amount)
			},
		]
	}

	const countPemasukanPerProductColor = _.take(_.orderBy(data?.amountPemasukanPerProductColor, ['count'], ['desc']), 20)
	const dataCountPemasukanPerProductColor = {
		labels: countPemasukanPerProductColor?.map(row => `${row?._id ? row?._id : 'Tidak diisi'}`),
		datasets: [
			{
				// label: 'Jumlah Pemasukan per ProductColor',
				label: '',
				backgroundColor: countPemasukanPerProductColor?.map(
													(row, index) => index % 2 === 0 ? blue : '#F0F0F0'
												),
				borderWidth: 1,
				data: countPemasukanPerProductColor?.map(row => row.count),
			},
		]
	}
	const amountPemasukanPerProductColor = _.take(_.orderBy(data?.amountPemasukanPerProductColor, ['amount'], ['desc']), 20)
	const dataAmountPemasukanPerProductColor = {
		labels: amountPemasukanPerProductColor?.map(row => `${row?._id ? row?._id : 'Tidak diisi'}`),
		datasets: [
			{
				// label: 'Nilai Pemasukan per ProductColor',
				label: '',
				backgroundColor: amountPemasukanPerProductColor?.map(
													(row, index) => index % 2 === 0 ? blue : '#F0F0F0'
												),
				borderWidth: 1,
				data: amountPemasukanPerProductColor?.map(row => row.amount)
			},
		]
	}

	let countPemasukanPerProductDetail = _.take(_.orderBy(data?.amountPemasukanPerProductDetail, ['count'], ['desc']), 20)
	// generate stock
	countPemasukanPerProductDetail = countPemasukanPerProductDetail.map(row => {
		let stock = 0

		const product = _.find(dataProduct.data, { _id: row._id.product })
		if (product) {
			const variation = _.find(product.variation, { _id: row._id.variation })
			if (variation) {
				const colors = _.find(variation.colors, { _id: row._id.colors })
				if (colors) {
					stock = colors.stock
				}
			}
		}
		return {
			...row,
			stock,
		}
	})

	const dataCountPemasukanPerProductDetail = {
		labels: countPemasukanPerProductDetail?.map(row => `${row?._id ? row?._id.description : 'Tidak diisi'}`),
		datasets: [
			{
				// label: 'Jumlah Pemasukan per Product',
				label: '',
				background: countPemasukanPerProductDetail?.map(
													(row, index) => index % 2 === 0 ? blue : blue //'#F0F0F0'
												),
				borderWidth: 1,
				data: countPemasukanPerProductDetail?.map(row => row.count),
				stock: countPemasukanPerProductDetail?.map(row => row.stock),
			},
		]
	}
	const amountPemasukanPerProductDetail = _.take(_.orderBy(data?.amountPemasukanPerProductDetail, ['amount'], ['desc']), 20)
	const dataAmountPemasukanPerProductDetail = {
		labels: amountPemasukanPerProductDetail?.map(row => `${row?._id ? row?._id.description : 'Tidak diisi'}`),
		datasets: [
			{
				// label: 'Nilai Pemasukan per Product',
				label: '',
				background: amountPemasukanPerProductDetail?.map(
													(row, index) => index % 2 === 0 ? blue : blue //'#F0F0F0'
												),
				borderWidth: 1,
				data: amountPemasukanPerProductDetail?.map(row => row.amount)
			},
		]
	}

	const dataCountPemasukanPerSourceOfSales = {
		labels: data?.amountPemasukanPerSourceOfSales?.map(row => row._id ? `${row._id}` : 'Tidak diisi'),
		datasets: [
			{
				// label: 'Jumlah Pemasukan per Sumber penjualan',
				label: '',
				// backgroundColor: data?.amountPemasukanPerSourceOfSales?.map(
				// 									(row, index) => index % 2 === 0 ? blue : '#F0F0F0'
				// 								),
				backgroundColor: [red, blue, yellow],
				borderWidth: 1,
				data: data?.amountPemasukanPerSourceOfSales?.map(row => row.count)
			},
		]
	}
	const dataAmountPemasukanPerSourceOfSales = {
		labels: data?.amountPemasukanPerSourceOfSales?.map(row => row._id ? `${row._id}` : 'Tidak diisi'),
		datasets: [
			{
				// label: 'Nilai Pemasukan per Sumber penjualan',
				label: '',
				// backgroundColor: data?.amountPemasukanPerSourceOfSales?.map(
				// 									(row, index) => index % 2 === 0 ? blue : '#F0F0F0'
				// 								),
				backgroundColor: [red, blue, yellow],
				borderWidth: 1,
				data: data?.amountPemasukanPerSourceOfSales?.map(row => row.amount)
			},
		]
	}

	const dataCountPemasukanPerPriceType = {
		labels: data?.amountPemasukanPerPriceType?.map(row => `${row._id}`),
		datasets: [
			{
				// label: 'Jumlah Pemasukan per tipe harga',
				label: '',
				// backgroundColor: data?.amountPemasukanPerPriceType?.map(
				// 									(row, index) => index % 2 === 0 ? blue : '#F0F0F0'
				// 								),
				backgroundColor: [blue, red],
				borderWidth: 1,
				data: data?.amountPemasukanPerPriceType?.map(row => row.count)
			},
		]
	}
	
	const dataAmountPemasukanPerPriceType = {
		labels: data?.amountPemasukanPerPriceType?.map(row => `${row._id}`),
		datasets: [
			{
				// label: 'Nilai Pemasukan per tipe harga',
				label: '',
				// backgroundColor: data?.amountPemasukanPerPriceType?.map(
				// 									(row, index) => index % 2 === 0 ? blue : '#F0F0F0'
				// 								),
				backgroundColor: [blue, red],
				borderWidth: 1,
				data: data?.amountPemasukanPerPriceType?.map(row => row.amount)
			},
		]
	}

	// ===============================================
	// PRICE TYPE
	// ===============================================
	const countPemasukanPerProductType_PriceType = _.take(_.orderBy(data?.amountPemasukanPerProductType_PriceType, ['count'], ['desc']), 20)
	const dataCountPemasukanPerProductType_PriceType = {
		labels: countPemasukanPerProductType_PriceType?.map(row => `${row._id.productTypeName} (${row._id.priceType})`),
		datasets: [
			{
				label: '',
				backgroundColor: countPemasukanPerProductType_PriceType?.map(
													(row, index) => index % 2 === 0 ? blue : '#F0F0F0'
												),
				borderWidth: 1,
				data: countPemasukanPerProductType_PriceType?.map(row => row.count)
			},
		]
	}

	const amountPemasukanPerProductType_PriceType = _.take(_.orderBy(data?.amountPemasukanPerProductType_PriceType, ['count'], ['desc']), 20)
	const dataAmountPemasukanPerProductType_PriceType = {
		labels: amountPemasukanPerProductType_PriceType?.map(row => `${row._id.productTypeName} (${row._id.priceType})`),
		datasets: [
			{
				label: '',
				backgroundColor: amountPemasukanPerProductType_PriceType?.map(
													(row, index) => index % 2 === 0 ? blue : '#F0F0F0'
												),
				borderWidth: 1,
				data: amountPemasukanPerProductType_PriceType?.map(row => row.amount)
			},
		]
	}
	// DETAIL PRICE TYPE RESELLER
		const countPemasukanPerProductType_PriceTypeReseller = _.take(_.orderBy(_.filter(data?.amountPemasukanPerProductType_PriceType, ['_id.priceType', 'priceReseller']), ['count'], ['desc']), 20)
		const dataCountPemasukanPerProductType_PriceTypeReseller = {
			labels: countPemasukanPerProductType_PriceTypeReseller?.map(row => `${row._id.productTypeName}`),
			datasets: [
				{
					label: '',
					backgroundColor: countPemasukanPerProductType_PriceTypeReseller?.map(
														(row, index) => index % 2 === 0 ? blue : '#F0F0F0'
													),
					borderWidth: 1,
					data: countPemasukanPerProductType_PriceTypeReseller?.map(row => row.count)
				},
			]
		}

		const amountPemasukanPerProductType_PriceTypeReseller = _.take(_.orderBy(_.filter(data?.amountPemasukanPerProductType_PriceType, ['_id.priceType', 'priceReseller']), ['count'], ['desc']), 20)
		const dataAmountPemasukanPerProductType_PriceTypeReseller = {
			labels: amountPemasukanPerProductType_PriceTypeReseller?.map(row => `${row._id.productTypeName}`),
			datasets: [
				{
					label: '',
					backgroundColor: amountPemasukanPerProductType_PriceTypeReseller?.map(
														(row, index) => index % 2 === 0 ? blue : '#F0F0F0'
													),
					borderWidth: 1,
					data: amountPemasukanPerProductType_PriceTypeReseller?.map(row => row.amount)
				},
			]
		}
	// DETAIL PRICE TYPE RETAIL
		const countPemasukanPerProductType_PriceTypeRetail = _.take(_.orderBy(_.filter(data?.amountPemasukanPerProductType_PriceType, ['_id.priceType', 'priceRetail']), ['count'], ['desc']), 20)
		const dataCountPemasukanPerProductType_PriceTypeRetail = {
			labels: countPemasukanPerProductType_PriceTypeRetail?.map(row => `${row._id.productTypeName}`),
			datasets: [
				{
					label: '',
					backgroundColor: countPemasukanPerProductType_PriceTypeRetail?.map(
														(row, index) => index % 2 === 0 ? blue : '#F0F0F0'
													),
					borderWidth: 1,
					data: countPemasukanPerProductType_PriceTypeRetail?.map(row => row.count)
				},
			]
		}

		const amountPemasukanPerProductType_PriceTypeRetail = _.take(_.orderBy(_.filter(data?.amountPemasukanPerProductType_PriceType, ['_id.priceType', 'priceRetail']), ['count'], ['desc']), 20)
		const dataAmountPemasukanPerProductType_PriceTypeRetail = {
			labels: amountPemasukanPerProductType_PriceTypeRetail?.map(row => `${row._id.productTypeName}`),
			datasets: [
				{
					label: '',
					backgroundColor: amountPemasukanPerProductType_PriceTypeRetail?.map(
														(row, index) => index % 2 === 0 ? blue : '#F0F0F0'
													),
					borderWidth: 1,
					data: amountPemasukanPerProductType_PriceTypeRetail?.map(row => row.amount)
				},
			]
		}
	// ===============================================

  return (
    <>
      <AuthenticatedNavbar />

      <div id="content" className="container-fluid pt-4 pb-4">
				<Container>

					<div className="title-lb">
						<span 
							onClick={() => props.history.goBack()}>
							<i className="fa fa-arrow-left" /> Kembali
						</span>
					</div>

					<Card className="shadow-sm">
						<CardBody>
							<h4 className="text-center"><b>Rekap data produk</b></h4>
							<Row className="bg-red">
								<Col md={5} className="text-center">
									<img
										alt="..."
										className=""
										src={img.chartOnLaptop}
									></img>
								</Col>
								<Col md={7} className="text-center">
									<Row>
										<Col md={6}>
											<div className="stati sun_flower shadow-none">
												<div>
													<b>{formatNumber(totalStock)}</b>
													<span>Jumlah Produk</span>
												</div> 
											</div>
										</Col>
										<Col md={6}>
											<div className="stati sun_flower shadow-none">
												<div>
													<b>{formatNumber(totalModal)}</b>
													<span>Nominal Produk</span>
												</div> 
											</div>
										</Col>
									</Row>

									<Row>
										<Col md={6}>
											<div className="stati sun_flower shadow-none">
												<div>
													<b>{formatNumber(totalPotensiPenjualan)}</b>
													<span>Potensi Penjualan</span>
												</div> 
											</div>
										</Col>
										<Col md={6}>
											<div className="stati sun_flower shadow-none">
												<div>
													<b>{formatNumber(totalPotensiPendapatan)}</b>
													<span>Potensi Pendapatan</span>
												</div> 
											</div>
										</Col>
									</Row>

								</Col>
							</Row>
						</CardBody>
					</Card>
					<br />
					<br />
					<br />
					<hr />
					<br />

					<Form onSubmit={e => e.preventDefault()}>
						<Row>
							<Col md={{size: 4, offset: 4}} className="text-center">
								<h4><b>Rekap data penjualan</b></h4>
							</Col>
						</Row>

						<Row>
							<Col md={{ size: 6, offset: 3 }} className="text-center">
								<Row>
									<FormGroup className="col-md-6">
										<Label htmlFor="dateFrom"><b>Dari tanggal</b></Label><br />
										<DatePicker
											name="dateFrom"
											id="dateFrom"
											style={{width: '100%'}}
											autoComplete="off"
											selected={form.dateFrom}
											onChange={(v) => handleChangeByName('dateFrom', v)}
										/>
									</FormGroup>
									<FormGroup className="col-md-6">
										<Label htmlFor="dateTo"><b>Sampai tanggal</b></Label><br />
										<DatePicker
											name="dateTo"
											id="dateTo"
											style={{width: '100%'}}
											autoComplete="off"
											selected={form.dateTo}
											onChange={(v) => handleChangeByName('dateTo', v)}
										/>
									</FormGroup>
								</Row>
								<Button
									color="primary"
									size="lg"
									onClick={() => { submit() }}
									disabled={isLoading}
								>
									{ !isLoading ? 'Submit' : <Loading type='basicSpinnerBorder' /> }
								</Button>
							</Col>
						</Row>
					</Form>


					<Row>
						<Col md={4}>
							<div className="stati peter_river">
								<i className="fa fa-arrow-right"></i>
								<div>
									<b>Rp {formatNumber(data.amountPengeluaran)}</b>
									<span>Belanja</span>
								</div> 
							</div>
						</Col>
						<Col md={4}>
							<div className="stati peter_river">
								<i className="fa fa-arrow-right"></i>
								<div>
									<b>Rp {formatNumber(data.amountPemasukan)}</b>
									<span>Omset</span>
								</div> 
							</div>
						</Col>
						<Col md={4}>
							<div className="stati bg-peter_river ">
								<i className="fa fa-arrow-right"></i>
								<div>
									<b>Rp {formatNumber(data.amountProfit)}</b>
									<span>Profit</span>
								</div> 
							</div>
						</Col>
					</Row>

					<Row>
						<Col md={6}>
							<div className="stati peter_river">
								<i className="fa fa-arrow-right"></i>
								<div>
									<b>{formatNumber(data.countPemasukan)} pcs</b>
									<span>Produk Terjual</span>
								</div> 
							</div>
						</Col>
						<Col md={6}>
							<div className="stati peter_river">
								<i className="fa fa-arrow-right"></i>
								<div>
									<b>{formatNumber(data.countPengeluaran)} pcs</b>
									<span>Produk Masuk</span>
								</div> 
							</div>
						</Col>
					</Row>

					<br />
					{data?.amountPengeluaranPerRange && 
					<Row>
						<Col md={6}>
							<Card>
								<CardHeader>
									Jumlah <b>Produk Terjual Per Waktu Tertentu</b>
								</CardHeader>
								<CardBody>
									<Line data={dataCountPemasukanPerRange} />
									<DetailStat data={dataCountPemasukanPerRange} unit={"pcs"}/>
								</CardBody>
							</Card>
						</Col>
						<Col md={6}>
							<Card>
								<CardHeader>
									Nominal Produk Terjual per Waktu Tertentu
								</CardHeader>
								<CardBody>
									<Line data={dataAmountPemasukanPerRange} />
									<DetailStat data={dataAmountPemasukanPerRange} unit={"Rupiah"}/>
								</CardBody>
							</Card>
						</Col>
					</Row>				
					}
					{data?.amountPengeluaranPerRange && 
					<Row>
						<Col md={6}>
							<Card>
								<CardHeader>
									Jumlah Produk Masuk Per Waktu Tertentu
								</CardHeader>
								<CardBody>
									<Line data={dataCountPengeluaranPerRange} />
									<DetailStat data={dataCountPengeluaranPerRange} unit={"pcs"}/>
								</CardBody>
							</Card>
						</Col>
						<Col md={6}>
							<Card>
								<CardHeader>
									Nominal Produk Masuk per Waktu Tertentu
								</CardHeader>
								<CardBody>
									<Line data={dataAmountPengeluaranPerRange} />
									<DetailStat data={dataAmountPengeluaranPerRange} unit={"Rupiah"}/>
								</CardBody>
							</Card>
						</Col>
					</Row>				
					}
					{data?.amountPemasukanPerCustomer && 
					<Row>
						<Col md={6}>
							<Card>
								<CardHeader>
									Jumlah Produk Terjual per Konsumen
								</CardHeader>
								<CardBody>
									<Bar data={dataCountPemasukanPerCustomer} />
									<DetailStat data={dataCountPemasukanPerCustomer} unit={"pcs"}/>
								</CardBody>
							</Card>
						</Col>
						<Col md={6}>
							<Card>
								<CardHeader>
									Nominal Transaksi per Konsumen
								</CardHeader>
								<CardBody>
									<Bar data={dataAmountPemasukanPerCustomer} />
									<DetailStat data={dataAmountPemasukanPerCustomer} unit={"Rupiah"}/>
								</CardBody>
							</Card>
						</Col>
					</Row>				
					}
					{data?.amountPemasukanPerProductModel && 
					<Row>
						<Col md={6}>
							<Card>
								<CardHeader>
									Jumlah Produk Terjual per Model
								</CardHeader>
								<CardBody>
									<Bar data={dataCountPemasukanPerProductModel} />
									<DetailStat data={dataCountPemasukanPerProductModel} unit={"pcs"}/>
								</CardBody>
							</Card>
						</Col>
						<Col md={6}>
							<Card>
								<CardHeader>
									Nominal Transaksi per Model
								</CardHeader>
								<CardBody>
									<Bar data={dataAmountPemasukanPerProductModel} />
									<DetailStat data={dataAmountPemasukanPerProductModel} unit={"Rupiah"}/>
								</CardBody>
							</Card>
						</Col>
					</Row>				
					}
					{data?.amountPemasukanPerProductType && 
					<Row>
						<Col md={6}>
							<Card>
								<CardHeader>
									Jumlah Produk Terjual per Jenis Produk
								</CardHeader>
								<CardBody>
									<Bar data={dataCountPemasukanPerProductType} />
									<DetailStat data={dataCountPemasukanPerProductType} unit={"pcs"}/>
								</CardBody>
							</Card>
						</Col>
						<Col md={6}>
							<Card>
								<CardHeader>
									Nominal Transaksi per Type
								</CardHeader>
								<CardBody>
									<Bar data={dataAmountPemasukanPerProductType} />
									<DetailStat data={dataAmountPemasukanPerProductType} unit={"Rupiah"}/>
								</CardBody>
							</Card>
						</Col>
					</Row>				
					}
					{data?.amountPemasukanPerProductColor && 
					<Row>
						<Col md={6}>
							<Card>
								<CardHeader>
									Jumlah Produk Terjual per Jenis Warna
								</CardHeader>
								<CardBody>
									<Bar data={dataCountPemasukanPerProductColor} />
									<DetailStat data={dataCountPemasukanPerProductColor} unit={"pcs"}/>
								</CardBody>
							</Card>
						</Col>
						<Col md={6}>
							<Card>
								<CardHeader>
									Nominal Transaksi per Warna
								</CardHeader>
								<CardBody>
									<Bar data={dataAmountPemasukanPerProductColor} />
									<DetailStat data={dataAmountPemasukanPerProductColor} unit={"Rupiah"}/>
								</CardBody>
							</Card>
						</Col>
					</Row>				
					}
					{data?.amountPemasukanPerProductDetail && 
					<Row>
						<Col md={6}>
							<Card>
								<CardHeader>
									Jumlah Produk Terjual per Produk
								</CardHeader>
								<CardBody>
									<Bar data={dataCountPemasukanPerProductDetail} />
									<DetailStat data={dataCountPemasukanPerProductDetail} unit={"pcs"} useStock={true} />
								</CardBody>
							</Card>
						</Col>
						<Col md={6}>
							<Card>
								<CardHeader>
									Nominal Transaksi per Produk
								</CardHeader>
								<CardBody>
									<Bar data={dataAmountPemasukanPerProductDetail} />
									<DetailStat data={dataAmountPemasukanPerProductDetail} unit={"Rupiah"}/>
								</CardBody>
							</Card>
						</Col>
					</Row>				
					}

					{data?.amountPemasukanPerSourceOfSales && 
					<Row>
						<Col md={6}>
							<Card>
								<CardHeader>
									Jumlah Produk Terjual per Sumber Penjualan
								</CardHeader>
								<CardBody>
									<Pie data={dataCountPemasukanPerSourceOfSales} />
									<DetailStat data={dataCountPemasukanPerSourceOfSales} unit={"pcs"}/>
								</CardBody>
							</Card>
						</Col>
						<Col md={6}>
							<Card>
								<CardHeader>
									Nominal Transaksi per Sumber Penjualan
								</CardHeader>
								<CardBody>
									<Pie data={dataAmountPemasukanPerSourceOfSales} />
									<DetailStat data={dataAmountPemasukanPerSourceOfSales} unit={"Rupiah"}/>
								</CardBody>
							</Card>
						</Col>
					</Row>				
					}

					{data?.amountPemasukanPerPriceType && 
					<Row>
						<Col md={6}>
							<Card>
								<CardHeader>
									Jumlah Produk Terjual per Tipe Harga
								</CardHeader>
								<CardBody>
									<Pie data={dataCountPemasukanPerPriceType} />
									<DetailStat data={dataCountPemasukanPerPriceType} unit={"pcs"}/>
									<div style={{paddingLeft: '0px'}}>
										<br />
										<hr />
										- Reseller
										<DetailStat data={dataCountPemasukanPerProductType_PriceTypeReseller} unit={"Rupiah"}/>
										<br />
										<hr />
										- Retail
										<DetailStat data={dataCountPemasukanPerProductType_PriceTypeRetail} unit={"Rupiah"}/>
									</div>
								</CardBody>
							</Card>
						</Col>
						<Col md={6}>
							<Card>
								<CardHeader>
									Nominal Transaksi per Tipe Harga
								</CardHeader>
								<CardBody>
									<Pie data={dataAmountPemasukanPerPriceType} />
									<DetailStat data={dataAmountPemasukanPerPriceType} unit={"Rupiah"}/>
									<div style={{paddingLeft: '0px'}}>
										<br />
										<hr />
										- Reseller
										<DetailStat data={dataAmountPemasukanPerProductType_PriceTypeReseller} unit={"Rupiah"}/>
										<br />
										<hr />
										- Retail
										<DetailStat data={dataAmountPemasukanPerProductType_PriceTypeRetail} unit={"Rupiah"}/>
									</div>
								</CardBody>
							</Card>
						</Col>
					</Row>				
					}

					{data?.amountPemasukanPerProductType_PriceType && 
					<Row>
						<Col md={6}>
							<Card>
								<CardHeader>
									Jumlah Produk Terjual per Produk, Model & Tipe Harga
								</CardHeader>
								<CardBody>
									<Bar data={dataCountPemasukanPerProductType_PriceType} />
									<DetailStat data={dataCountPemasukanPerProductType_PriceType} unit={"pcs"}/>
								</CardBody>
							</Card>
						</Col>
						<Col md={6}>
							<Card>
								<CardHeader>
									Nominal Transaksi per Produk, Model & Tipe Harga
								</CardHeader>
								<CardBody>
									<Bar data={dataAmountPemasukanPerProductType_PriceType} />
									<DetailStat data={dataAmountPemasukanPerProductType_PriceType} unit={"Rupiah"}/>
								</CardBody>
							</Card>
						</Col>
					</Row>				
					}
				</Container>
      </div>
    </>
  )
}

RecapPage.propTypes = {
  getRecap: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  recap: state.recap,
  product: state.product,
})

export default connect( mapStateToProps, {
	getRecap,
	getProduct,
})(RecapPage)
