import { combineReducers } from 'redux'
import profileReducer from './profileReducer'
import productTypeReducer from './productTypeReducer.js'
import productModelReducer from './productModelReducer.js'
import productReducer from './productReducer.js'
import productColorReducer from './productColorReducer.js'
import customerReducer from './customerReducer.js'
import inoutReducer from './inoutReducer.js'
import recapReducer from './recapReducer.js'
import settingReducer from './settingReducer.js'

const rootReducer = combineReducers({
  profile: profileReducer,
  productType: productTypeReducer,
  productModel: productModelReducer,
  productColor: productColorReducer,
  product: productReducer,
  customer: customerReducer,
  inout: inoutReducer,
  recap: recapReducer,
  setting: settingReducer,
})

export default rootReducer
